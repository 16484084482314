import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";

import Login from "components/Authorization/Login";
import MainNavigation from "components/MainNavigation/MainNavigation";
import OrganizationAndVenueProvider from "components/OrganizationAndVenueProvider";
import PrivateRoute from "components/PrivateRoute";
import ErrorHandler from "components/atoms/ErrorHandler";

import AppHeader from "AppHeader";
import colors from "config/style";
import { checkIfUserAlreadyAuthenticated } from "helpers/auth";
import { useIsLoggedIn } from "hooks/auth";
import { flattenRoutes } from "routes/flattenRoutes";
import routes from "routes/routes";
import styled from "styled-components";

const tagManagerArgs = {
  gtmId: "GTM-WPXGX3T",
};

TagManager.initialize(tagManagerArgs);

const AppLayout = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.grey.c1};
  min-height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const Aside = styled.aside`
  background: #fff;
  border-right: 1px solid ${colors.grey.c3};
  transition: width 0.1s linear;
  width: 200px;
`;

const Main = styled.main`
  flex: 1;
  min-height: 100%;
  min-width: 0;
`;

const AppRoutes = (): JSX.Element => {
  return (
    <Switch>
      {flattenRoutes.map((route) => {
        return <Route exact path={route.path()} key={route.path()} component={route.component} />;
      })}
      <Route path="*">
        <p>404</p>
      </Route>
    </Switch>
  );
};

interface AppTemplateProps {
  children: React.ReactElement;
}

const Apptemplate = ({ children, ...rest }: AppTemplateProps): JSX.Element => {
  return (
    <>
      <AppLayout>
        <AppHeader />
        <Container>
          <MainNavigation />
          <Main>
            <ErrorHandler>{children}</ErrorHandler>
          </Main>
        </Container>
      </AppLayout>
    </>
  );
};

function App(): JSX.Element {
  const loggedIn = useIsLoggedIn();

  useEffect(() => {
    checkIfUserAlreadyAuthenticated();
  }, []);

  return (
    <Apptemplate>
      <Switch>
        <Route
          exact
          path={routes.demo.path()}
          key={routes.demo.path()}
          component={routes.demo.component}
        />
        <Route exact path="/login">
          {!loggedIn ? <Login /> : <Redirect to="/" />}
        </Route>
        <PrivateRoute path="/">
          <OrganizationAndVenueProvider>
            <AppRoutes />
          </OrganizationAndVenueProvider>
        </PrivateRoute>
        <Route path="*">
          <p>404</p>
        </Route>
      </Switch>
    </Apptemplate>
  );
}

export default withRouter(App);
