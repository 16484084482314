import { Customer, CustomerInput } from "generated/schemaTypes";

import { getFirst } from "helpers/Array";

export interface CustomerFormValues {
  givenName?: string;
  familyName?: string;
  email?: string;
  telephoneNumber?: string;
}

export const convertValuesToCustomerInput = (
  values: CustomerFormValues,
  tloId: string,
  customerId?: string,
): CustomerInput => {
  const customerInput: CustomerInput = {
    tlo: tloId,
    id: customerId,
    customerNumber: 0,
    givenName: values.givenName ?? "",
    familyName: values.familyName ?? "",
    email: values.email ?? "",
    telephoneNumbers: [
      {
        number: values?.telephoneNumber ?? "",
        telephoneType: "default",
      },
    ],
  };
  return customerInput;
};

export const convertCustomerToValues = (customer: Customer | undefined): CustomerFormValues => {
  return {
    givenName: customer?.givenName ?? "",
    familyName: customer?.familyName ?? "",
    email: customer?.email ?? "",
    telephoneNumber: getFirst(customer?.telephoneNumbers)?.number ?? "",
  };
};
