import React from "react";

import Col from "components/atoms/antD/Col";
import Row from "components/atoms/antD/Row";

const InputFieldRow = ({ inputs }: { inputs: JSX.Element[] }): JSX.Element => {
  const span = 24 / inputs?.length;
  const inputFields = inputs.map((e, i: number) => (
    <Col span={span} key={i}>
      {e}
    </Col>
  ));
  return <Row gutter={24}>{inputFields}</Row>;
};

export default InputFieldRow;
