import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `AWSDate` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 Date](https://en.wikipedia.org/wiki/ISO_8601#Calendar_dates) string. In other words, this scalar type accepts date strings of the form `YYYY-MM-DD`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-05-01**" and "**-9999-01-01**" are both valid dates.  This scalar type can also accept an optional [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators). For example, "**1970-01-01**", "**1970-01-01Z**", "**1970-01-01-07:00**" and "**1970-01-01+05:30**" are all valid dates. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDate: string;
  /** The `AWSDateTime` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations) string. In other words, this scalar type accepts datetime strings of the form `YYYY-MM-DDThh:mm:ss.SSSZ`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-01-01T00:00Z**" and "**-9999-01-01T00:00Z**" are both valid datetime strings.  The field after the two digit seconds field is a nanoseconds field. It can accept between 1 and 9 digits. So, for example, "**1970-01-01T12:00:00.2Z**", "**1970-01-01T12:00:00.277Z**" and "**1970-01-01T12:00:00.123456789Z**" are all valid datetime strings.  The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used).  The [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators) is compulsory for this scalar. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDateTime: string;
  /** The `AWSEmail` scalar type provided by AWS AppSync, represents an Email address string that complies with [RFC 822](https://www.ietf.org/rfc/rfc822.txt). For example, "**username@example.com**" is a valid Email address. */
  AWSEmail: string;
  /** The `AWSTime` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 Time](https://en.wikipedia.org/wiki/ISO_8601#Times) string. In other words, this scalar type accepts time strings of the form `hh:mm:ss.SSS`.  The field after the two digit seconds field is a nanoseconds field. It can accept between 1 and 9 digits. So, for example, "**12:00:00.2**", "**12:00:00.277**" and "**12:00:00.123456789**" are all valid time strings. The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used).  This scalar type can also accept an optional [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators). For example, "**12:30**", "**12:30Z**", "**12:30:24-07:00**" and "**12:30:24.500+05:30**" are all valid time strings. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSTime: string;
  /** The `AWSURL` scalar type provided by AWS AppSync, represents a valid URL string (Ex: <https://www.amazon.com/>). The URL may use any scheme and may also be a local URL (Ex: <http://localhost/>).  URLs without schemes like "**amazon.com**" or "**www.amazon.com**" are considered invalid. URLs which contain double slashes (two consecutive forward slashes) in their path are also considered invalid. */
  AWSURL: string;
};

export type Address = {
  __typename?: "Address";
  street: Scalars["String"];
  city: Scalars["String"];
  label?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  postalCode: Scalars["String"];
  countryCode: Scalars["String"];
  addressType: Scalars["String"];
};

export type AddressInput = {
  street: Scalars["String"];
  city: Scalars["String"];
  label?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  postalCode: Scalars["String"];
  countryCode: Scalars["String"];
  addressType: Scalars["String"];
};

export type Booking = {
  __typename?: "Booking";
  id?: Maybe<Scalars["String"]>;
  tlo: Scalars["String"];
  createdAt?: Maybe<Scalars["AWSDateTime"]>;
  createdBy: Scalars["String"];
  channel: Scalars["String"];
  type: Scalars["String"];
  status?: Maybe<Scalars["String"]>;
  paymentStatus?: Maybe<Scalars["String"]>;
  startTime: Scalars["AWSDateTime"];
  endTime: Scalars["AWSDateTime"];
  duration?: Maybe<Scalars["Int"]>;
  comment?: Maybe<Scalars["String"]>;
  players?: Maybe<Array<Maybe<Scalars["String"]>>>;
  resources: Array<Maybe<Scalars["String"]>>;
  events?: Maybe<Array<Maybe<Event>>>;
};

export type BookingInput = {
  id?: Maybe<Scalars["String"]>;
  tlo: Scalars["String"];
  createdAt?: Maybe<Scalars["AWSDateTime"]>;
  createdBy: Scalars["String"];
  channel: Scalars["String"];
  type: Scalars["String"];
  status?: Maybe<Scalars["String"]>;
  paymentStatus?: Maybe<Scalars["String"]>;
  startTime: Scalars["AWSDateTime"];
  endTime: Scalars["AWSDateTime"];
  duration?: Maybe<Scalars["Int"]>;
  comment?: Maybe<Scalars["String"]>;
  players?: Maybe<Array<Maybe<Scalars["String"]>>>;
  resources: Array<Maybe<Scalars["String"]>>;
  events?: Maybe<Array<Maybe<EventInput>>>;
};

export type Bookings = {
  __typename?: "Bookings";
  bookings: Array<Maybe<Booking>>;
  boundaries?: Maybe<Boundaries>;
};

export type Boundaries = {
  __typename?: "Boundaries";
  startTime?: Maybe<Scalars["AWSDateTime"]>;
  endTime?: Maybe<Scalars["AWSDateTime"]>;
};

export type CameraLegacy = {
  __typename?: "CameraLegacy";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Config = {
  __typename?: "Config";
  resourceConfiguration?: Maybe<Array<Maybe<Configuration>>>;
  contactConfiguration?: Maybe<Array<Maybe<Configuration>>>;
  locationConfiguration?: Maybe<Array<Maybe<Configuration>>>;
};

export type ConfigAttribute = {
  __typename?: "ConfigAttribute";
  attribute: Scalars["String"];
  mandatory: Scalars["Boolean"];
  editable: Scalars["Boolean"];
  visible: Scalars["Boolean"];
  type: Scalars["String"];
  sortOrder: Scalars["Int"];
  options?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type ConfigAttributeInput = {
  attribute: Scalars["String"];
  mandatory: Scalars["Boolean"];
  editable: Scalars["Boolean"];
  visible: Scalars["Boolean"];
  type: Scalars["String"];
  sortOrder: Scalars["Int"];
  options?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export enum ConfigCategory {
  Resource = "RESOURCE",
  Contact = "CONTACT",
  Location = "LOCATION",
}

export type Configuration = {
  __typename?: "Configuration";
  id: Scalars["String"];
  configType: Scalars["String"];
  configCategory: ConfigCategory;
  configAttributes?: Maybe<Array<Maybe<ConfigAttribute>>>;
};

export type ConfigurationInput = {
  configType: Scalars["String"];
  configCategory: ConfigCategory;
  configAttribute?: Maybe<ConfigAttributeInput>;
};

export type ContactPerson = {
  __typename?: "ContactPerson";
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  contactPersonType: Scalars["String"];
  emails?: Maybe<Array<Maybe<Email>>>;
  telephones?: Maybe<Array<Maybe<Telephone>>>;
  addresses?: Maybe<Array<Maybe<Address>>>;
};

export type ContactPersonInput = {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  contactPersonType: Scalars["String"];
  emails?: Maybe<Array<Maybe<EmailInput>>>;
  telephones?: Maybe<Array<Maybe<TelephoneInput>>>;
  addresses?: Maybe<Array<Maybe<AddressInput>>>;
};

export type CourtLegacy = {
  __typename?: "CourtLegacy";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  sport?: Maybe<SportLegacy>;
  surface?: Maybe<Scalars["String"]>;
  indoor?: Maybe<Scalars["Boolean"]>;
  facility?: Maybe<FacilityLegacy>;
  cameras?: Maybe<Array<Maybe<CameraLegacy>>>;
  courtTypes?: Maybe<CourtTypesLegacy>;
};

export type CourtTypesLegacy = {
  __typename?: "CourtTypesLegacy";
  TENNIS_SIZE?: Maybe<Scalars["String"]>;
  PADEL_SIZE?: Maybe<Scalars["String"]>;
};

export type CreateBooking = {
  parent: Scalars["String"];
  booking: BookingInput;
};

export type CreateCustomer = {
  parent: Scalars["String"];
  customer: CustomerInput;
};

export type CreateLocation = {
  parent: Scalars["String"];
  location: LocationInput;
};

export type CreateOrganization = {
  parent: Scalars["String"];
  organization: OrganizationInput;
};

export type CreateResource = {
  parent: Scalars["String"];
  resource: ResourceInput;
};

export type CreateScheduleRule = {
  parent: Scalars["String"];
  schedule: ScheduleRuleInput;
};

export type CreateVenue = {
  parent: Scalars["String"];
  venue: VenueInput;
};

export type Customer = {
  __typename?: "Customer";
  id: Scalars["String"];
  tlo: Scalars["String"];
  status?: Maybe<Scalars["String"]>;
  customerNumber: Scalars["Int"];
  parent?: Maybe<Scalars["String"]>;
  parentType?: Maybe<Scalars["String"]>;
  givenName?: Maybe<Scalars["String"]>;
  familyName?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  addresses?: Maybe<Array<Maybe<Address>>>;
  telephoneNumbers?: Maybe<Array<Maybe<Telephone>>>;
};

export type CustomerInput = {
  id?: Maybe<Scalars["String"]>;
  tlo: Scalars["String"];
  customerNumber: Scalars["Int"];
  givenName?: Maybe<Scalars["String"]>;
  familyName?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  addresses?: Maybe<Array<Maybe<AddressInput>>>;
  telephoneNumbers?: Maybe<Array<Maybe<TelephoneInput>>>;
};

export type Email = {
  __typename?: "Email";
  email: Scalars["AWSEmail"];
  emailType: Scalars["String"];
};

export type EmailInput = {
  email: Scalars["AWSEmail"];
  emailType: Scalars["String"];
};

export type Event = {
  __typename?: "Event";
  createdAt?: Maybe<Scalars["AWSDateTime"]>;
  createdBy?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  reason?: Maybe<Scalars["String"]>;
};

export type EventInput = {
  createdAt?: Maybe<Scalars["AWSDateTime"]>;
  createdBy?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  reason?: Maybe<Scalars["String"]>;
};

export type FacilityLegacy = {
  __typename?: "FacilityLegacy";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type FilterAttribute = {
  attributeType: Scalars["String"];
  /**  INDOOR */
  attributeValue: Scalars["String"];
  /**  OUTDOOR */
  sortOrder: Scalars["Int"];
};

export type FilterRequest = {
  countryCode: Scalars["String"];
  /**  SE ... */
  timeZone: Scalars["String"];
  specific?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /**  VenueIds that should alway be included in matching criteria regardless of geography */
  position?: Maybe<PositionInput>;
  /**  Position of the place that the player wants to play at */
  filters?: Maybe<Array<Maybe<FilterType>>>;
};

export type FilterResponse = {
  __typename?: "FilterResponse";
  specific: Array<Maybe<VenueResponse>>;
  /**  Should not be present in generic list */
  generic: Array<Maybe<VenueResponse>>;
};

export type FilterType = {
  resourceType: Scalars["String"];
  /**  PADEL, TENNIS .... */
  filterAttributes?: Maybe<Array<Maybe<FilterAttribute>>>;
};

export type Hours = {
  __typename?: "Hours";
  startTime?: Maybe<Scalars["String"]>;
  endTime?: Maybe<Scalars["String"]>;
  weekdays?: Maybe<Array<Maybe<Scalars["Int"]>>>;
};

export type HoursInput = {
  startTime?: Maybe<Scalars["String"]>;
  endTime?: Maybe<Scalars["String"]>;
  weekdays?: Maybe<Array<Maybe<Scalars["Int"]>>>;
};

export type Location = {
  __typename?: "Location";
  id: Scalars["String"];
  tlo: Scalars["String"];
  name: Scalars["String"];
  parent?: Maybe<Scalars["String"]>;
  parentType?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  position?: Maybe<Position>;
  hasRights?: Maybe<Scalars["Boolean"]>;
  contactPersons?: Maybe<Array<Maybe<ContactPerson>>>;
  addresses?: Maybe<Array<Maybe<Address>>>;
  resources?: Maybe<Array<Maybe<Resource>>>;
};

export type LocationInput = {
  id?: Maybe<Scalars["String"]>;
  tlo: Scalars["String"];
  name: Scalars["String"];
  position?: Maybe<PositionInput>;
  hasRights?: Maybe<Scalars["Boolean"]>;
  contactPersons?: Maybe<Array<Maybe<ContactPersonInput>>>;
  addresses?: Maybe<Array<Maybe<AddressInput>>>;
  resources?: Maybe<Array<Maybe<ResourceInput>>>;
};

export type Mutation = {
  __typename?: "Mutation";
  addConfiguration: Configuration;
  /**  @aws_auth(cognito_groups: ["Administrators"]) */
  updateConfiguration: Configuration;
  /**  @aws_auth(cognito_groups: ["Administrators"]) */
  deleteConfiguration?: Maybe<Configuration>;
  /**
   *  @aws_auth(cognito_groups: ["Administrators"])
   * addUserRight(userId: String! role: String! id: String!) # creates id -> [role, role2] in cognito
   * @aws_auth(cognito_groups: ["Administrators"])
   */
  createTopOrganization: Organization;
  /**  @aws_auth(cognito_groups: ["Administrators"]) */
  createOrganization: Organization;
  /**  @aws_auth(cognito_groups: ["VenueOwners"]) */
  updateOrganization: Organization;
  /**  @aws_auth(cognito_groups: ["VenueOwners"]) */
  deleteOrganization: Scalars["String"];
  /**  @aws_auth(cognito_groups: ["VenueOwners"]) */
  createVenue: Venue;
  /**  @aws_auth(cognito_groups: ["VenueOwners"]) */
  updateVenue: Venue;
  /**  @aws_auth(cognito_groups: ["VenueOperators", "VenueOwners"]) */
  deleteVenue: Scalars["String"];
  /**  @aws_auth(cognito_groups: ["VenueOwners"]) */
  createLocation: Location;
  /**  @aws_auth(cognito_groups: ["VenueOperators"]) */
  updateLocation: Location;
  /**  @aws_auth(cognito_groups: ["VenueOperators"]) */
  deleteLocation: Scalars["String"];
  /**  @aws_auth(cognito_groups: ["VenueOperators"]) */
  createResource: Resource;
  /**  @aws_auth(cognito_groups: ["VenueOperators"]) */
  updateResource: Resource;
  /**  @aws_auth(cognito_groups: ["VenueOperators"]) */
  deleteResource: Scalars["String"];
  /**  @aws_auth(cognito_groups: ["VenueOperators"]) */
  createCustomer: Customer;
  /**  @aws_auth(cognito_groups: ["VenueOperators"]) */
  updateCustomer: Customer;
  /**  @aws_auth(cognito_groups: ["VenueOperators"]) */
  deleteCustomer: Scalars["String"];
  /**  @aws_auth(cognito_groups: ["VenueOperators"]) */
  createSchedule: ScheduleRule;
  /**  @aws_auth(cognito_groups: ["VenueOperators"]) */
  updateSchedule: ScheduleRule;
  /**  @aws_auth(cognito_groups: ["VenueOperators"]) */
  deleteSchedule: Scalars["String"];
  /**  @aws_auth(cognito_groups: ["VenueOperators"]) */
  createBooking: Booking;
  /**  @aws_auth(cognito_groups: ["VenueOperators"]) */
  updateBooking: Booking;
  /**  @aws_auth(cognito_groups: ["VenueOperators"]) */
  deleteBooking: Scalars["String"];
  /**  @aws_auth(cognito_groups: ["VenueOperators"]) */
  deleteMe: Scalars["String"];
  updateUser: User;
};

export type MutationAddConfigurationArgs = {
  updateConfiguration: UpdateConfiguration;
};

export type MutationUpdateConfigurationArgs = {
  updateConfiguration: UpdateConfiguration;
};

export type MutationDeleteConfigurationArgs = {
  updateConfiguration: UpdateConfiguration;
};

export type MutationCreateTopOrganizationArgs = {
  name: Scalars["String"];
};

export type MutationCreateOrganizationArgs = {
  createOrganization: CreateOrganization;
};

export type MutationUpdateOrganizationArgs = {
  organization: OrganizationInput;
};

export type MutationDeleteOrganizationArgs = {
  id: Scalars["String"];
  tlo: Scalars["String"];
};

export type MutationCreateVenueArgs = {
  createVenue: CreateVenue;
};

export type MutationUpdateVenueArgs = {
  venue: VenueInput;
};

export type MutationDeleteVenueArgs = {
  id: Scalars["String"];
  tlo: Scalars["String"];
};

export type MutationCreateLocationArgs = {
  createLocation: CreateLocation;
};

export type MutationUpdateLocationArgs = {
  location: LocationInput;
};

export type MutationDeleteLocationArgs = {
  id: Scalars["String"];
  tlo: Scalars["String"];
};

export type MutationCreateResourceArgs = {
  createResource: CreateResource;
};

export type MutationUpdateResourceArgs = {
  resource: ResourceInput;
};

export type MutationDeleteResourceArgs = {
  id: Scalars["String"];
  tlo: Scalars["String"];
};

export type MutationCreateCustomerArgs = {
  createCustomer: CreateCustomer;
};

export type MutationUpdateCustomerArgs = {
  customer: CustomerInput;
};

export type MutationDeleteCustomerArgs = {
  id: Scalars["String"];
  tlo: Scalars["String"];
};

export type MutationCreateScheduleArgs = {
  createSchedule: CreateScheduleRule;
};

export type MutationUpdateScheduleArgs = {
  schedule: ScheduleRuleInput;
};

export type MutationDeleteScheduleArgs = {
  id: Scalars["String"];
  tlo: Scalars["String"];
};

export type MutationCreateBookingArgs = {
  createBooking: CreateBooking;
};

export type MutationUpdateBookingArgs = {
  booking: BookingInput;
};

export type MutationDeleteBookingArgs = {
  id: Scalars["String"];
  tlo: Scalars["String"];
};

export type MutationUpdateUserArgs = {
  user: UserInput;
};

export type OpenHoursLegacy = {
  __typename?: "OpenHoursLegacy";
  weekday?: Maybe<Scalars["Int"]>;
  open?: Maybe<Scalars["String"]>;
  close?: Maybe<Scalars["String"]>;
};

export type OpeningHours = {
  __typename?: "OpeningHours";
  monday?: Maybe<Array<Maybe<TimeInterval>>>;
  tuesday?: Maybe<Array<Maybe<TimeInterval>>>;
  wednesday?: Maybe<Array<Maybe<TimeInterval>>>;
  thursday?: Maybe<Array<Maybe<TimeInterval>>>;
  friday?: Maybe<Array<Maybe<TimeInterval>>>;
  saturday?: Maybe<Array<Maybe<TimeInterval>>>;
  sunday?: Maybe<Array<Maybe<TimeInterval>>>;
  openingHoursType?: Maybe<Array<Maybe<Scalars["String"]>>>;
  description?: Maybe<Scalars["String"]>;
};

export type OpeningHoursInput = {
  monday?: Maybe<Array<Maybe<TimeIntervalInput>>>;
  tuesday?: Maybe<Array<Maybe<TimeIntervalInput>>>;
  wednesday?: Maybe<Array<Maybe<TimeIntervalInput>>>;
  thursday?: Maybe<Array<Maybe<TimeIntervalInput>>>;
  friday?: Maybe<Array<Maybe<TimeIntervalInput>>>;
  saturday?: Maybe<Array<Maybe<TimeIntervalInput>>>;
  sunday?: Maybe<Array<Maybe<TimeIntervalInput>>>;
  openingHoursType?: Maybe<Array<Maybe<Scalars["String"]>>>;
  description?: Maybe<Scalars["String"]>;
};

export type Organization = {
  __typename?: "Organization";
  id: Scalars["String"];
  tlo: Scalars["String"];
  name: Scalars["String"];
  parent?: Maybe<Scalars["String"]>;
  parentType?: Maybe<Scalars["String"]>;
  countryCode: Scalars["String"];
  status?: Maybe<Scalars["String"]>;
  vatNumber?: Maybe<Scalars["String"]>;
  hasRights?: Maybe<Scalars["Boolean"]>;
  salesContact?: Maybe<Array<Maybe<ContactPerson>>>;
  contactPersons?: Maybe<Array<Maybe<ContactPerson>>>;
  addresses?: Maybe<Array<Maybe<Address>>>;
  telephoneNumbers?: Maybe<Array<Maybe<Telephone>>>;
  emails?: Maybe<Array<Maybe<Email>>>;
  organizations?: Maybe<Array<Maybe<Organization>>>;
  venues?: Maybe<Array<Maybe<Venue>>>;
};

export type OrganizationInput = {
  id?: Maybe<Scalars["String"]>;
  tlo: Scalars["String"];
  name: Scalars["String"];
  countryCode: Scalars["String"];
  vatNumber?: Maybe<Scalars["String"]>;
  hasRights?: Maybe<Scalars["Boolean"]>;
  salesContact?: Maybe<Array<Maybe<ContactPersonInput>>>;
  contactPersons?: Maybe<Array<Maybe<ContactPersonInput>>>;
  addresses?: Maybe<Array<Maybe<AddressInput>>>;
  telephoneNumbers?: Maybe<Array<Maybe<TelephoneInput>>>;
  emails?: Maybe<Array<Maybe<EmailInput>>>;
  organizations?: Maybe<Array<Maybe<OrganizationInput>>>;
  venues?: Maybe<Array<Maybe<VenueInput>>>;
};

export type Position = {
  __typename?: "Position";
  latitude: Scalars["String"];
  longitude: Scalars["String"];
};

export type PositionInput = {
  longitude?: Maybe<Scalars["String"]>;
  latitude?: Maybe<Scalars["String"]>;
};

export type PositionLegacy = {
  __typename?: "PositionLegacy";
  latitude?: Maybe<Scalars["Float"]>;
  longitude?: Maybe<Scalars["Float"]>;
};

export type Query = {
  __typename?: "Query";
  realtimeEndpoint?: Maybe<RealTimeEndpoint>;
  configuration?: Maybe<Config>;
  /**  ADD */
  organizations?: Maybe<Array<Maybe<Organization>>>;
  /**
   *  To be used at login, this will fetch all top level org a user has access to with their trees
   * KEEP
   */
  organization?: Maybe<Organization>;
  /**   Complete tree rooted on the org with ID */
  venue?: Maybe<Venue>;
  /**  Complete tree rooted on the venue with ID */
  location?: Maybe<Location>;
  /**  Complete tree rooted on the location with ID */
  resource?: Maybe<Resource>;
  /**  This will fetch a singel resource */
  schedule?: Maybe<ScheduleRule>;
  /**  This will fetch a singel schedule */
  schedules?: Maybe<Array<Maybe<ScheduleRule>>>;
  resourceSchedule?: Maybe<ScheduleRule>;
  booking?: Maybe<Booking>;
  bookings?: Maybe<Bookings>;
  customer?: Maybe<Customer>;
  searchableVenues?: Maybe<Array<Maybe<Venue>>>;
  /**  userRights(userId: String!) #map[role] -> [id, id2, id3] fetch from cognito */
  getUsers: Array<Maybe<User>>;
  /**   Queries for players/mobile infrastructure */
  getPlayerVenues?: Maybe<Array<Maybe<VenueLegacy>>>;
};

export type QueryConfigurationArgs = {
  countryCode: Scalars["String"];
};

export type QueryOrganizationArgs = {
  id: Scalars["String"];
  tlo: Scalars["String"];
};

export type QueryVenueArgs = {
  id: Scalars["String"];
  tlo: Scalars["String"];
};

export type QueryLocationArgs = {
  id: Scalars["String"];
  tlo: Scalars["String"];
};

export type QueryResourceArgs = {
  id: Scalars["String"];
  tlo: Scalars["String"];
};

export type QueryScheduleArgs = {
  id: Scalars["String"];
  tlo: Scalars["String"];
};

export type QuerySchedulesArgs = {
  venueId: Scalars["String"];
  tlo: Scalars["String"];
  date: Scalars["AWSDate"];
};

export type QueryResourceScheduleArgs = {
  resourceId: Scalars["String"];
  tlo: Scalars["String"];
  time: Scalars["AWSDateTime"];
};

export type QueryBookingArgs = {
  id: Scalars["String"];
  tlo: Scalars["String"];
};

export type QueryBookingsArgs = {
  venue: Scalars["String"];
  tlo: Scalars["String"];
  date: Scalars["AWSDate"];
};

export type QueryCustomerArgs = {
  id: Scalars["String"];
  tlo: Scalars["String"];
};

export type QueryGetUsersArgs = {
  ids: Array<Scalars["String"]>;
};

export type RealTimeEndpoint = {
  __typename?: "RealTimeEndpoint";
  host?: Maybe<Scalars["String"]>;
};

export type Resource = {
  __typename?: "Resource";
  id: Scalars["String"];
  tlo: Scalars["String"];
  name: Scalars["String"];
  parent?: Maybe<Scalars["String"]>;
  parentType?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
  hasRights?: Maybe<Scalars["Boolean"]>;
  sortOrder: Scalars["Int"];
  attributes?: Maybe<Array<Maybe<ResourceAttribute>>>;
  schedules?: Maybe<Array<Maybe<ScheduleRule>>>;
};

export type ResourceAttribute = {
  __typename?: "ResourceAttribute";
  type: Scalars["String"];
  value: Scalars["String"];
};

export type ResourceAttributeInput = {
  type: Scalars["String"];
  value: Scalars["String"];
};

export type ResourceInput = {
  id?: Maybe<Scalars["String"]>;
  tlo: Scalars["String"];
  name: Scalars["String"];
  type: Scalars["String"];
  hasRights?: Maybe<Scalars["Boolean"]>;
  sortOrder: Scalars["Int"];
  attributes?: Maybe<Array<Maybe<ResourceAttributeInput>>>;
};

export type ScheduleRule = {
  __typename?: "ScheduleRule";
  id: Scalars["String"];
  tlo: Scalars["String"];
  name: Scalars["String"];
  status?: Maybe<Scalars["String"]>;
  enabled?: Maybe<Scalars["Boolean"]>;
  startDate: Scalars["AWSDate"];
  endDate?: Maybe<Scalars["AWSDate"]>;
  type?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  minDuration?: Maybe<Scalars["Int"]>;
  maxDuration?: Maybe<Scalars["Int"]>;
  interval?: Maybe<Scalars["Int"]>;
  sortOrder: Scalars["Int"];
  resources?: Maybe<Array<Maybe<Scalars["String"]>>>;
  hours?: Maybe<Array<Maybe<Hours>>>;
};

export type ScheduleRuleInput = {
  id?: Maybe<Scalars["String"]>;
  tlo: Scalars["String"];
  name: Scalars["String"];
  enabled?: Maybe<Scalars["Boolean"]>;
  startDate: Scalars["AWSDate"];
  endDate?: Maybe<Scalars["AWSDate"]>;
  type?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  minDuration?: Maybe<Scalars["Int"]>;
  maxDuration?: Maybe<Scalars["Int"]>;
  interval?: Maybe<Scalars["Int"]>;
  sortOrder: Scalars["Int"];
  resources?: Maybe<Array<Maybe<Scalars["String"]>>>;
  hours?: Maybe<Array<Maybe<HoursInput>>>;
};

export type SportLegacy = {
  __typename?: "SportLegacy";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Subscription = {
  __typename?: "Subscription";
  updateOrganization?: Maybe<Organization>;
  /**  @aws_subscribe(mutations: ["updateOrganization"]) */
  updateVenue?: Maybe<Venue>;
  /**  @aws_subscribe(mutations: ["updateVenue"]) */
  updateLocation?: Maybe<Location>;
  /**  @aws_subscribe(mutations: ["updateLocation"]) */
  updateResource?: Maybe<Resource>;
};

export type SubscriptionUpdateOrganizationArgs = {
  organization: OrganizationInput;
};

export type SubscriptionUpdateVenueArgs = {
  venue: VenueInput;
};

export type SubscriptionUpdateLocationArgs = {
  location: LocationInput;
};

export type SubscriptionUpdateResourceArgs = {
  resource: ResourceInput;
};

export type Telephone = {
  __typename?: "Telephone";
  number: Scalars["String"];
  /**   +46722087407 */
  telephoneType: Scalars["String"];
};

export type TelephoneInput = {
  number: Scalars["String"];
  /**   +46722087407 */
  telephoneType: Scalars["String"];
};

export type TimeInterval = {
  __typename?: "TimeInterval";
  startTime: Scalars["AWSTime"];
  endTime: Scalars["AWSTime"];
};

export type TimeIntervalInput = {
  startTime: Scalars["AWSTime"];
  endTime: Scalars["AWSTime"];
};

export type UpdateConfiguration = {
  countryCode: Scalars["String"];
  configuration: ConfigurationInput;
};

export type Url = {
  __typename?: "Url";
  urlType: Scalars["String"];
  url: Scalars["AWSURL"];
};

export type UrlInput = {
  urlType: Scalars["String"];
  url: Scalars["AWSURL"];
};

export type User = {
  __typename?: "User";
  id: Scalars["String"];
  email: Scalars["String"];
  givenName?: Maybe<Scalars["String"]>;
  familyName?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  nationalIdNumber?: Maybe<Scalars["String"]>;
  gender?: Maybe<Scalars["String"]>;
  birthday?: Maybe<Scalars["String"]>;
  /**  ISO 8601? */
  nationality?: Maybe<Scalars["String"]>;
  address?: Maybe<Address>;
};

export type UserInput = {
  id: Scalars["String"];
  email: Scalars["String"];
  givenName?: Maybe<Scalars["String"]>;
  familyName?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  nationalIdNumber?: Maybe<Scalars["String"]>;
  gender?: Maybe<Scalars["String"]>;
  birthday?: Maybe<Scalars["String"]>;
  /**  ISO 8601? */
  nationality?: Maybe<Scalars["String"]>;
  address?: Maybe<AddressInput>;
};

export type Venue = {
  __typename?: "Venue";
  id: Scalars["String"];
  tlo: Scalars["String"];
  name: Scalars["String"];
  parent?: Maybe<Scalars["String"]>;
  parentType?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  timeZone?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  shortName?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  municipality?: Maybe<Scalars["String"]>;
  position?: Maybe<Position>;
  hasRights?: Maybe<Scalars["Boolean"]>;
  contactPersons?: Maybe<Array<Maybe<ContactPerson>>>;
  venueUrls?: Maybe<Array<Maybe<Url>>>;
  externalUrls?: Maybe<Array<Maybe<Url>>>;
  openingHours?: Maybe<Array<Maybe<OpeningHours>>>;
  emails?: Maybe<Array<Maybe<Email>>>;
  addresses?: Maybe<Array<Maybe<Address>>>;
  telephoneNumbers?: Maybe<Array<Maybe<Telephone>>>;
  locations?: Maybe<Array<Maybe<Location>>>;
  resources?: Maybe<Array<Maybe<Resource>>>;
  schedules?: Maybe<Array<Maybe<ScheduleRule>>>;
  bookings?: Maybe<Array<Maybe<Booking>>>;
  customers?: Maybe<Array<Maybe<Customer>>>;
};

export type VenueInput = {
  id?: Maybe<Scalars["String"]>;
  tlo: Scalars["String"];
  name: Scalars["String"];
  timeZone?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  shortName?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  municipality?: Maybe<Scalars["String"]>;
  position?: Maybe<PositionInput>;
  hasRights?: Maybe<Scalars["Boolean"]>;
  contactPersons?: Maybe<Array<Maybe<ContactPersonInput>>>;
  venueUrls?: Maybe<Array<Maybe<UrlInput>>>;
  externalUrls?: Maybe<Array<Maybe<UrlInput>>>;
  openingHours?: Maybe<Array<Maybe<OpeningHoursInput>>>;
  emails?: Maybe<Array<Maybe<EmailInput>>>;
  addresses?: Maybe<Array<Maybe<AddressInput>>>;
  telephoneNumbers?: Maybe<Array<Maybe<TelephoneInput>>>;
  locations?: Maybe<Array<Maybe<LocationInput>>>;
  resources?: Maybe<Array<Maybe<ResourceInput>>>;
  schedules?: Maybe<Array<Maybe<ScheduleRuleInput>>>;
};

/**   -Legacy suffix is to mimic the Web App REST API */
export type VenueLegacy = {
  __typename?: "VenueLegacy";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  logoUrl?: Maybe<Scalars["String"]>;
  address?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  courts?: Maybe<Array<Maybe<CourtLegacy>>>;
  email?: Maybe<Scalars["String"]>;
  fax?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  municipality?: Maybe<Scalars["Int"]>;
  openHours?: Maybe<Array<Maybe<OpenHoursLegacy>>>;
  position?: Maybe<PositionLegacy>;
  region?: Maybe<Scalars["Int"]>;
  shortname?: Maybe<Scalars["String"]>;
  telephone?: Maybe<Scalars["String"]>;
  webUrl?: Maybe<Scalars["String"]>;
  zipcode?: Maybe<Scalars["String"]>;
};

export type VenueResponse = {
  __typename?: "VenueResponse";
  id: Scalars["String"];
  name: Scalars["String"];
  city: Scalars["String"];
  timeZone: Scalars["String"];
  logoUrl?: Maybe<Scalars["AWSURL"]>;
  distance?: Maybe<Scalars["Int"]>;
  /**  distance in meters */
  resourceTypes?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type BookingsQueryVariables = Exact<{
  venue: Scalars["String"];
  tlo: Scalars["String"];
  date: Scalars["AWSDate"];
}>;

export type BookingsQuery = {
  __typename?: "Query";
  bookings?: Maybe<{
    __typename?: "Bookings";
    boundaries?: Maybe<{ __typename?: "Boundaries" } & FullBoundariesFragment>;
    bookings: Array<Maybe<{ __typename?: "Booking" } & FullBookingFragment>>;
  }>;
};

export type BookingsWithEventsQueryVariables = Exact<{
  venue: Scalars["String"];
  tlo: Scalars["String"];
  date: Scalars["AWSDate"];
}>;

export type BookingsWithEventsQuery = {
  __typename?: "Query";
  bookings?: Maybe<{
    __typename?: "Bookings";
    bookings: Array<Maybe<{ __typename?: "Booking" } & BookingWithEventsFragment>>;
  }>;
};

export type BookingWithEventsQueryVariables = Exact<{
  id: Scalars["String"];
  tlo: Scalars["String"];
}>;

export type BookingWithEventsQuery = {
  __typename?: "Query";
  booking?: Maybe<{ __typename?: "Booking" } & BookingWithEventsFragment>;
};

export type CreateBookingMutationVariables = Exact<{
  createBooking: CreateBooking;
}>;

export type CreateBookingMutation = {
  __typename?: "Mutation";
  createBooking: { __typename?: "Booking" } & FullBookingFragment;
};

export type UpdateBookingMutationVariables = Exact<{
  updateBooking: BookingInput;
}>;

export type UpdateBookingMutation = {
  __typename?: "Mutation";
  updateBooking: { __typename?: "Booking" } & FullBookingFragment;
};

export type DeleteBookingMutationVariables = Exact<{
  id: Scalars["String"];
  tlo: Scalars["String"];
}>;

export type DeleteBookingMutation = { __typename?: "Mutation"; deleteBooking: string };

export type FullBookingFragment = {
  __typename?: "Booking";
  id?: Maybe<string>;
  tlo: string;
  status?: Maybe<string>;
  startTime: string;
  endTime: string;
  type: string;
  createdAt?: Maybe<string>;
  createdBy: string;
  channel: string;
  resources: Array<Maybe<string>>;
  comment?: Maybe<string>;
};

export type FullBoundariesFragment = {
  __typename?: "Boundaries";
  startTime?: Maybe<string>;
  endTime?: Maybe<string>;
};

export type BookingWithEventsFragment = {
  __typename?: "Booking";
  players?: Maybe<Array<Maybe<string>>>;
  events?: Maybe<
    Array<
      Maybe<{
        __typename?: "Event";
        createdAt?: Maybe<string>;
        createdBy?: Maybe<string>;
        status?: Maybe<string>;
        reason?: Maybe<string>;
      }>
    >
  >;
} & FullBookingFragment;

export type CustomersFromVenueQueryVariables = Exact<{
  id: Scalars["String"];
  tlo: Scalars["String"];
}>;

export type CustomersFromVenueQuery = {
  __typename?: "Query";
  venue?: Maybe<
    {
      __typename?: "Venue";
      customers?: Maybe<Array<Maybe<{ __typename?: "Customer" } & FullCustomerFragment>>>;
    } & VenueSkeletonFragment
  >;
};

export type GetCustomerQueryVariables = Exact<{
  id: Scalars["String"];
  tlo: Scalars["String"];
}>;

export type GetCustomerQuery = {
  __typename?: "Query";
  customer?: Maybe<{ __typename?: "Customer" } & FullCustomerFragment>;
};

export type CreateCustomerMutationVariables = Exact<{
  createCustomer: CreateCustomer;
}>;

export type CreateCustomerMutation = {
  __typename?: "Mutation";
  createCustomer: { __typename?: "Customer" } & FullCustomerFragment;
};

export type UpdateCustomerMutationVariables = Exact<{
  customer: CustomerInput;
}>;

export type UpdateCustomerMutation = {
  __typename?: "Mutation";
  updateCustomer: { __typename?: "Customer" } & FullCustomerFragment;
};

export type DeleteCustomerMutationVariables = Exact<{
  id: Scalars["String"];
  tlo: Scalars["String"];
}>;

export type DeleteCustomerMutation = { __typename?: "Mutation"; deleteCustomer: string };

export type FullCustomerFragment = {
  __typename?: "Customer";
  id: string;
  tlo: string;
  customerNumber: number;
  status?: Maybe<string>;
  givenName?: Maybe<string>;
  familyName?: Maybe<string>;
  email: string;
  telephoneNumbers?: Maybe<
    Array<Maybe<{ __typename?: "Telephone"; number: string; telephoneType: string }>>
  >;
};

export type OrganizationSkeletonFragment = {
  __typename?: "Organization";
  id: string;
  tlo: string;
  name: string;
};

export type GetOrganizationSkeletonsQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrganizationSkeletonsQuery = {
  __typename?: "Query";
  organizations?: Maybe<
    Array<
      Maybe<
        {
          __typename?: "Organization";
          organizations?: Maybe<
            Array<
              Maybe<
                {
                  __typename?: "Organization";
                  organizations?: Maybe<
                    Array<
                      Maybe<
                        {
                          __typename?: "Organization";
                          organizations?: Maybe<
                            Array<
                              Maybe<{ __typename?: "Organization" } & OrganizationSkeletonFragment>
                            >
                          >;
                        } & OrganizationSkeletonFragment
                      >
                    >
                  >;
                } & OrganizationSkeletonFragment
              >
            >
          >;
        } & OrganizationSkeletonFragment
      >
    >
  >;
};

export type GetOrganizationQueryVariables = Exact<{
  id: Scalars["String"];
  tlo: Scalars["String"];
}>;

export type GetOrganizationQuery = {
  __typename?: "Query";
  organization?: Maybe<
    {
      __typename?: "Organization";
      countryCode: string;
      vatNumber?: Maybe<string>;
      hasRights?: Maybe<boolean>;
      telephoneNumbers?: Maybe<
        Array<Maybe<{ __typename?: "Telephone"; number: string; telephoneType: string }>>
      >;
    } & OrganizationSkeletonFragment
  >;
};

export type CreateOrganizationMutationVariables = Exact<{
  createOrganization: CreateOrganization;
}>;

export type CreateOrganizationMutation = {
  __typename?: "Mutation";
  createOrganization: { __typename?: "Organization" } & OrganizationSkeletonFragment;
};

export type UpdateOrganizationMutationVariables = Exact<{
  organization: OrganizationInput;
}>;

export type UpdateOrganizationMutation = {
  __typename?: "Mutation";
  updateOrganization: { __typename?: "Organization" } & OrganizationSkeletonFragment;
};

export type ResourcesFromVenueQueryVariables = Exact<{
  id: Scalars["String"];
  tlo: Scalars["String"];
}>;

export type ResourcesFromVenueQuery = {
  __typename?: "Query";
  venue?: Maybe<
    {
      __typename?: "Venue";
      resources?: Maybe<Array<Maybe<{ __typename?: "Resource" } & FullResourceFragment>>>;
    } & VenueSkeletonFragment
  >;
};

export type GetResourceQueryVariables = Exact<{
  id: Scalars["String"];
  tlo: Scalars["String"];
}>;

export type GetResourceQuery = {
  __typename?: "Query";
  resource?: Maybe<{ __typename?: "Resource" } & FullResourceFragment>;
};

export type CreateResourceMutationVariables = Exact<{
  createResource: CreateResource;
}>;

export type CreateResourceMutation = {
  __typename?: "Mutation";
  createResource: { __typename?: "Resource" } & ResourceSkeletonFragment;
};

export type UpdateResourceMutationVariables = Exact<{
  resource: ResourceInput;
}>;

export type UpdateResourceMutation = {
  __typename?: "Mutation";
  updateResource: { __typename?: "Resource" } & ResourceSkeletonFragment;
};

export type DeleteResourceMutationVariables = Exact<{
  id: Scalars["String"];
  tlo: Scalars["String"];
}>;

export type DeleteResourceMutation = { __typename?: "Mutation"; deleteResource: string };

export type ResourceSkeletonFragment = {
  __typename?: "Resource";
  id: string;
  name: string;
  tlo: string;
  type: string;
  sortOrder: number;
};

export type FullResourceFragment = {
  __typename?: "Resource";
  id: string;
  tlo: string;
  name: string;
  type: string;
  hasRights?: Maybe<boolean>;
  sortOrder: number;
  attributes?: Maybe<
    Array<Maybe<{ __typename?: "ResourceAttribute"; type: string; value: string }>>
  >;
};

export type ScheduleRulesFromVenueQueryVariables = Exact<{
  id: Scalars["String"];
  tlo: Scalars["String"];
}>;

export type ScheduleRulesFromVenueQuery = {
  __typename?: "Query";
  venue?: Maybe<
    {
      __typename?: "Venue";
      schedules?: Maybe<Array<Maybe<{ __typename?: "ScheduleRule" } & FullScheduleRuleFragment>>>;
    } & VenueSkeletonFragment
  >;
};

export type CreateScheduleRuleMutationVariables = Exact<{
  createScheduleRule: CreateScheduleRule;
}>;

export type CreateScheduleRuleMutation = {
  __typename?: "Mutation";
  createSchedule: { __typename?: "ScheduleRule" } & ScheduleRuleSkeletonFragment;
};

export type UpdateScheduleRuleMutationVariables = Exact<{
  updateScheduleRule: ScheduleRuleInput;
}>;

export type UpdateScheduleRuleMutation = {
  __typename?: "Mutation";
  updateSchedule: { __typename?: "ScheduleRule" } & FullScheduleRuleFragment;
};

export type DeleteScheduleRuleMutationVariables = Exact<{
  id: Scalars["String"];
  tlo: Scalars["String"];
}>;

export type DeleteScheduleRuleMutation = { __typename?: "Mutation"; deleteSchedule: string };

export type GetScheduleRuleQueryVariables = Exact<{
  id: Scalars["String"];
  tlo: Scalars["String"];
}>;

export type GetScheduleRuleQuery = {
  __typename?: "Query";
  schedule?: Maybe<{ __typename?: "ScheduleRule" } & FullScheduleRuleFragment>;
};

export type ScheduleRuleSkeletonFragment = {
  __typename?: "ScheduleRule";
  id: string;
  tlo: string;
  name: string;
};

export type FullScheduleRuleFragment = {
  __typename?: "ScheduleRule";
  id: string;
  tlo: string;
  name: string;
  startDate: string;
  endDate?: Maybe<string>;
  type?: Maybe<string>;
  description?: Maybe<string>;
  minDuration?: Maybe<number>;
  maxDuration?: Maybe<number>;
  interval?: Maybe<number>;
  sortOrder: number;
  resources?: Maybe<Array<Maybe<string>>>;
  hours?: Maybe<
    Array<
      Maybe<{
        __typename?: "Hours";
        startTime?: Maybe<string>;
        endTime?: Maybe<string>;
        weekdays?: Maybe<Array<Maybe<number>>>;
      }>
    >
  >;
};

export type VenuesFromOrganizationQueryVariables = Exact<{
  id: Scalars["String"];
  tlo: Scalars["String"];
}>;

export type VenuesFromOrganizationQuery = {
  __typename?: "Query";
  organization?: Maybe<
    {
      __typename?: "Organization";
      venues?: Maybe<
        Array<
          Maybe<
            {
              __typename?: "Venue";
              description?: Maybe<string>;
              municipality?: Maybe<string>;
              shortName?: Maybe<string>;
            } & VenueSkeletonFragment
          >
        >
      >;
    } & OrganizationSkeletonFragment
  >;
};

export type GetVenueQueryVariables = Exact<{
  id: Scalars["String"];
  tlo: Scalars["String"];
}>;

export type GetVenueQuery = {
  __typename?: "Query";
  venue?: Maybe<{ __typename?: "Venue" } & FullVenueFragment>;
};

export type CreateVenueMutationVariables = Exact<{
  createVenue: CreateVenue;
}>;

export type CreateVenueMutation = {
  __typename?: "Mutation";
  createVenue: { __typename?: "Venue" } & VenueSkeletonFragment;
};

export type UpdateVenueMutationVariables = Exact<{
  venue: VenueInput;
}>;

export type UpdateVenueMutation = {
  __typename?: "Mutation";
  updateVenue: { __typename?: "Venue" } & FullVenueFragment;
};

export type DeleteVenueMutationVariables = Exact<{
  id: Scalars["String"];
  tlo: Scalars["String"];
}>;

export type DeleteVenueMutation = { __typename?: "Mutation"; deleteVenue: string };

export type VenueSkeletonFragment = { __typename?: "Venue"; id: string; tlo: string; name: string };

export type FullVenueFragment = {
  __typename?: "Venue";
  id: string;
  tlo: string;
  name: string;
  shortName?: Maybe<string>;
  description?: Maybe<string>;
  municipality?: Maybe<string>;
  hasRights?: Maybe<boolean>;
  position?: Maybe<{ __typename?: "Position"; latitude: string; longitude: string }>;
  contactPersons?: Maybe<
    Array<
      Maybe<{
        __typename?: "ContactPerson";
        firstName: string;
        lastName: string;
        contactPersonType: string;
        emails?: Maybe<Array<Maybe<{ __typename?: "Email"; email: string; emailType: string }>>>;
        telephones?: Maybe<
          Array<Maybe<{ __typename?: "Telephone"; number: string; telephoneType: string }>>
        >;
        addresses?: Maybe<
          Array<
            Maybe<{
              __typename?: "Address";
              street: string;
              city: string;
              label?: Maybe<string>;
              region?: Maybe<string>;
              postalCode: string;
              countryCode: string;
              addressType: string;
            }>
          >
        >;
      }>
    >
  >;
  venueUrls?: Maybe<Array<Maybe<{ __typename?: "Url"; urlType: string; url: string }>>>;
  externalUrls?: Maybe<Array<Maybe<{ __typename?: "Url"; urlType: string; url: string }>>>;
  openingHours?: Maybe<
    Array<
      Maybe<{
        __typename?: "OpeningHours";
        description?: Maybe<string>;
        monday?: Maybe<
          Array<Maybe<{ __typename?: "TimeInterval"; startTime: string; endTime: string }>>
        >;
        tuesday?: Maybe<
          Array<Maybe<{ __typename?: "TimeInterval"; startTime: string; endTime: string }>>
        >;
        wednesday?: Maybe<
          Array<Maybe<{ __typename?: "TimeInterval"; startTime: string; endTime: string }>>
        >;
        thursday?: Maybe<
          Array<Maybe<{ __typename?: "TimeInterval"; startTime: string; endTime: string }>>
        >;
        friday?: Maybe<
          Array<Maybe<{ __typename?: "TimeInterval"; startTime: string; endTime: string }>>
        >;
        saturday?: Maybe<
          Array<Maybe<{ __typename?: "TimeInterval"; startTime: string; endTime: string }>>
        >;
        sunday?: Maybe<
          Array<Maybe<{ __typename?: "TimeInterval"; startTime: string; endTime: string }>>
        >;
      }>
    >
  >;
  emails?: Maybe<Array<Maybe<{ __typename?: "Email"; email: string; emailType: string }>>>;
  addresses?: Maybe<
    Array<
      Maybe<{
        __typename?: "Address";
        street: string;
        city: string;
        label?: Maybe<string>;
        region?: Maybe<string>;
        postalCode: string;
        countryCode: string;
        addressType: string;
      }>
    >
  >;
  telephoneNumbers?: Maybe<
    Array<Maybe<{ __typename?: "Telephone"; number: string; telephoneType: string }>>
  >;
  resources?: Maybe<Array<Maybe<{ __typename?: "Resource" } & FullResourceFragment>>>;
};

export type ConfigurationQueryVariables = Exact<{
  countryCode: Scalars["String"];
}>;

export type ConfigurationQuery = {
  __typename?: "Query";
  configuration?: Maybe<
    { __typename?: "Config" } & ResourceConfigurationFragment &
      ContactConfigurationFragment &
      LocationConfigurationFragment
  >;
};

export type ResourceConfigurationFragment = {
  __typename?: "Config";
  resourceConfiguration?: Maybe<
    Array<Maybe<{ __typename?: "Configuration" } & ConfigFieldsFragment>>
  >;
};

export type ContactConfigurationFragment = {
  __typename?: "Config";
  contactConfiguration?: Maybe<
    Array<Maybe<{ __typename?: "Configuration" } & ConfigFieldsFragment>>
  >;
};

export type LocationConfigurationFragment = {
  __typename?: "Config";
  locationConfiguration?: Maybe<
    Array<Maybe<{ __typename?: "Configuration" } & ConfigFieldsFragment>>
  >;
};

export type ConfigFieldsFragment = {
  __typename?: "Configuration";
  id: string;
  configType: string;
  configCategory: ConfigCategory;
  configAttributes?: Maybe<
    Array<
      Maybe<{
        __typename?: "ConfigAttribute";
        sortOrder: number;
        attribute: string;
        mandatory: boolean;
        editable: boolean;
        visible: boolean;
        type: string;
        options?: Maybe<Array<Maybe<string>>>;
      }>
    >
  >;
};

export const FullBoundariesFragmentDoc = gql`
  fragment FullBoundaries on Boundaries {
    startTime
    endTime
  }
`;
export const FullBookingFragmentDoc = gql`
  fragment FullBooking on Booking {
    id
    tlo
    status
    startTime
    endTime
    type
    createdAt
    createdBy
    channel
    resources
    comment
  }
`;
export const BookingWithEventsFragmentDoc = gql`
  fragment BookingWithEvents on Booking {
    ...FullBooking
    players
    events {
      createdAt
      createdBy
      status
      reason
    }
  }
  ${FullBookingFragmentDoc}
`;
export const FullCustomerFragmentDoc = gql`
  fragment FullCustomer on Customer {
    id
    tlo
    customerNumber
    status
    givenName
    familyName
    email
    telephoneNumbers {
      number
      telephoneType
    }
  }
`;
export const OrganizationSkeletonFragmentDoc = gql`
  fragment OrganizationSkeleton on Organization {
    id
    tlo
    name
  }
`;
export const ResourceSkeletonFragmentDoc = gql`
  fragment ResourceSkeleton on Resource {
    id
    name
    tlo
    type
    sortOrder
  }
`;
export const ScheduleRuleSkeletonFragmentDoc = gql`
  fragment ScheduleRuleSkeleton on ScheduleRule {
    id
    tlo
    name
  }
`;
export const FullScheduleRuleFragmentDoc = gql`
  fragment FullScheduleRule on ScheduleRule {
    id
    tlo
    name
    startDate
    endDate
    type
    description
    minDuration
    maxDuration
    interval
    sortOrder
    resources
    hours {
      startTime
      endTime
      weekdays
    }
  }
`;
export const VenueSkeletonFragmentDoc = gql`
  fragment VenueSkeleton on Venue {
    id
    tlo
    name
  }
`;
export const FullResourceFragmentDoc = gql`
  fragment FullResource on Resource {
    id
    tlo
    name
    type
    hasRights
    sortOrder
    attributes {
      type
      value
    }
  }
`;
export const FullVenueFragmentDoc = gql`
  fragment FullVenue on Venue {
    id
    tlo
    name
    shortName
    description
    municipality
    position {
      latitude
      longitude
    }
    hasRights
    contactPersons {
      firstName
      lastName
      contactPersonType
      emails {
        email
        emailType
      }
      telephones {
        number
        telephoneType
      }
      addresses {
        street
        city
        label
        region
        postalCode
        countryCode
        addressType
      }
    }
    venueUrls {
      urlType
      url
    }
    externalUrls {
      urlType
      url
    }
    openingHours {
      monday {
        startTime
        endTime
      }
      tuesday {
        startTime
        endTime
      }
      wednesday {
        startTime
        endTime
      }
      thursday {
        startTime
        endTime
      }
      friday {
        startTime
        endTime
      }
      saturday {
        startTime
        endTime
      }
      sunday {
        startTime
        endTime
      }
      description
    }
    emails {
      email
      emailType
    }
    addresses {
      street
      city
      label
      region
      postalCode
      countryCode
      addressType
    }
    telephoneNumbers {
      number
      telephoneType
    }
    resources {
      ...FullResource
    }
  }
  ${FullResourceFragmentDoc}
`;
export const ConfigFieldsFragmentDoc = gql`
  fragment configFields on Configuration {
    id
    configType
    configCategory
    configAttributes {
      sortOrder
      attribute
      mandatory
      editable
      visible
      type
      options
    }
  }
`;
export const ResourceConfigurationFragmentDoc = gql`
  fragment resourceConfiguration on Config {
    resourceConfiguration {
      ...configFields
    }
  }
  ${ConfigFieldsFragmentDoc}
`;
export const ContactConfigurationFragmentDoc = gql`
  fragment contactConfiguration on Config {
    contactConfiguration {
      ...configFields
    }
  }
  ${ConfigFieldsFragmentDoc}
`;
export const LocationConfigurationFragmentDoc = gql`
  fragment locationConfiguration on Config {
    locationConfiguration {
      ...configFields
    }
  }
  ${ConfigFieldsFragmentDoc}
`;
export const BookingsDocument = gql`
  query bookings($venue: String!, $tlo: String!, $date: AWSDate!) {
    bookings(venue: $venue, tlo: $tlo, date: $date) {
      boundaries {
        ...FullBoundaries
      }
      bookings {
        ...FullBooking
      }
    }
  }
  ${FullBoundariesFragmentDoc}
  ${FullBookingFragmentDoc}
`;

/**
 * __useBookingsQuery__
 *
 * To run a query within a React component, call `useBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingsQuery({
 *   variables: {
 *      venue: // value for 'venue'
 *      tlo: // value for 'tlo'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useBookingsQuery(
  baseOptions: Apollo.QueryHookOptions<BookingsQuery, BookingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookingsQuery, BookingsQueryVariables>(BookingsDocument, options);
}
export function useBookingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookingsQuery, BookingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookingsQuery, BookingsQueryVariables>(BookingsDocument, options);
}
export type BookingsQueryHookResult = ReturnType<typeof useBookingsQuery>;
export type BookingsLazyQueryHookResult = ReturnType<typeof useBookingsLazyQuery>;
export type BookingsQueryResult = Apollo.QueryResult<BookingsQuery, BookingsQueryVariables>;
export const BookingsWithEventsDocument = gql`
  query bookingsWithEvents($venue: String!, $tlo: String!, $date: AWSDate!) {
    bookings(venue: $venue, tlo: $tlo, date: $date) {
      bookings {
        ...BookingWithEvents
      }
    }
  }
  ${BookingWithEventsFragmentDoc}
`;

/**
 * __useBookingsWithEventsQuery__
 *
 * To run a query within a React component, call `useBookingsWithEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingsWithEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingsWithEventsQuery({
 *   variables: {
 *      venue: // value for 'venue'
 *      tlo: // value for 'tlo'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useBookingsWithEventsQuery(
  baseOptions: Apollo.QueryHookOptions<BookingsWithEventsQuery, BookingsWithEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookingsWithEventsQuery, BookingsWithEventsQueryVariables>(
    BookingsWithEventsDocument,
    options,
  );
}
export function useBookingsWithEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BookingsWithEventsQuery,
    BookingsWithEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookingsWithEventsQuery, BookingsWithEventsQueryVariables>(
    BookingsWithEventsDocument,
    options,
  );
}
export type BookingsWithEventsQueryHookResult = ReturnType<typeof useBookingsWithEventsQuery>;
export type BookingsWithEventsLazyQueryHookResult = ReturnType<
  typeof useBookingsWithEventsLazyQuery
>;
export type BookingsWithEventsQueryResult = Apollo.QueryResult<
  BookingsWithEventsQuery,
  BookingsWithEventsQueryVariables
>;
export const BookingWithEventsDocument = gql`
  query bookingWithEvents($id: String!, $tlo: String!) {
    booking(id: $id, tlo: $tlo) {
      ...BookingWithEvents
    }
  }
  ${BookingWithEventsFragmentDoc}
`;

/**
 * __useBookingWithEventsQuery__
 *
 * To run a query within a React component, call `useBookingWithEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingWithEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingWithEventsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tlo: // value for 'tlo'
 *   },
 * });
 */
export function useBookingWithEventsQuery(
  baseOptions: Apollo.QueryHookOptions<BookingWithEventsQuery, BookingWithEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookingWithEventsQuery, BookingWithEventsQueryVariables>(
    BookingWithEventsDocument,
    options,
  );
}
export function useBookingWithEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BookingWithEventsQuery,
    BookingWithEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookingWithEventsQuery, BookingWithEventsQueryVariables>(
    BookingWithEventsDocument,
    options,
  );
}
export type BookingWithEventsQueryHookResult = ReturnType<typeof useBookingWithEventsQuery>;
export type BookingWithEventsLazyQueryHookResult = ReturnType<typeof useBookingWithEventsLazyQuery>;
export type BookingWithEventsQueryResult = Apollo.QueryResult<
  BookingWithEventsQuery,
  BookingWithEventsQueryVariables
>;
export const CreateBookingDocument = gql`
  mutation createBooking($createBooking: CreateBooking!) {
    createBooking(createBooking: $createBooking) {
      ...FullBooking
    }
  }
  ${FullBookingFragmentDoc}
`;
export type CreateBookingMutationFn = Apollo.MutationFunction<
  CreateBookingMutation,
  CreateBookingMutationVariables
>;

/**
 * __useCreateBookingMutation__
 *
 * To run a mutation, you first call `useCreateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingMutation, { data, loading, error }] = useCreateBookingMutation({
 *   variables: {
 *      createBooking: // value for 'createBooking'
 *   },
 * });
 */
export function useCreateBookingMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBookingMutation, CreateBookingMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBookingMutation, CreateBookingMutationVariables>(
    CreateBookingDocument,
    options,
  );
}
export type CreateBookingMutationHookResult = ReturnType<typeof useCreateBookingMutation>;
export type CreateBookingMutationResult = Apollo.MutationResult<CreateBookingMutation>;
export type CreateBookingMutationOptions = Apollo.BaseMutationOptions<
  CreateBookingMutation,
  CreateBookingMutationVariables
>;
export const UpdateBookingDocument = gql`
  mutation updateBooking($updateBooking: BookingInput!) {
    updateBooking(booking: $updateBooking) {
      ...FullBooking
    }
  }
  ${FullBookingFragmentDoc}
`;
export type UpdateBookingMutationFn = Apollo.MutationFunction<
  UpdateBookingMutation,
  UpdateBookingMutationVariables
>;

/**
 * __useUpdateBookingMutation__
 *
 * To run a mutation, you first call `useUpdateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingMutation, { data, loading, error }] = useUpdateBookingMutation({
 *   variables: {
 *      updateBooking: // value for 'updateBooking'
 *   },
 * });
 */
export function useUpdateBookingMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBookingMutation, UpdateBookingMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBookingMutation, UpdateBookingMutationVariables>(
    UpdateBookingDocument,
    options,
  );
}
export type UpdateBookingMutationHookResult = ReturnType<typeof useUpdateBookingMutation>;
export type UpdateBookingMutationResult = Apollo.MutationResult<UpdateBookingMutation>;
export type UpdateBookingMutationOptions = Apollo.BaseMutationOptions<
  UpdateBookingMutation,
  UpdateBookingMutationVariables
>;
export const DeleteBookingDocument = gql`
  mutation deleteBooking($id: String!, $tlo: String!) {
    deleteBooking(id: $id, tlo: $tlo)
  }
`;
export type DeleteBookingMutationFn = Apollo.MutationFunction<
  DeleteBookingMutation,
  DeleteBookingMutationVariables
>;

/**
 * __useDeleteBookingMutation__
 *
 * To run a mutation, you first call `useDeleteBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookingMutation, { data, loading, error }] = useDeleteBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tlo: // value for 'tlo'
 *   },
 * });
 */
export function useDeleteBookingMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteBookingMutation, DeleteBookingMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBookingMutation, DeleteBookingMutationVariables>(
    DeleteBookingDocument,
    options,
  );
}
export type DeleteBookingMutationHookResult = ReturnType<typeof useDeleteBookingMutation>;
export type DeleteBookingMutationResult = Apollo.MutationResult<DeleteBookingMutation>;
export type DeleteBookingMutationOptions = Apollo.BaseMutationOptions<
  DeleteBookingMutation,
  DeleteBookingMutationVariables
>;
export const CustomersFromVenueDocument = gql`
  query customersFromVenue($id: String!, $tlo: String!) {
    venue(id: $id, tlo: $tlo) {
      ...VenueSkeleton
      customers {
        ...FullCustomer
      }
    }
  }
  ${VenueSkeletonFragmentDoc}
  ${FullCustomerFragmentDoc}
`;

/**
 * __useCustomersFromVenueQuery__
 *
 * To run a query within a React component, call `useCustomersFromVenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersFromVenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersFromVenueQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tlo: // value for 'tlo'
 *   },
 * });
 */
export function useCustomersFromVenueQuery(
  baseOptions: Apollo.QueryHookOptions<CustomersFromVenueQuery, CustomersFromVenueQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomersFromVenueQuery, CustomersFromVenueQueryVariables>(
    CustomersFromVenueDocument,
    options,
  );
}
export function useCustomersFromVenueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomersFromVenueQuery,
    CustomersFromVenueQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomersFromVenueQuery, CustomersFromVenueQueryVariables>(
    CustomersFromVenueDocument,
    options,
  );
}
export type CustomersFromVenueQueryHookResult = ReturnType<typeof useCustomersFromVenueQuery>;
export type CustomersFromVenueLazyQueryHookResult = ReturnType<
  typeof useCustomersFromVenueLazyQuery
>;
export type CustomersFromVenueQueryResult = Apollo.QueryResult<
  CustomersFromVenueQuery,
  CustomersFromVenueQueryVariables
>;
export const GetCustomerDocument = gql`
  query getCustomer($id: String!, $tlo: String!) {
    customer(id: $id, tlo: $tlo) {
      ...FullCustomer
    }
  }
  ${FullCustomerFragmentDoc}
`;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tlo: // value for 'tlo'
 *   },
 * });
 */
export function useGetCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
}
export function useGetCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(
    GetCustomerDocument,
    options,
  );
}
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<
  GetCustomerQuery,
  GetCustomerQueryVariables
>;
export const CreateCustomerDocument = gql`
  mutation createCustomer($createCustomer: CreateCustomer!) {
    createCustomer(createCustomer: $createCustomer) {
      ...FullCustomer
    }
  }
  ${FullCustomerFragmentDoc}
`;
export type CreateCustomerMutationFn = Apollo.MutationFunction<
  CreateCustomerMutation,
  CreateCustomerMutationVariables
>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      createCustomer: // value for 'createCustomer'
 *   },
 * });
 */
export function useCreateCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(
    CreateCustomerDocument,
    options,
  );
}
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<
  CreateCustomerMutation,
  CreateCustomerMutationVariables
>;
export const UpdateCustomerDocument = gql`
  mutation updateCustomer($customer: CustomerInput!) {
    updateCustomer(customer: $customer) {
      ...FullCustomer
    }
  }
  ${FullCustomerFragmentDoc}
`;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useUpdateCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(
    UpdateCustomerDocument,
    options,
  );
}
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>;
export const DeleteCustomerDocument = gql`
  mutation deleteCustomer($id: String!, $tlo: String!) {
    deleteCustomer(id: $id, tlo: $tlo)
  }
`;
export type DeleteCustomerMutationFn = Apollo.MutationFunction<
  DeleteCustomerMutation,
  DeleteCustomerMutationVariables
>;

/**
 * __useDeleteCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerMutation, { data, loading, error }] = useDeleteCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tlo: // value for 'tlo'
 *   },
 * });
 */
export function useDeleteCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCustomerMutation, DeleteCustomerMutationVariables>(
    DeleteCustomerDocument,
    options,
  );
}
export type DeleteCustomerMutationHookResult = ReturnType<typeof useDeleteCustomerMutation>;
export type DeleteCustomerMutationResult = Apollo.MutationResult<DeleteCustomerMutation>;
export type DeleteCustomerMutationOptions = Apollo.BaseMutationOptions<
  DeleteCustomerMutation,
  DeleteCustomerMutationVariables
>;
export const GetOrganizationSkeletonsDocument = gql`
  query getOrganizationSkeletons {
    organizations {
      ...OrganizationSkeleton
      organizations {
        ...OrganizationSkeleton
        organizations {
          ...OrganizationSkeleton
          organizations {
            ...OrganizationSkeleton
          }
        }
      }
    }
  }
  ${OrganizationSkeletonFragmentDoc}
`;

/**
 * __useGetOrganizationSkeletonsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationSkeletonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationSkeletonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationSkeletonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationSkeletonsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrganizationSkeletonsQuery,
    GetOrganizationSkeletonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrganizationSkeletonsQuery, GetOrganizationSkeletonsQueryVariables>(
    GetOrganizationSkeletonsDocument,
    options,
  );
}
export function useGetOrganizationSkeletonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationSkeletonsQuery,
    GetOrganizationSkeletonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrganizationSkeletonsQuery, GetOrganizationSkeletonsQueryVariables>(
    GetOrganizationSkeletonsDocument,
    options,
  );
}
export type GetOrganizationSkeletonsQueryHookResult = ReturnType<
  typeof useGetOrganizationSkeletonsQuery
>;
export type GetOrganizationSkeletonsLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationSkeletonsLazyQuery
>;
export type GetOrganizationSkeletonsQueryResult = Apollo.QueryResult<
  GetOrganizationSkeletonsQuery,
  GetOrganizationSkeletonsQueryVariables
>;
export const GetOrganizationDocument = gql`
  query getOrganization($id: String!, $tlo: String!) {
    organization(id: $id, tlo: $tlo) {
      ...OrganizationSkeleton
      countryCode
      telephoneNumbers {
        number
        telephoneType
      }
      vatNumber
      hasRights
    }
  }
  ${OrganizationSkeletonFragmentDoc}
`;

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tlo: // value for 'tlo'
 *   },
 * });
 */
export function useGetOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(
    GetOrganizationDocument,
    options,
  );
}
export function useGetOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(
    GetOrganizationDocument,
    options,
  );
}
export type GetOrganizationQueryHookResult = ReturnType<typeof useGetOrganizationQuery>;
export type GetOrganizationLazyQueryHookResult = ReturnType<typeof useGetOrganizationLazyQuery>;
export type GetOrganizationQueryResult = Apollo.QueryResult<
  GetOrganizationQuery,
  GetOrganizationQueryVariables
>;
export const CreateOrganizationDocument = gql`
  mutation createOrganization($createOrganization: CreateOrganization!) {
    createOrganization(createOrganization: $createOrganization) {
      ...OrganizationSkeleton
    }
  }
  ${OrganizationSkeletonFragmentDoc}
`;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      createOrganization: // value for 'createOrganization'
 *   },
 * });
 */
export function useCreateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(
    CreateOrganizationDocument,
    options,
  );
}
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;
export const UpdateOrganizationDocument = gql`
  mutation updateOrganization($organization: OrganizationInput!) {
    updateOrganization(organization: $organization) {
      ...OrganizationSkeleton
    }
  }
  ${OrganizationSkeletonFragmentDoc}
`;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      organization: // value for 'organization'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(
    UpdateOrganizationDocument,
    options,
  );
}
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;
export const ResourcesFromVenueDocument = gql`
  query resourcesFromVenue($id: String!, $tlo: String!) {
    venue(id: $id, tlo: $tlo) {
      ...VenueSkeleton
      resources {
        ...FullResource
      }
    }
  }
  ${VenueSkeletonFragmentDoc}
  ${FullResourceFragmentDoc}
`;

/**
 * __useResourcesFromVenueQuery__
 *
 * To run a query within a React component, call `useResourcesFromVenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcesFromVenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcesFromVenueQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tlo: // value for 'tlo'
 *   },
 * });
 */
export function useResourcesFromVenueQuery(
  baseOptions: Apollo.QueryHookOptions<ResourcesFromVenueQuery, ResourcesFromVenueQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResourcesFromVenueQuery, ResourcesFromVenueQueryVariables>(
    ResourcesFromVenueDocument,
    options,
  );
}
export function useResourcesFromVenueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourcesFromVenueQuery,
    ResourcesFromVenueQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResourcesFromVenueQuery, ResourcesFromVenueQueryVariables>(
    ResourcesFromVenueDocument,
    options,
  );
}
export type ResourcesFromVenueQueryHookResult = ReturnType<typeof useResourcesFromVenueQuery>;
export type ResourcesFromVenueLazyQueryHookResult = ReturnType<
  typeof useResourcesFromVenueLazyQuery
>;
export type ResourcesFromVenueQueryResult = Apollo.QueryResult<
  ResourcesFromVenueQuery,
  ResourcesFromVenueQueryVariables
>;
export const GetResourceDocument = gql`
  query getResource($id: String!, $tlo: String!) {
    resource(id: $id, tlo: $tlo) {
      ...FullResource
    }
  }
  ${FullResourceFragmentDoc}
`;

/**
 * __useGetResourceQuery__
 *
 * To run a query within a React component, call `useGetResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tlo: // value for 'tlo'
 *   },
 * });
 */
export function useGetResourceQuery(
  baseOptions: Apollo.QueryHookOptions<GetResourceQuery, GetResourceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetResourceQuery, GetResourceQueryVariables>(GetResourceDocument, options);
}
export function useGetResourceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetResourceQuery, GetResourceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetResourceQuery, GetResourceQueryVariables>(
    GetResourceDocument,
    options,
  );
}
export type GetResourceQueryHookResult = ReturnType<typeof useGetResourceQuery>;
export type GetResourceLazyQueryHookResult = ReturnType<typeof useGetResourceLazyQuery>;
export type GetResourceQueryResult = Apollo.QueryResult<
  GetResourceQuery,
  GetResourceQueryVariables
>;
export const CreateResourceDocument = gql`
  mutation createResource($createResource: CreateResource!) {
    createResource(createResource: $createResource) {
      ...ResourceSkeleton
    }
  }
  ${ResourceSkeletonFragmentDoc}
`;
export type CreateResourceMutationFn = Apollo.MutationFunction<
  CreateResourceMutation,
  CreateResourceMutationVariables
>;

/**
 * __useCreateResourceMutation__
 *
 * To run a mutation, you first call `useCreateResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createResourceMutation, { data, loading, error }] = useCreateResourceMutation({
 *   variables: {
 *      createResource: // value for 'createResource'
 *   },
 * });
 */
export function useCreateResourceMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateResourceMutation, CreateResourceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateResourceMutation, CreateResourceMutationVariables>(
    CreateResourceDocument,
    options,
  );
}
export type CreateResourceMutationHookResult = ReturnType<typeof useCreateResourceMutation>;
export type CreateResourceMutationResult = Apollo.MutationResult<CreateResourceMutation>;
export type CreateResourceMutationOptions = Apollo.BaseMutationOptions<
  CreateResourceMutation,
  CreateResourceMutationVariables
>;
export const UpdateResourceDocument = gql`
  mutation updateResource($resource: ResourceInput!) {
    updateResource(resource: $resource) {
      ...ResourceSkeleton
    }
  }
  ${ResourceSkeletonFragmentDoc}
`;
export type UpdateResourceMutationFn = Apollo.MutationFunction<
  UpdateResourceMutation,
  UpdateResourceMutationVariables
>;

/**
 * __useUpdateResourceMutation__
 *
 * To run a mutation, you first call `useUpdateResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResourceMutation, { data, loading, error }] = useUpdateResourceMutation({
 *   variables: {
 *      resource: // value for 'resource'
 *   },
 * });
 */
export function useUpdateResourceMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateResourceMutation, UpdateResourceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateResourceMutation, UpdateResourceMutationVariables>(
    UpdateResourceDocument,
    options,
  );
}
export type UpdateResourceMutationHookResult = ReturnType<typeof useUpdateResourceMutation>;
export type UpdateResourceMutationResult = Apollo.MutationResult<UpdateResourceMutation>;
export type UpdateResourceMutationOptions = Apollo.BaseMutationOptions<
  UpdateResourceMutation,
  UpdateResourceMutationVariables
>;
export const DeleteResourceDocument = gql`
  mutation deleteResource($id: String!, $tlo: String!) {
    deleteResource(id: $id, tlo: $tlo)
  }
`;
export type DeleteResourceMutationFn = Apollo.MutationFunction<
  DeleteResourceMutation,
  DeleteResourceMutationVariables
>;

/**
 * __useDeleteResourceMutation__
 *
 * To run a mutation, you first call `useDeleteResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResourceMutation, { data, loading, error }] = useDeleteResourceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tlo: // value for 'tlo'
 *   },
 * });
 */
export function useDeleteResourceMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteResourceMutation, DeleteResourceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteResourceMutation, DeleteResourceMutationVariables>(
    DeleteResourceDocument,
    options,
  );
}
export type DeleteResourceMutationHookResult = ReturnType<typeof useDeleteResourceMutation>;
export type DeleteResourceMutationResult = Apollo.MutationResult<DeleteResourceMutation>;
export type DeleteResourceMutationOptions = Apollo.BaseMutationOptions<
  DeleteResourceMutation,
  DeleteResourceMutationVariables
>;
export const ScheduleRulesFromVenueDocument = gql`
  query scheduleRulesFromVenue($id: String!, $tlo: String!) {
    venue(id: $id, tlo: $tlo) {
      ...VenueSkeleton
      schedules {
        ...FullScheduleRule
      }
    }
  }
  ${VenueSkeletonFragmentDoc}
  ${FullScheduleRuleFragmentDoc}
`;

/**
 * __useScheduleRulesFromVenueQuery__
 *
 * To run a query within a React component, call `useScheduleRulesFromVenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleRulesFromVenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleRulesFromVenueQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tlo: // value for 'tlo'
 *   },
 * });
 */
export function useScheduleRulesFromVenueQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScheduleRulesFromVenueQuery,
    ScheduleRulesFromVenueQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScheduleRulesFromVenueQuery, ScheduleRulesFromVenueQueryVariables>(
    ScheduleRulesFromVenueDocument,
    options,
  );
}
export function useScheduleRulesFromVenueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScheduleRulesFromVenueQuery,
    ScheduleRulesFromVenueQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScheduleRulesFromVenueQuery, ScheduleRulesFromVenueQueryVariables>(
    ScheduleRulesFromVenueDocument,
    options,
  );
}
export type ScheduleRulesFromVenueQueryHookResult = ReturnType<
  typeof useScheduleRulesFromVenueQuery
>;
export type ScheduleRulesFromVenueLazyQueryHookResult = ReturnType<
  typeof useScheduleRulesFromVenueLazyQuery
>;
export type ScheduleRulesFromVenueQueryResult = Apollo.QueryResult<
  ScheduleRulesFromVenueQuery,
  ScheduleRulesFromVenueQueryVariables
>;
export const CreateScheduleRuleDocument = gql`
  mutation createScheduleRule($createScheduleRule: CreateScheduleRule!) {
    createSchedule(createSchedule: $createScheduleRule) {
      ...ScheduleRuleSkeleton
    }
  }
  ${ScheduleRuleSkeletonFragmentDoc}
`;
export type CreateScheduleRuleMutationFn = Apollo.MutationFunction<
  CreateScheduleRuleMutation,
  CreateScheduleRuleMutationVariables
>;

/**
 * __useCreateScheduleRuleMutation__
 *
 * To run a mutation, you first call `useCreateScheduleRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduleRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduleRuleMutation, { data, loading, error }] = useCreateScheduleRuleMutation({
 *   variables: {
 *      createScheduleRule: // value for 'createScheduleRule'
 *   },
 * });
 */
export function useCreateScheduleRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateScheduleRuleMutation,
    CreateScheduleRuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateScheduleRuleMutation, CreateScheduleRuleMutationVariables>(
    CreateScheduleRuleDocument,
    options,
  );
}
export type CreateScheduleRuleMutationHookResult = ReturnType<typeof useCreateScheduleRuleMutation>;
export type CreateScheduleRuleMutationResult = Apollo.MutationResult<CreateScheduleRuleMutation>;
export type CreateScheduleRuleMutationOptions = Apollo.BaseMutationOptions<
  CreateScheduleRuleMutation,
  CreateScheduleRuleMutationVariables
>;
export const UpdateScheduleRuleDocument = gql`
  mutation updateScheduleRule($updateScheduleRule: ScheduleRuleInput!) {
    updateSchedule(schedule: $updateScheduleRule) {
      ...FullScheduleRule
    }
  }
  ${FullScheduleRuleFragmentDoc}
`;
export type UpdateScheduleRuleMutationFn = Apollo.MutationFunction<
  UpdateScheduleRuleMutation,
  UpdateScheduleRuleMutationVariables
>;

/**
 * __useUpdateScheduleRuleMutation__
 *
 * To run a mutation, you first call `useUpdateScheduleRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduleRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduleRuleMutation, { data, loading, error }] = useUpdateScheduleRuleMutation({
 *   variables: {
 *      updateScheduleRule: // value for 'updateScheduleRule'
 *   },
 * });
 */
export function useUpdateScheduleRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScheduleRuleMutation,
    UpdateScheduleRuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateScheduleRuleMutation, UpdateScheduleRuleMutationVariables>(
    UpdateScheduleRuleDocument,
    options,
  );
}
export type UpdateScheduleRuleMutationHookResult = ReturnType<typeof useUpdateScheduleRuleMutation>;
export type UpdateScheduleRuleMutationResult = Apollo.MutationResult<UpdateScheduleRuleMutation>;
export type UpdateScheduleRuleMutationOptions = Apollo.BaseMutationOptions<
  UpdateScheduleRuleMutation,
  UpdateScheduleRuleMutationVariables
>;
export const DeleteScheduleRuleDocument = gql`
  mutation deleteScheduleRule($id: String!, $tlo: String!) {
    deleteSchedule(id: $id, tlo: $tlo)
  }
`;
export type DeleteScheduleRuleMutationFn = Apollo.MutationFunction<
  DeleteScheduleRuleMutation,
  DeleteScheduleRuleMutationVariables
>;

/**
 * __useDeleteScheduleRuleMutation__
 *
 * To run a mutation, you first call `useDeleteScheduleRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduleRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScheduleRuleMutation, { data, loading, error }] = useDeleteScheduleRuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tlo: // value for 'tlo'
 *   },
 * });
 */
export function useDeleteScheduleRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteScheduleRuleMutation,
    DeleteScheduleRuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteScheduleRuleMutation, DeleteScheduleRuleMutationVariables>(
    DeleteScheduleRuleDocument,
    options,
  );
}
export type DeleteScheduleRuleMutationHookResult = ReturnType<typeof useDeleteScheduleRuleMutation>;
export type DeleteScheduleRuleMutationResult = Apollo.MutationResult<DeleteScheduleRuleMutation>;
export type DeleteScheduleRuleMutationOptions = Apollo.BaseMutationOptions<
  DeleteScheduleRuleMutation,
  DeleteScheduleRuleMutationVariables
>;
export const GetScheduleRuleDocument = gql`
  query getScheduleRule($id: String!, $tlo: String!) {
    schedule(id: $id, tlo: $tlo) {
      ...FullScheduleRule
    }
  }
  ${FullScheduleRuleFragmentDoc}
`;

/**
 * __useGetScheduleRuleQuery__
 *
 * To run a query within a React component, call `useGetScheduleRuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduleRuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduleRuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tlo: // value for 'tlo'
 *   },
 * });
 */
export function useGetScheduleRuleQuery(
  baseOptions: Apollo.QueryHookOptions<GetScheduleRuleQuery, GetScheduleRuleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetScheduleRuleQuery, GetScheduleRuleQueryVariables>(
    GetScheduleRuleDocument,
    options,
  );
}
export function useGetScheduleRuleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetScheduleRuleQuery, GetScheduleRuleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetScheduleRuleQuery, GetScheduleRuleQueryVariables>(
    GetScheduleRuleDocument,
    options,
  );
}
export type GetScheduleRuleQueryHookResult = ReturnType<typeof useGetScheduleRuleQuery>;
export type GetScheduleRuleLazyQueryHookResult = ReturnType<typeof useGetScheduleRuleLazyQuery>;
export type GetScheduleRuleQueryResult = Apollo.QueryResult<
  GetScheduleRuleQuery,
  GetScheduleRuleQueryVariables
>;
export const VenuesFromOrganizationDocument = gql`
  query venuesFromOrganization($id: String!, $tlo: String!) {
    organization(id: $id, tlo: $tlo) {
      ...OrganizationSkeleton
      venues {
        ...VenueSkeleton
        description
        municipality
        shortName
      }
    }
  }
  ${OrganizationSkeletonFragmentDoc}
  ${VenueSkeletonFragmentDoc}
`;

/**
 * __useVenuesFromOrganizationQuery__
 *
 * To run a query within a React component, call `useVenuesFromOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenuesFromOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVenuesFromOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tlo: // value for 'tlo'
 *   },
 * });
 */
export function useVenuesFromOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    VenuesFromOrganizationQuery,
    VenuesFromOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VenuesFromOrganizationQuery, VenuesFromOrganizationQueryVariables>(
    VenuesFromOrganizationDocument,
    options,
  );
}
export function useVenuesFromOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VenuesFromOrganizationQuery,
    VenuesFromOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VenuesFromOrganizationQuery, VenuesFromOrganizationQueryVariables>(
    VenuesFromOrganizationDocument,
    options,
  );
}
export type VenuesFromOrganizationQueryHookResult = ReturnType<
  typeof useVenuesFromOrganizationQuery
>;
export type VenuesFromOrganizationLazyQueryHookResult = ReturnType<
  typeof useVenuesFromOrganizationLazyQuery
>;
export type VenuesFromOrganizationQueryResult = Apollo.QueryResult<
  VenuesFromOrganizationQuery,
  VenuesFromOrganizationQueryVariables
>;
export const GetVenueDocument = gql`
  query getVenue($id: String!, $tlo: String!) {
    venue(id: $id, tlo: $tlo) {
      ...FullVenue
    }
  }
  ${FullVenueFragmentDoc}
`;

/**
 * __useGetVenueQuery__
 *
 * To run a query within a React component, call `useGetVenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVenueQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tlo: // value for 'tlo'
 *   },
 * });
 */
export function useGetVenueQuery(
  baseOptions: Apollo.QueryHookOptions<GetVenueQuery, GetVenueQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVenueQuery, GetVenueQueryVariables>(GetVenueDocument, options);
}
export function useGetVenueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVenueQuery, GetVenueQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVenueQuery, GetVenueQueryVariables>(GetVenueDocument, options);
}
export type GetVenueQueryHookResult = ReturnType<typeof useGetVenueQuery>;
export type GetVenueLazyQueryHookResult = ReturnType<typeof useGetVenueLazyQuery>;
export type GetVenueQueryResult = Apollo.QueryResult<GetVenueQuery, GetVenueQueryVariables>;
export const CreateVenueDocument = gql`
  mutation createVenue($createVenue: CreateVenue!) {
    createVenue(createVenue: $createVenue) {
      ...VenueSkeleton
    }
  }
  ${VenueSkeletonFragmentDoc}
`;
export type CreateVenueMutationFn = Apollo.MutationFunction<
  CreateVenueMutation,
  CreateVenueMutationVariables
>;

/**
 * __useCreateVenueMutation__
 *
 * To run a mutation, you first call `useCreateVenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVenueMutation, { data, loading, error }] = useCreateVenueMutation({
 *   variables: {
 *      createVenue: // value for 'createVenue'
 *   },
 * });
 */
export function useCreateVenueMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateVenueMutation, CreateVenueMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVenueMutation, CreateVenueMutationVariables>(
    CreateVenueDocument,
    options,
  );
}
export type CreateVenueMutationHookResult = ReturnType<typeof useCreateVenueMutation>;
export type CreateVenueMutationResult = Apollo.MutationResult<CreateVenueMutation>;
export type CreateVenueMutationOptions = Apollo.BaseMutationOptions<
  CreateVenueMutation,
  CreateVenueMutationVariables
>;
export const UpdateVenueDocument = gql`
  mutation updateVenue($venue: VenueInput!) {
    updateVenue(venue: $venue) {
      ...FullVenue
    }
  }
  ${FullVenueFragmentDoc}
`;
export type UpdateVenueMutationFn = Apollo.MutationFunction<
  UpdateVenueMutation,
  UpdateVenueMutationVariables
>;

/**
 * __useUpdateVenueMutation__
 *
 * To run a mutation, you first call `useUpdateVenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVenueMutation, { data, loading, error }] = useUpdateVenueMutation({
 *   variables: {
 *      venue: // value for 'venue'
 *   },
 * });
 */
export function useUpdateVenueMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateVenueMutation, UpdateVenueMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateVenueMutation, UpdateVenueMutationVariables>(
    UpdateVenueDocument,
    options,
  );
}
export type UpdateVenueMutationHookResult = ReturnType<typeof useUpdateVenueMutation>;
export type UpdateVenueMutationResult = Apollo.MutationResult<UpdateVenueMutation>;
export type UpdateVenueMutationOptions = Apollo.BaseMutationOptions<
  UpdateVenueMutation,
  UpdateVenueMutationVariables
>;
export const DeleteVenueDocument = gql`
  mutation deleteVenue($id: String!, $tlo: String!) {
    deleteVenue(id: $id, tlo: $tlo)
  }
`;
export type DeleteVenueMutationFn = Apollo.MutationFunction<
  DeleteVenueMutation,
  DeleteVenueMutationVariables
>;

/**
 * __useDeleteVenueMutation__
 *
 * To run a mutation, you first call `useDeleteVenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVenueMutation, { data, loading, error }] = useDeleteVenueMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tlo: // value for 'tlo'
 *   },
 * });
 */
export function useDeleteVenueMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteVenueMutation, DeleteVenueMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteVenueMutation, DeleteVenueMutationVariables>(
    DeleteVenueDocument,
    options,
  );
}
export type DeleteVenueMutationHookResult = ReturnType<typeof useDeleteVenueMutation>;
export type DeleteVenueMutationResult = Apollo.MutationResult<DeleteVenueMutation>;
export type DeleteVenueMutationOptions = Apollo.BaseMutationOptions<
  DeleteVenueMutation,
  DeleteVenueMutationVariables
>;
export const ConfigurationDocument = gql`
  query configuration($countryCode: String!) {
    configuration(countryCode: $countryCode) {
      ...resourceConfiguration
      ...contactConfiguration
      ...locationConfiguration
    }
  }
  ${ResourceConfigurationFragmentDoc}
  ${ContactConfigurationFragmentDoc}
  ${LocationConfigurationFragmentDoc}
`;

/**
 * __useConfigurationQuery__
 *
 * To run a query within a React component, call `useConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigurationQuery({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useConfigurationQuery(
  baseOptions: Apollo.QueryHookOptions<ConfigurationQuery, ConfigurationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConfigurationQuery, ConfigurationQueryVariables>(
    ConfigurationDocument,
    options,
  );
}
export function useConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ConfigurationQuery, ConfigurationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ConfigurationQuery, ConfigurationQueryVariables>(
    ConfigurationDocument,
    options,
  );
}
export type ConfigurationQueryHookResult = ReturnType<typeof useConfigurationQuery>;
export type ConfigurationLazyQueryHookResult = ReturnType<typeof useConfigurationLazyQuery>;
export type ConfigurationQueryResult = Apollo.QueryResult<
  ConfigurationQuery,
  ConfigurationQueryVariables
>;
