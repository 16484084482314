import Spacer from "components/atoms/Spacer";

import colors from "config/style";
import styled from "styled-components";

const StyledHr = styled.hr`
  border-bottom: none;
  border-top: 1px solid ${colors.grey.c2};
`;

const Hr = ({
  size,
}: {
  size: "xxs" | "xs" | "sm" | "md" | "lg" | "responsive";
}): React.ReactElement => {
  return (
    <>
      <Spacer size={size} />
      <StyledHr />
      <Spacer size={size} />
    </>
  );
};

export default Hr;
