export const increaseNumberInEndOfString = (value: string): string => {
  if (value) {
    var endNumber = value.match(/[\d]{1,5}$/);
    if (endNumber) {
      return value.substr(0, endNumber.index) + (parseInt(endNumber[0]) + 1);
    }
  }
  return "";
};

export const convertToKebabCase = (string: string): string => {
  return string.replace(/\s+/g, "-").toLowerCase();
};
