import { ApolloCache, FetchResult, Reference } from "@apollo/client";

import {
  CreateResourceMutation,
  DeleteResourceMutation,
  FullResourceFragmentDoc,
  UpdateResourceMutation,
} from "generated/schemaTypes";

import { extendListInCache } from "helpers/service";

export const createResourceInCache = (
  cache: ApolloCache<CreateResourceMutation>,
  { data }: FetchResult<CreateResourceMutation>,
): void => {
  cache.modify({
    fields: {
      venue(venueRef, { readField }) {
        cache.modify({
          id: venueRef.__ref,
          fields: {
            resources(resources) {
              extendListInCache(
                resources,
                data?.createResource,
                FullResourceFragmentDoc,
                cache,
                readField,
              );
            },
          },
        });
        return venueRef;
      },
    },
  });
};

export const updateResourceInCache = (
  cache: ApolloCache<UpdateResourceMutation>,
  { data }: FetchResult<UpdateResourceMutation>,
): void => {
  cache.modify({
    fields: {
      resource() {
        cache.writeFragment({
          data: data?.updateResource,
          fragment: FullResourceFragmentDoc,
        });
      },
    },
  });
};

export const deleteResourceInCache = (
  cache: ApolloCache<DeleteResourceMutation>,
  { data }: FetchResult<DeleteResourceMutation>,
): void => {
  cache.modify({
    fields: {
      venue(venueRef) {
        cache.modify({
          id: venueRef.__ref,
          fields: {
            resources(resources, { readField }) {
              return resources.filter(
                (resource: Reference) => readField("id", resource) !== data?.deleteResource,
              );
            },
          },
        });
        // Keep Query.currentUser unchanged.
        return venueRef;
      },
    },
  });
};
