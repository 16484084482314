import React, { useEffect, useState } from "react";

import { useUpdateCustomerMutation, Customer } from "generated/schemaTypes";

import CustomerForm from "components/Customer/Modify/CustomerForm";
import Button from "components/atoms/antD/Button";
import message from "components/atoms/antD/Message";
import Modal from "components/atoms/antD/Modal";

import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "antd/lib/form/Form";
import { convertValuesToCustomerInput, CustomerFormValues } from "helpers/converters/customer";
import { isFormInitialized } from "helpers/form";
import { updateCustomerInCache } from "services/CustomerServices";

const UpdateCustomerButton = ({ customer }: { customer: Customer }): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const [form] = useForm<CustomerFormValues>();

  useEffect(() => {
    if (modalOpen === false && isFormInitialized(form)) {
      form.resetFields();
    }
  }, [modalOpen, form]);

  const [updateCustomer, { loading }] = useUpdateCustomerMutation({
    update: updateCustomerInCache,
    onCompleted: () => {
      setModalOpen(false);
      message.success("Customer updated");
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const onSubmit = (): void => {
    const values = convertValuesToCustomerInput(form.getFieldsValue(), customer.tlo, customer.id);
    updateCustomer({ variables: { customer: values } });
  };

  return (
    <>
      <Button
        type="link"
        title="Edit customer"
        onClick={(): void => {
          setModalOpen(true);
        }}>
        <FontAwesomeIcon icon={faEdit} />
      </Button>

      <Modal
        centered
        title="Edit Customer"
        visible={modalOpen}
        onCancel={(): void => {
          setModalOpen(false);
        }}
        footer={
          <>
            <Button
              onClick={(): void => {
                setModalOpen(false);
              }}
              type="default">
              Cancel
            </Button>
            <Button
              onClick={(): void => {
                onSubmit();
              }}
              loading={loading}
              type="primary">
              Save
            </Button>
          </>
        }>
        <CustomerForm form={form} customer={customer} />
      </Modal>
    </>
  );
};

export default UpdateCustomerButton;
