import React from "react";
import { useParams } from "react-router-dom";

import { Organization, useGetOrganizationQuery } from "generated/schemaTypes";

import CreateVenue from "components/Venue/Modify/CreateVenue";
import StatusHandler from "components/atoms/StatusHandler";

const CreateVenueView = (): JSX.Element => {
  const { organizationId, tloId } = useParams<{ organizationId: string; tloId: string }>();

  const getOrganizationQuery = useGetOrganizationQuery({
    variables: { id: organizationId, tlo: tloId },
  });

  return (
    <StatusHandler
      dataQueries={[
        { query: getOrganizationQuery, nonNullData: getOrganizationQuery.data?.organization },
      ]}>
      {([organization]): React.ReactElement => (
        <CreateVenue organization={organization as Organization} />
      )}
    </StatusHandler>
  );
};

export default CreateVenueView;
