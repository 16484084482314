import CreateOrganizationView from "components/Organization/Views/CreateOrganizationView";

import { BASE_ROUTE_PATH_ORGANIZATION } from "routes/paths";

const route = {
  path: (): string => BASE_ROUTE_PATH_ORGANIZATION + "/create",
  component: CreateOrganizationView,
};

export default route;
