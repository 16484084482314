import antDMessage, { ArgsProps, MessageType } from "antd/lib/message";

declare type JointContent = React.ReactNode | string | ArgsProps;

type MessageVariants = "info" | "error" | "success" | "warning" | "loading";

const defaultProps: JointContent = {
  duration: 4.5,
  style: {
    borderRadius: "4px",
    boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.1)",
  },
};

export const handleMessage = (type: MessageVariants, props: JointContent): MessageType => {
  if (antDMessage[type]) {
    if (typeof props === "object") {
      return antDMessage[type]({ ...defaultProps, ...props });
    }
    return antDMessage[type](defaultProps);
  }
  throw new Error("Invalid Message Type");
};

const messageTypes: MessageVariants[] = ["info", "error", "success", "warning", "loading"];

const message: { [key in MessageVariants]?: (props: JointContent) => MessageType } = {};

for (let i = 0; i < messageTypes.length; i++) {
  message[messageTypes[i]] = (props: JointContent): MessageType => {
    return handleMessage(messageTypes[i], props);
  };
}

export default message as { [key in MessageVariants]: (props: JointContent) => MessageType };
