import React, {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import { ConfigFieldsFragment, useCreateResourceMutation, Venue } from "generated/schemaTypes";

import ResourceForm from "components/Resource/Modify/ResourceForm";
import { FormInstance } from "components/atoms/antD/Form";

import { convertValuesToResourceInput, ResourceFormValues } from "helpers/converters/resource";
import { increaseNumberInEndOfString } from "helpers/string";
import routes from "routes/routes";
import { createResourceInCache } from "services/ResourceServices";
import styled from "styled-components";

export interface CreateResourceRef {
  addMore: () => void;
  onSubmit: () => void;
}

const FormContainer = styled.div`
  position: relative;
  max-height: 80vh;
  overflow: scroll;
`;

const CreatedResources = forwardRef(
  (
    {
      parent,
      resourceConfigs,
      setLoading,
      setModalOpen,
    }: {
      parent: Venue;
      resourceConfigs: ConfigFieldsFragment[];
      setLoading?: (value: boolean) => void;
      setModalOpen?: (value: boolean) => void;
    },
    ref: Ref<CreateResourceRef>,
  ): JSX.Element => {
    //Keep forms in state to be able to fetch values on AddMore and Submit
    const [forms, setForms] = useState<FormInstance<ResourceFormValues>[]>([]);
    const [numberOfForms, setNumberOfForms] = useState(1);

    const history = useHistory();

    const [createResource, { loading: createLoading, error }] = useCreateResourceMutation({
      update: createResourceInCache,
    });

    if (error) {
      throw error;
    }

    useEffect(() => {
      //Hack to let DOM render before fetching scrollHeight
      setTimeout(() => {
        document
          .getElementById("FormContainer")
          ?.scrollTo(0, document.getElementById("FormContainer")?.scrollHeight || 0);
      }, 10);
    });

    //Updates forms state variable and copy values from latest form to new form.
    const addForm = useCallback(
      (form: FormInstance<ResourceFormValues>): void => {
        if (forms.length > 0) {
          const values = { ...forms[forms.length - 1].getFieldsValue(true) };
          values.name = increaseNumberInEndOfString(values.name);
          form.setFieldsValue(values);
        }
        forms.push(form);

        setForms(forms);
      },
      [forms],
    );

    useImperativeHandle(ref, () => ({
      addMore,
      onSubmit,
    }));

    useEffect(() => {
      if (setLoading) {
        setLoading(createLoading);
      }
    }, [createLoading, setLoading]);

    const onSubmit = (): void => {
      Promise.all(
        forms.map((form) => {
          return form.validateFields();
        }),
      )
        .then((validations) => {
          Promise.all(
            forms.map((form) => {
              return createResource({
                variables: {
                  createResource: {
                    resource: convertValuesToResourceInput(form.getFieldsValue(true), parent.tlo),
                    parent: parent.id,
                  },
                },
              });
            }),
          ).then(() => {
            setLoading && setLoading(false);
            setModalOpen && setModalOpen(false);
            history.push(routes.resource.index.path({ tloId: parent.tlo, venueId: parent.id }));
          });
        })
        .catch((validationErrors) => {
          console.log(validationErrors);
        });
    };

    //Used to trigger re-render which will add another form to Array.map in return-block
    const addMore = (): void => {
      setNumberOfForms(numberOfForms + 1);
    };

    return (
      <FormContainer id="FormContainer">
        {Array(numberOfForms)
          .fill(0)
          .map((_, index) => {
            return (
              <ResourceForm
                // addForm is called in child on first render
                addForm={addForm}
                formName={`ResourceForm_${index}`}
                key={index}
                resourceConfigs={resourceConfigs}
              />
            );
          })}
      </FormContainer>
    );
  },
);

export default CreatedResources;
