import React from "react";

import { ResourceSkeletonFragment } from "generated/schemaTypes";

import scheduleRuleForms, {
  ScheduleRuleFormValues,
} from "components/ScheduleRule/Modify/ScheduleRuleFormItems";
import Wizard, { WizardInstance, WizardProps, WizardStep } from "components/Wizard";
import Padding from "components/atoms/Padding";
import Spacer from "components/atoms/Spacer";
import Form, { FormInstance } from "components/atoms/antD/Form";
import FormItem from "components/atoms/antD/FormItem";

import { Col, Row } from "antd/lib/grid";
import { spacing } from "config/layout";
import { WEEK_DAYS } from "constants/index";
import dayjs from "dayjs";
import styled from "styled-components";

const BottomContainer = styled.div`
  height: 100%;
  position: relative;
`;

const Bottom = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
`;

const ScheduleRuleFormWizard = ({
  wizard,
  resources,
  form,
  onFinish,
  numOfRules,
  ...otherProps
}: {
  wizard: WizardInstance;
  resources: ResourceSkeletonFragment[];
  form: FormInstance<ScheduleRuleFormValues>;
  onFinish: (values: ScheduleRuleFormValues) => void;
  numOfRules: number;
} & WizardProps): JSX.Element => {
  const ScheduleRuleFormItem = scheduleRuleForms(resources);

  const defaultOpeningTime = [
    dayjs().set("hour", 6).set("minute", 0),
    dayjs().set("hour", 22).set("minute", 0),
  ];

  return (
    <>
      <BottomContainer>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={{
            sortOrder: numOfRules,
            hours: {
              monday: defaultOpeningTime,
              tuesday: defaultOpeningTime,
              wednesday: defaultOpeningTime,
              thursday: defaultOpeningTime,
              friday: defaultOpeningTime,
              saturday: defaultOpeningTime,
              sunday: defaultOpeningTime,
            },
          }}>
          <FormItem
            noStyle
            shouldUpdate={(prevValues, curValues): boolean =>
              prevValues.typeOfRule !== curValues.typeOfRule
            }>
            {({ getFieldValue }): React.ReactNode => {
              return (
                <Wizard
                  wizard={wizard}
                  NavigationContainer={Bottom}
                  renderHiddenSteps={false}
                  {...otherProps}>
                  {(props: WizardInstance): React.ReactElement[] => {
                    const steps = [
                      <WizardStep label="1">
                        <Padding size="responsive">
                          <h3>Select type</h3>
                          <Spacer size="responsive" />
                          <ScheduleRuleFormItem item="typeOfRule" />
                          <ScheduleRuleFormItem item="sortOrder" props={{ disabled: true }} />
                        </Padding>
                      </WizardStep>,
                      <WizardStep label="2">
                        <Padding size="responsive">
                          <Row gutter={spacing.lg * 2}>
                            <Col span={12}>
                              <ScheduleRuleFormItem item="name" />
                              <ScheduleRuleFormItem item="description" />
                            </Col>
                            <Col span={12}>
                              <ScheduleRuleFormItem item="resources" />
                            </Col>
                          </Row>
                        </Padding>
                      </WizardStep>,
                      <WizardStep label="3">
                        <Padding size="responsive">
                          <Row gutter={spacing.lg * 2}>
                            <Col span={12}>
                              <h3>Date</h3>
                              <p>Set a start and end date for when the exception should apply:</p>
                              <Row gutter={spacing.lg}>
                                <Col span={12}>
                                  <ScheduleRuleFormItem item="startDate" />
                                </Col>
                                <Col span={12}>
                                  <ScheduleRuleFormItem item="endDate" />
                                </Col>
                              </Row>
                            </Col>
                            <Col span={12}>
                              <h3>Time</h3>
                              <p>Set a start and end time for when the court should be bookable</p>
                              {WEEK_DAYS.map((day) => (
                                <Row key={day}>
                                  <Col span={8}>{day}</Col>
                                  <Col span={16}>
                                    <ScheduleRuleFormItem
                                      data-cy={day}
                                      item={"hours"}
                                      props={{ day: day }}
                                    />
                                  </Col>
                                </Row>
                              ))}
                            </Col>
                          </Row>
                        </Padding>
                      </WizardStep>,
                    ];
                    if (getFieldValue("typeOfRule") === "bookable") {
                      steps.push(
                        <WizardStep label="4">
                          <Padding size="responsive">
                            <Row gutter={spacing.lg * 2}>
                              <Col span={12}>
                                <h3>Booking length</h3>
                                <Row gutter={spacing.lg}>
                                  <Col span={12}>
                                    <ScheduleRuleFormItem item="minDuration" />
                                  </Col>
                                  <Col span={12}>
                                    <ScheduleRuleFormItem item="maxDuration" />
                                  </Col>
                                </Row>
                                <h3>Increment</h3>
                                <p>
                                  Booking extension increment. <br />
                                  Enables the user to book a slot from the miminum booking length up
                                  to the maximum booking length in incremental steps.{" "}
                                </p>
                                <ScheduleRuleFormItem item="interval" />
                              </Col>
                              <Col span={12}></Col>
                            </Row>
                          </Padding>
                        </WizardStep>,
                      );
                    }
                    return steps;
                  }}
                </Wizard>
              );
            }}
          </FormItem>
        </Form>
      </BottomContainer>
    </>
  );
};

export default ScheduleRuleFormWizard;
