import { Booking, BookingInput } from "generated/schemaTypes";

import { bookingTypesList } from "components/Booking/BookingForm";

import dayjs, { Dayjs } from "dayjs";
import { getFirst } from "helpers/Array";

export interface BookingFormValues {
  //temporary until backend provide type by codegen
  bookingType: string;
  date: Dayjs;
  startTime: Dayjs;
  endTime: Dayjs;
  court: string;
  price: number;
  comment: string;
  createdAt?: string;
}

const applyDateOnTime = (time: Dayjs, date: Dayjs): Dayjs => {
  return time.set("year", date.year()).set("month", date.month()).set("date", date.date());
};

export const convertValuesToBookingInput = (
  values: BookingFormValues,
  tloId: string,
  bookingId?: string,
): BookingInput => {
  const date = values.date;

  const newStartTime = applyDateOnTime(values.startTime, date);
  const newEndTime = applyDateOnTime(values.endTime, date);

  const bookingInput: BookingInput = {
    tlo: tloId,
    id: bookingId ?? "",
    comment: values.comment,
    createdBy: "",
    channel: "web",
    startTime: newStartTime.toISOString(),
    endTime: newEndTime.toISOString(),
    resources: [values.court],
    type: values.bookingType,
    createdAt: values.createdAt ?? dayjs().toISOString(),
  };

  return bookingInput;
};

export const convertBookingToFormValues = (
  booking?: Partial<Booking>,
  resourceId?: string,
): BookingFormValues => {
  return {
    bookingType: booking?.type ?? Object.keys(bookingTypesList)[0],
    date: booking?.startTime ? dayjs(booking?.startTime) : dayjs(),
    startTime: booking?.startTime ? dayjs(booking?.startTime) : getRoundedDate(30, dayjs()),
    endTime: booking?.endTime
      ? dayjs(booking?.endTime)
      : getRoundedDate(30, dayjs().add(1, "hour")),
    court: getFirst(booking?.resources) ?? resourceId ?? "",
    price: 400,
    comment: booking?.comment ?? "",
    createdAt: booking?.createdAt,
  };
};

export const getRoundedDate = (roundToNearestMinutes: number, time: Dayjs): Dayjs => {
  let ms = 1000 * 60 * roundToNearestMinutes; // convert minutes to ms
  let roundedDate = dayjs(Math.round(time.valueOf() / ms) * ms);

  return roundedDate;
};
