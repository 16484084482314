import React from "react";

import ResponsiveContainer from "components/atoms/ResponsiveContainer";

import AntDLayout, { LayoutProps } from "antd/lib/layout";
import { breakpoints, spacing } from "config/layout";
import styled from "styled-components";

const { Header: AntDHeader, Footer: AntDFooter, Content: AntDContent } = AntDLayout;

const HeaderWrapper = (props: LayoutProps): JSX.Element => {
  return <AntDHeader {...props}>{props.children}</AntDHeader>;
};

const ContentWrapper = (props: LayoutProps): JSX.Element => {
  return (
    <ResponsiveContainer>
      <AntDContent {...props} />
    </ResponsiveContainer>
  );
};

const FooterWrapper = (props: LayoutProps): JSX.Element => {
  return (
    <AntDFooter {...props}>
      <ResponsiveContainer>{props.children}</ResponsiveContainer>
    </AntDFooter>
  );
};

const StyledHeader = styled(HeaderWrapper)`
  grid-area: header;
  display: flex;
  align-items: center;
  overflow: hidden;
  line-height: 1;
  height: auto;
  background: linear-gradient(90deg, #3a7bd5 0%, #00d2ff 100%);
  padding: 0 ${spacing.sm}px;
  @media screen and (min-width: ${breakpoints.sm}px) {
    padding: 0;
  }
`;

const StyledContent = styled(ContentWrapper)`
  margin-top: ${spacing.md}px;

  @media screen and (min-width: ${breakpoints.sm}px) {
    margin-top: ${spacing.lg}px;
  }
`;

const StyledFooter = styled(FooterWrapper)`
  width: 100%;
  background: linear-gradient(90deg, #3a7bd5 0%, #00d2ff 100%);
  height: 100px;
  padding: ${spacing.md}px;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const layout = { Header: StyledHeader, Footer: StyledFooter, Content: StyledContent };

export default layout;
