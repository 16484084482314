import { useState } from "react";
import { matchPath, useLocation } from "react-router-dom";

import { CollapseButton } from "components/MainNavigation/CollapseButton";
import { MenuItems } from "components/MainNavigation/Menu";
import { loggedInMenu, loggedOutMenu, MenuItem } from "components/MainNavigation/MenuItemObjects";
import OrganizationAndVenueProvider from "components/OrganizationAndVenueProvider";

import { faChevronDoubleLeft, faChevronDoubleRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Aside } from "App";
import { useIsLoggedIn, useLogout } from "hooks/auth";

export const isActiveByLocation = (item: MenuItem, location?: string): boolean => {
  if ("url" in item && location) {
    return Boolean(
      item.url === location ||
        item.routes.find((route) =>
          matchPath(location, {
            path: route,
            exact: true,
          }),
        ),
    );
  }
  return false;
};

const MainNavigation = (): React.ReactElement => {
  const location = useLocation();
  const isLoggedIn = useIsLoggedIn();
  const logout = useLogout();
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = (): void => {
    setCollapsed(!collapsed);
  };

  return (
    <Aside style={{ width: `${collapsed ? 50 : 200}px` }}>
      <CollapseButton onClick={toggleCollapsed} type={"text"} size="small">
        {collapsed ? (
          <FontAwesomeIcon icon={faChevronDoubleRight} />
        ) : (
          <FontAwesomeIcon icon={faChevronDoubleLeft} />
        )}
      </CollapseButton>

      {isLoggedIn ? (
        <OrganizationAndVenueProvider>
          {({ organization, venue }): React.ReactElement => (
            <MenuItems
              show={true}
              collapsed={collapsed}
              items={loggedInMenu(venue.id, venue.tlo, logout)}
              location={location.pathname}
            />
          )}
        </OrganizationAndVenueProvider>
      ) : (
        <MenuItems
          show={true}
          collapsed={collapsed}
          items={loggedOutMenu()}
          location={location.pathname}
        />
      )}
    </Aside>
  );
};

export default MainNavigation;
