import { useParams } from "react-router-dom";

import {
  Organization,
  useResourcesFromVenueQuery,
  useScheduleRulesFromVenueQuery,
  Venue,
} from "generated/schemaTypes";

import { withOrganizationAndVenue } from "components/OrganizationAndVenueProvider";
import Schedule from "components/Schedule/Schedule";
import { filterScheduleObjectOriginsByDate } from "components/Schedule/helpers";
import DateNavigator from "components/atoms/DateNavigator";
import Loading from "components/atoms/Loading";
import StatusHandler from "components/atoms/StatusHandler";

import dayjs from "dayjs";

const ScheduleRulesOverview = withOrganizationAndVenue(
  ({
    venue,
    organization,
    navigationPathForDate,
  }: {
    venue: Venue;
    organization: Organization;
    navigationPathForDate: (date: string) => string;
  }): JSX.Element => {
    const { date } = useParams<{ date?: string }>();
    const selectedDate = dayjs(date).isValid() ? dayjs(date) : dayjs();
    const scheduleRulesFromVenueQuery = useScheduleRulesFromVenueQuery({
      variables: { tlo: venue.tlo, id: venue.id },
    });

    const resourcesFromVenueQuery = useResourcesFromVenueQuery({
      variables: { tlo: venue.tlo, id: venue.id },
    });

    return (
      <StatusHandler
        dataQueries={[
          {
            query: scheduleRulesFromVenueQuery,
            nonNullData: scheduleRulesFromVenueQuery.data?.venue || { schedules: [] },
          },
          {
            query: resourcesFromVenueQuery,
            nonNullData: resourcesFromVenueQuery.data?.venue || { resources: [] },
          },
        ]}>
        {([venueWithSchedules, venueWithResources], loading): React.ReactElement => {
          const schedulesOnDate = filterScheduleObjectOriginsByDate(
            venueWithSchedules.schedules ?? [],
            selectedDate,
          );
          return (
            <>
              <DateNavigator date={selectedDate} navigationPathForDate={navigationPathForDate} />
              {loading && <Loading />}
              <Schedule
                data={{
                  scheduleObjectOrigins: schedulesOnDate,
                  resources: venueWithResources.resources ?? [],
                  day: selectedDate,
                  boundaries: undefined,
                }}
              />
            </>
          );
        }}
      </StatusHandler>
    );
  },
);

export default ScheduleRulesOverview;
