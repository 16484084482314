import { Resource, ResourceInput } from "generated/schemaTypes";

export interface ResourceFormValues {
  name?: string;
  type?: string;
  sortOrder?: number;
  attributes?: { [key: string]: { [key: string]: string } };
}

export const convertValuesToResourceInput = (
  values: ResourceFormValues,
  tloId: string,
  resourceId?: string,
): ResourceInput => {
  const resourceInput: ResourceInput = {
    tlo: tloId,
    id: resourceId,
    name: values.name ?? "",
    type: values.type ?? "",
    attributes: [],
    sortOrder: values.sortOrder ?? 0,
  };

  if (values.attributes && values.attributes[values.type ?? ""]) {
    Object.keys(values.attributes[values.type ?? ""]).forEach((key) => {
      if (values.attributes && resourceInput) {
        resourceInput.attributes?.push({
          type: key,
          value: values.attributes[values.type ?? ""][key],
        });
      }
    });
  }

  return resourceInput;
};

export const convertResourceToValues = (resource: Resource | undefined): ResourceFormValues => {
  const newAttributes: { [key: string]: { [key: string]: string } } = {};
  if (resource) {
    newAttributes[resource.type] = {};
    resource?.attributes?.forEach((attribute): void => {
      newAttributes[resource.type][attribute.type] = attribute.value;
    });
  }
  return {
    name: resource?.name ?? "",
    type: resource?.type ?? "",
    sortOrder: resource?.sortOrder ?? 0,
    attributes: newAttributes,
  };
};
