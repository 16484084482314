import React, { useState } from "react";

import { useCreateScheduleRuleMutation, useResourcesFromVenueQuery } from "generated/schemaTypes";

import { ScheduleRuleFormValues } from "components/ScheduleRule/Modify/ScheduleRuleFormItems";
import ScheduleRuleFormWizard from "components/ScheduleRule/Modify/ScheduleRuleFormWizard";
import { useWizard } from "components/Wizard";
import Padding from "components/atoms/Padding";
import StatusHandler from "components/atoms/StatusHandler";
import Button from "components/atoms/antD/Button";
import Form from "components/atoms/antD/Form";
import Modal from "components/atoms/antD/Modal";

import { FormInstance } from "antd/lib/form";
import { WEEK_DAYS } from "constants/index";
import {
  convertScheduleRuleToValues,
  convertValuesToScheduleRuleInput,
} from "helpers/converters/scheduleRule";
import { createScheduleRuleInCache } from "services/ScheduleRuleServices";

export interface ScheduleRuleWizard {
  formFields: string[];
}

const fields = [
  ["typeOfRule"],
  ["name", "description", "resources"],
  ["startDate", "endDate", ...WEEK_DAYS.map((day) => ["hours", day])],
  ["minDuration", "maxDuration", "interval"],
];

const validateFormStep = (form: FormInstance, step: number): Promise<boolean> => {
  if (fields[step]) {
    console.log(form);

    return form
      .validateFields(fields[step])
      .then((value) => {
        return true;
      })
      .catch((error) => {
        return false;
      });
  }
  return new Promise((resolve) => {
    resolve(true);
  });
};

const CreateScheduleRule = ({
  modalOpen,
  setModalOpen,
  venueId,
  tloId,
  numOfRules = 0,
}: {
  setModalOpen: (value: boolean) => void;
  modalOpen: boolean;
  venueId: string;
  tloId: string;
  numOfRules?: number;
}): React.ReactElement => {
  const wizard = useWizard();
  const [form] = Form.useForm<ScheduleRuleFormValues>();
  const [formValues, setFormValues] = useState<ScheduleRuleFormValues>(
    convertScheduleRuleToValues(),
  );

  const [createSchedule] = useCreateScheduleRuleMutation({
    update: createScheduleRuleInCache,
  });

  const resourcesFromVenueQuery = useResourcesFromVenueQuery({
    variables: {
      id: venueId,
      tlo: tloId,
    },
  });

  const updateFormValues = (values: ScheduleRuleFormValues): ScheduleRuleFormValues => {
    const newValues = Object.assign({}, formValues, values);
    setFormValues(newValues);
    return newValues;
  };

  const onFinish = async (values: ScheduleRuleFormValues): Promise<void> => {
    await createSchedule({
      variables: {
        createScheduleRule: {
          parent: venueId,
          schedule: convertValuesToScheduleRuleInput(values, tloId),
        },
      },
      refetchQueries: ["scheduleRulesFromVenue"],
    });
    setModalOpen(false);
  };

  return (
    <>
      <StatusHandler
        dataQueries={[
          {
            query: resourcesFromVenueQuery,
            nonNullData: resourcesFromVenueQuery?.data?.venue,
          },
        ]}>
        {([venueWithResources]): React.ReactElement => (
          <Modal
            centered
            title={<Padding size="responsive">Add rule</Padding>}
            visible={modalOpen}
            onCancel={(): void => {
              setModalOpen(false);
            }}
            width={"80vw"}
            style={{ width: "50vw", maxWidth: "900px" }}
            bodyStyle={{ height: "80vh", maxHeight: "600px" }}
            footer={
              <>
                <Button
                  disabled={wizard.wizardPosition === 0}
                  onClick={(): void => {
                    updateFormValues(form.getFieldsValue());
                    wizard.stepToPrevious();
                  }}>
                  Previous
                </Button>
                {wizard.wizardPosition === wizard.numOfSteps - 1 ? (
                  <Button
                    type="primary"
                    onClick={(): void => {
                      validateFormStep(form, wizard.wizardPosition).then((validated) => {
                        if (validated) {
                          const formValues = updateFormValues(form.getFieldsValue());
                          onFinish(formValues);
                        }
                      });
                    }}>
                    Finish
                  </Button>
                ) : (
                  <Button
                    onClick={(): void => {
                      validateFormStep(form, wizard.wizardPosition).then((validated) => {
                        console.log(validated);

                        if (validated) {
                          updateFormValues(form.getFieldsValue());
                          wizard.stepToNext();
                        }
                      });
                    }}>
                    Next
                  </Button>
                )}
              </>
            }>
            <ScheduleRuleFormWizard
              form={form}
              resources={venueWithResources.resources ?? []}
              wizard={wizard}
              showNavigationButtons={false}
              onFinish={onFinish}
              numOfRules={numOfRules}
            />
          </Modal>
        )}
      </StatusHandler>
    </>
  );
};

export default CreateScheduleRule;
