import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { isActiveByLocation } from "components/MainNavigation/MainNavigation";
import { MenuItem } from "components/MainNavigation/MenuItemObjects";
import Button from "components/atoms/antD/Button";

import { Tooltip } from "antd";
import colors from "config/style";
import styled, { css } from "styled-components";

const IconContainer = styled.div`
  display: flex;
  width: 30px;
  justify-content: center;
  flex-grow: 0;
`;

const StyledMenuItem = styled.div<{ isActive?: boolean; collapsed: boolean }>`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  & > a,
  && > button {
    display: block;
    height: 40px;
    color: ${colors.grey.c6};
    border-radius: 3px;
    flex-grow: 1;
    transition: color 0.1s linear, background-color 0.1s linear;
    padding: 0;

    & span {
      transition: opacity 0.1s linear;
    }

    & svg {
      transition: color 0.1s linear, background-color 0.1s linear;
    }
    &:hover {
      background: ${colors.grey.c2};
      color: #000;
    }

    ${({ isActive }): any =>
      isActive &&
      css`
        &,
        &:hover {
          color: ${colors.green.c5};
          background: ${colors.green.c1};
        }
      `}

    ${({ collapsed }): any =>
      collapsed &&
      css`
        padding: 0 10px;
        span {
          opacity: 0;
          display: none;
        }
      `}
  }
`;

const StyledMenuItemInner = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-items: center;
`;

export const MenuItems = ({
  items,
  show,
  location,
  collapsed,
}: {
  items: MenuItem[];
  show: boolean;
  collapsed: boolean;
  location: string;
}): React.ReactElement => {
  return (
    <Ul style={{ display: show ? "block" : "none" }}>
      {items.map((item) => {
        return (
          <MenuListItem item={item} collapsed={collapsed} key={item.title} location={location} />
        );
      })}
    </Ul>
  );
};

const MenuItemInner = ({
  item,
  collapsed,
}: {
  item: MenuItem;
  collapsed: boolean;
}): React.ReactElement => {
  return collapsed ? (
    <Tooltip title={item.title} placement="right">
      <StyledMenuItemInner>
        {item.icon ? <IconContainer>{item.icon}</IconContainer> : <></>}
      </StyledMenuItemInner>
    </Tooltip>
  ) : (
    <StyledMenuItemInner>
      {item.icon ? <IconContainer>{item.icon}</IconContainer> : <></>}
      <span>{item.title}</span>
    </StyledMenuItemInner>
  );
};

const MenuListItem = ({
  item,
  location,
  collapsed,
}: {
  item: MenuItem;
  location: string;
  collapsed: boolean;
}): React.ReactElement => {
  const isActive = isActiveByLocation(item, location);
  const [showChildren, setShowChildren] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (isActive && showChildren === false) {
      setShowChildren(undefined);
    }
  }, [isActive]);

  // const showSubMenus: boolean = Boolean(isActive || showChildren) && showChildren !== false;

  return (
    <Li collapsed={collapsed}>
      <StyledMenuItem isActive={isActiveByLocation(item, location)} collapsed={collapsed}>
        {"url" in item ? (
          <Link to={item.url}>
            <MenuItemInner collapsed={collapsed} item={item} />
          </Link>
        ) : "onClick" in item ? (
          <Button type="link" onClick={item.onClick}>
            <MenuItemInner collapsed={collapsed} item={item} />
          </Button>
        ) : (
          <></>
        )}
        {/* {Boolean(item.subMenuItems) ? (
          <Chevron
            rotate={showSubMenus}
            onClick={(): void => {
              setShowChildren(!showSubMenus);
            }}
          />
        ) : (
          <></>
        )} */}
      </StyledMenuItem>
      {/* {item.subMenuItems ? (
        <MenuItems
          collapsed={collapsed}
          items={item.subMenuItems}
          show={showSubMenus}
          location={location}
        />
      ) : (
        <></>
      )} */}
    </Li>
  );
};

export const Ul = styled.ul``;

const Li = styled.li<{ collapsed: boolean }>`
  padding: 0 10px;

  & & {
    padding: 0 0 0 10px;
  }

  ${({ collapsed }): any =>
    collapsed &&
    css`
      padding: 0;
    `}
`;
