import React from "react";

import AntDForm, { FormProps, FormInstance } from "antd/lib/form";
import hoistNonReactStatics from "hoist-non-react-statics";

const FormWrapper = (props: FormProps): JSX.Element => {
  return <AntDForm {...props} />;
};

export type { FormInstance };

export default hoistNonReactStatics(FormWrapper, AntDForm);
