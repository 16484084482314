import { useHistory } from "react-router-dom";

import DateSelector from "components/atoms/DateSelector";

import { Dayjs } from "dayjs";
import { formatDateAsRegular } from "helpers/date";

type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;

export const dateIntoPathMethod = <T extends { (params: { [key: string]: any }): string }>(
  pathFunction: T,
  attrs: Omit<ArgumentTypes<typeof pathFunction>[0], "date">,
): ((date: string) => string) => {
  return (date: string): string => {
    return pathFunction({ ...attrs, date });
  };
};

const DateNavigator = ({
  date,
  navigationPathForDate,
}: {
  date: Dayjs;
  navigationPathForDate: (date: string) => string;
}): React.ReactElement => {
  const history = useHistory();

  const handleChange = (value: Dayjs | null): void => {
    history.push(navigationPathForDate(formatDateAsRegular(value)));
  };

  return <DateSelector date={date} handleChange={handleChange} />;
};

export default DateNavigator;
