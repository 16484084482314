import { ApolloCache, FetchResult, InMemoryCache, Reference } from "@apollo/client";

import {
  CreateCustomerMutation,
  FullCustomerFragmentDoc,
  UpdateCustomerMutation,
} from "generated/schemaTypes";

import { extendListInCache } from "helpers/service";

export const createCustomerInCache = (
  cache: ApolloCache<CreateCustomerMutation>,
  { data }: FetchResult<CreateCustomerMutation>,
): void => {
  cache.modify({
    fields: {
      venue(venueRef, { readField }) {
        cache.modify({
          id: venueRef.__ref,
          fields: {
            customers(customers) {
              extendListInCache(
                customers,
                data?.createCustomer,
                FullCustomerFragmentDoc,
                cache,
                readField,
              );
            },
          },
        });
        return venueRef;
      },
    },
  });
};

export const updateCustomerInCache = (
  cache: ApolloCache<UpdateCustomerMutation>,
  { data }: FetchResult<UpdateCustomerMutation>,
): void => {
  cache.modify({
    fields: {
      customer() {
        cache.writeFragment({
          data: data?.updateCustomer,
          fragment: FullCustomerFragmentDoc,
        });
      },
    },
  });
};

export const deleteCustomerInCache = (cache: InMemoryCache, deletedCustomerIds: string[]): void => {
  cache.modify({
    fields: {
      venue(venueRef) {
        cache.modify({
          id: venueRef.__ref,
          fields: {
            customers(customers, { readField }) {
              return customers.filter(
                (customer: Reference) =>
                  !deletedCustomerIds.includes(readField("id", customer)?.toString() ?? ""),
              );
            },
          },
        });
        return venueRef;
      },
    },
  });
};
