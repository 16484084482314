import React, { useRef, useState } from "react";

import { useConfigurationQuery, Venue } from "generated/schemaTypes";

import CreateResources from "components/Resource/Modify/CreateResources";
import StatusHandler from "components/atoms/StatusHandler";
import Button from "components/atoms/antD/Button";
import Modal from "components/atoms/antD/Modal";

export interface CreateResourceRef {
  addMore: () => void;
  onSubmit: () => void;
}

const CreateResourceButton = ({ parent }: { parent: Venue }): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const createResourceRef = useRef<CreateResourceRef>(null);

  const configurationQuery = useConfigurationQuery({
    variables: { countryCode: "SE" },
  });

  return (
    <>
      <Button
        type="primary"
        title="Add resource(s)"
        onClick={(): void => {
          setModalOpen(true);
        }}>
        Add
      </Button>
      <Modal
        centered
        title="Create Resource"
        visible={modalOpen}
        onCancel={(): void => {
          setModalOpen(false);
        }}
        footer={
          <>
            <Button
              type="default"
              title="Save and add another resource"
              onClick={(): void => {
                createResourceRef.current?.addMore();
              }}>
              Add another resource
            </Button>
            <Button
              type="primary"
              title="Save"
              onClick={(): void => {
                createResourceRef.current?.onSubmit();
              }}
              loading={isLoading}>
              Save
            </Button>
          </>
        }>
        <StatusHandler
          dataQueries={[
            { query: configurationQuery, nonNullData: configurationQuery.data?.configuration },
          ]}>
          {([configuration]): React.ReactElement => (
            <CreateResources
              ref={createResourceRef}
              resourceConfigs={configuration.resourceConfiguration ?? []}
              parent={parent}
              setModalOpen={setModalOpen}
              setLoading={setLoading}
            />
          )}
        </StatusHandler>
      </Modal>
    </>
  );
};

export default CreateResourceButton;
