import React from "react";

import AntDTabs, { TabPaneProps, TabsProps } from "antd/lib/tabs";
import { spacing } from "config/layout";
import hoistNonReactStatics from "hoist-non-react-statics";
import styled from "styled-components";

const TabsWrapper = (props: TabsProps): JSX.Element => {
  return <AntDTabs {...props} />;
};

const TabsPaneWrapper = (props: TabPaneProps): JSX.Element => {
  return <AntDTabs.TabPane {...props} />;
};

const Tabs = styled(TabsWrapper)`
  height: 100%;
  margin-top: ${spacing.sm}px;

  & > .ant-tabs-nav {
    margin-bottom: 0;
  }

  & .ant-tabs-content {
    height: 100%;
  }
`;

export const TabsPane = styled(TabsPaneWrapper)``;

export default hoistNonReactStatics(Tabs, AntDTabs);
