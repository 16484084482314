const colors = {
  green: {
    c1: "rgba(34, 173, 92, 0.1)",
    c2: "rgba(34, 173, 92, 0.2)",
    c3: "rgba(34, 173, 92, 0.5)",
    c4: "#28C76A",
    c5: "#22AD5C",
    c6: "#1E944F",
  },
  salmon: {
    c4: "#FF9187",
    c5: "#FF796E",
    c6: "#CC6258",
  },
  blue: {
    light: "#f0f",
    medium: "#1C91DD",
    bright: "#f0f",
    dark: "#f0f",
  },
  orange: {
    light: "#f0f",
    medium: "#FF9538",
    dark: "#f0f",
  },
  white: "#FFF",
  whiteO: {
    o1: "rgba(255, 255, 255, 0.1)",
    o2: "rgba(255, 255, 255, 0.2)",
    o3: "rgba(255, 255, 255, 0.3)",
    o4: "rgba(255, 255, 255, 0.4)",
    o5: "rgba(255, 255, 255, 0.5)",
    o6: "rgba(255, 255, 255, 0.6)",
    o7: "rgba(255, 255, 255, 0.7)",
    o8: "rgba(255, 255, 255, 0.8)",
    o9: "rgba(255, 255, 255, 0.9)",
  },
  grey: {
    c1: "#fafafa",
    c2: "#f5f5f5",
    c3: "#f0f0f0",
    c4: "#d9d9d9",
    c5: "#bfbfbf",
    c6: "#8c8c8c",
    c7: "#595959",
    c8: "#434343",
    c9: "#262626",
    c10: "#1f1f1f",
  },
  black: "#000",
};

module.exports = colors;
