import React from "react";

import { ResourceSkeletonFragment } from "generated/schemaTypes";

import SimpleFormItemFactory, {
  SimpleFormItemProps,
  SimpleFormProps,
} from "components/SimpleFormItemFactory";
import DatePicker from "components/atoms/antD/DatePicker";
import Input, { TextArea } from "components/atoms/antD/Input";
import Radio from "components/atoms/antD/Radio";

import Checkbox from "antd/lib/checkbox";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import { Dayjs } from "dayjs";

export interface ScheduleRuleFormValues {
  typeOfRule?: string;
  sortOrder: number;
  name?: string;
  description?: string;
  resources?: string[];
  startDate?: Dayjs;
  endDate?: Dayjs;
  hours?: {
    monday: [Dayjs, Dayjs];
    tuesday: [Dayjs, Dayjs];
    wednesday: [Dayjs, Dayjs];
    thursday: [Dayjs, Dayjs];
    friday: [Dayjs, Dayjs];
    saturday: [Dayjs, Dayjs];
    sunday: [Dayjs, Dayjs];
  };
  minDuration?: number;
  maxDuration?: number;
  interval?: number;
}

const staticFormItems: SimpleFormProps = {
  typeOfRule: {
    label: "",
    rules: [{ required: true }],
    children: (
      <Radio.Group>
        <Radio value="bookable" style={{ width: "100%" }}>
          <Row>
            <Col span={8}>Bookable slots</Col>
            <Col span={16}>
              <p>
                Create a schedule with either flexible slot between a given interval (common for
                padel) or fixed slots (common for tennis).
              </p>
            </Col>
          </Row>
        </Radio>
        <Radio value="close" style={{ width: "100%" }}>
          <Row>
            <Col span={8}>Close</Col>
            <Col span={16}>
              <p>Create a rule that makes a period of time unbookable.</p>
            </Col>
          </Row>
        </Radio>
      </Radio.Group>
    ),
  },
  sortOrder: (props) => {
    const { disabled } = props ?? {};
    return {
      label: "Sort order",
      rules: [{ required: true }],
      children: <Input type="number" disabled={disabled} />,
    };
  },
  name: {
    label: "Name",
    rules: [{ required: true }],
  },
  description: {
    label: "Description",
    rules: [{ required: true }],
    children: <TextArea />,
  },
  startDate: {
    label: "Date start",
    rules: [{ required: true }],
    children: <DatePicker />,
  },
  endDate: {
    label: "Date end",
    rules: [{ required: true }],
    children: <DatePicker />,
  },
  hours: (props) => {
    const { day } = props ?? {};
    return {
      name: ["hours", day],
      rules: [{ required: true }],
      children: <DatePicker.RangePicker format="HH:mm" picker="time" order={true} />,
    };
  },
  minDuration: {
    label: "Minimum",
    rules: [{ required: true }],
    children: <Input addonAfter="minutes" />,
  },
  maxDuration: {
    label: "Maximum",
    rules: [{ required: true }],
    children: <Input addonAfter="minutes" />,
  },
  interval: {
    label: "",
    rules: [{ required: true }],
    children: <Input addonAfter="minutes" />,
  },
};

const scheduleRuleForms = (
  resources?: ResourceSkeletonFragment[],
): (({ item, props }: SimpleFormItemProps) => JSX.Element) => {
  const dynamicFormItems: SimpleFormProps = {
    resources: {
      label: "Resources",
      rules: [{ required: true }],
      children: (
        <Checkbox.Group>
          {resources?.map((resource, index) => {
            return (
              <p key={index}>
                <Checkbox value={resource.id}>{resource.name}</Checkbox>
              </p>
            );
          })}
        </Checkbox.Group>
      ),
    },
  };

  const formItem = SimpleFormItemFactory(Object.assign(staticFormItems, dynamicFormItems));

  return formItem;
};

export default scheduleRuleForms;
