import { ApolloCache, FetchResult, Reference } from "@apollo/client";

import {
  CreateVenueMutation,
  DeleteVenueMutation,
  FullVenueFragmentDoc,
  UpdateVenueMutation,
} from "generated/schemaTypes";

import gql from "graphql-tag";

export const updateListVenuesByOrganizationInCache = (
  cache: ApolloCache<CreateVenueMutation>,
  { data }: FetchResult<CreateVenueMutation>,
): void => {
  cache.modify({
    fields: {
      organization(organizations) {
        organizations = organizations ? organizations : {};
        cache.writeFragment({
          data: data?.createVenue,
          fragment: gql`
            fragment NewVenue on Venue {
              id
              name
              tlo
            }
          `,
        });
      },
    },
  });
};

export const updateVenueInCache = (
  cache: ApolloCache<UpdateVenueMutation>,
  { data }: FetchResult<UpdateVenueMutation>,
): void => {
  cache.modify({
    fields: {
      venue() {
        cache.writeFragment({
          data: data?.updateVenue,
          fragment: FullVenueFragmentDoc,
          fragmentName: "FullVenue",
        });
      },
    },
  });
};

export const deleteVenueInCache = (
  cache: ApolloCache<DeleteVenueMutation>,
  { data }: FetchResult<DeleteVenueMutation>,
): void => {
  cache.modify({
    fields: {
      listVenuesByOrganization(existingVenues, { readField }) {
        return existingVenues.filter(
          (venueRef: Reference) => data?.deleteVenue !== readField("id", venueRef),
        );
      },
    },
  });
};
