import { AUTH_TYPE } from "aws-appsync-auth-link";

declare global {
  interface Window {
    config: any;
  }
}

const envConfigs = window.config;

export const REACT_APP_GRAPHQL_URL = envConfigs.REACT_APP_GRAPHQL_URL;
const _AMAZON_COGNITO_AUTH_TYPE = envConfigs.AMAZON_COGNITO_AUTH_TYPE;
export const AMAZON_COGNITO_AUTH_TYPE = isAmazonCognitoAuthType(_AMAZON_COGNITO_AUTH_TYPE)
  ? _AMAZON_COGNITO_AUTH_TYPE
  : AUTH_TYPE.AMAZON_COGNITO_USER_POOLS;
export const REACT_APP_AWS_REGION = envConfigs.REACT_APP_AWS_REGION;
export const REACT_APP_AWS_USER_POOL_ID = envConfigs.REACT_APP_AWS_USER_POOL_ID;
export const REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID =
  envConfigs.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID;
export const REACT_APP_AWS_OATH_DOMAIN = envConfigs.REACT_APP_AWS_OATH_DOMAIN;

export const REACT_APP_AWS_OATH_SCOPE = envConfigs.REACT_APP_AWS_OATH_SCOPE;

// typechecks

export type AuthOptionsOAuth = AUTH_TYPE.OPENID_CONNECT | AUTH_TYPE.AMAZON_COGNITO_USER_POOLS;

function isAmazonCognitoAuthType(arg: any): arg is AuthOptionsOAuth {
  return Object.values([AUTH_TYPE.OPENID_CONNECT, AUTH_TYPE.AMAZON_COGNITO_USER_POOLS]).includes(
    arg,
  );
}
