import styled from "styled-components";

const StyledH1 = styled.h1``;

const PageHeadline = ({ title }: { title: string }): React.ReactElement => {
  return (
    <>
      <StyledH1>{title}</StyledH1>
    </>
  );
};
export default PageHeadline;
