import React from "react";

import AntDResult, { ResultProps } from "antd/lib/result";
import hoistNonReactStatics from "hoist-non-react-statics";

const ResultWrapper = (props: ResultProps): JSX.Element => {
  return <AntDResult {...props} />;
};

export default hoistNonReactStatics(ResultWrapper, AntDResult);
