import React from "react";

import { OrganizationSkeletonFragment } from "generated/schemaTypes";

import ListTable from "components/atoms/ListTable";

import routes from "routes/routes";

const transform = (
  organization: OrganizationSkeletonFragment,
): OrganizationSkeletonFragment & { organizations: OrganizationSkeletonFragment[] } => {
  return organization as OrganizationSkeletonFragment & {
    organizations: OrganizationSkeletonFragment[];
  };
};

const ListOrganizations = ({
  organizations,
}: {
  organizations?: OrganizationSkeletonFragment[];
}): JSX.Element => {
  if (!organizations) {
    return <></>;
  }

  // Flat organizations to also include organizations[x].organizations
  const flatOrganizations = organizations.flatMap((organization, num) => {
    const arr = [...transform(organization).organizations];
    arr.push(organization);
    return arr;
  });

  return (
    <ListTable<OrganizationSkeletonFragment>
      easyColumns={{ name: "Name", tlo: "TLO" }}
      dataSource={flatOrganizations}
      showUrl={(_, organization): string => {
        return routes.organization.show.path({
          tloId: organization.tlo,
          organizationId: organization.id,
        });
      }}
      // editUrl={(_, organization): string => {
      //   return routes.organization.edit.path({
      //     tloId: organization.tlo,
      //     organizationId: organization.id,
      //   });
      // }}
    />
  );
};

export default ListOrganizations;
