import React from "react";

const StopPropagation = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  return (
    <div
      onClick={(e): void => {
        e.stopPropagation();
      }}>
      {children}
    </div>
  );
};

export default StopPropagation;
