import React from "react";
import { useParams } from "react-router-dom";

import { useGetResourceQuery } from "generated/schemaTypes";

import ShowResource from "components/Resource/Get/ShowResource";
import BgContainer from "components/atoms/BgContainer";
import StatusHandler from "components/atoms/StatusHandler";

const ShowResourceView = (): JSX.Element => {
  const { resourceId, tloId } = useParams<{ resourceId: string; tloId: string }>();

  const resourceQuery = useGetResourceQuery({
    variables: { id: resourceId, tlo: tloId },
  });

  return (
    <BgContainer>
      <StatusHandler
        dataQueries={[{ query: resourceQuery, nonNullData: resourceQuery?.data?.resource }]}>
        {([resource]): React.ReactElement => <ShowResource resource={resource} />}
      </StatusHandler>
    </BgContainer>
  );
};

export default ShowResourceView;
