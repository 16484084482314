import React, { useEffect, useState } from "react";

import { useCreateCustomerMutation, Venue } from "generated/schemaTypes";

import CustomerForm from "components/Customer/Modify/CustomerForm";
import Button from "components/atoms/antD/Button";
import message from "components/atoms/antD/Message";
import Modal from "components/atoms/antD/Modal";

import { useForm } from "antd/lib/form/Form";
import { convertValuesToCustomerInput, CustomerFormValues } from "helpers/converters/customer";
import { isFormInitialized } from "helpers/form";
import { createCustomerInCache } from "services/CustomerServices";

const CreateCustomerButton = ({ parent }: { parent: Venue }): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const [form] = useForm<CustomerFormValues>();

  useEffect(() => {
    if (modalOpen === false && isFormInitialized(form)) {
      form.resetFields();
    }
  }, [modalOpen, form]);

  const [createCustomer, { loading }] = useCreateCustomerMutation({
    update: createCustomerInCache,
    onCompleted: () => {
      setModalOpen(false);
      message.success("Customer added");
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const onSubmit = (): void => {
    const values = convertValuesToCustomerInput(form.getFieldsValue(), parent.tlo);
    createCustomer({ variables: { createCustomer: { parent: parent.id, customer: values } } });

    return;
  };

  return (
    <>
      <Button
        type="primary"
        title="Add customer"
        onClick={(): void => {
          setModalOpen(true);
        }}>
        Add customer
      </Button>

      <Modal
        centered
        title="Create Customer"
        visible={modalOpen}
        onCancel={(): void => {
          setModalOpen(false);
        }}
        footer={
          <>
            <Button
              onClick={(): void => {
                setModalOpen(false);
              }}
              type="default">
              Cancel
            </Button>
            <Button
              onClick={(): void => {
                onSubmit();
              }}
              loading={loading}
              type="primary">
              Save
            </Button>
          </>
        }>
        <CustomerForm form={form} />
      </Modal>
    </>
  );
};

export default CreateCustomerButton;
