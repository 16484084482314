import React from "react";
import { useState } from "react";

import { Modal } from "components/atoms/antD";
import Button, { ButtonColor } from "components/atoms/antD/Button";

const ConfirmModal = ({
  children,
  onConfirm,
  cancelText = "Cancel",
  title,
  message,
  okText = "Ok",
  okColor = "green",
  okLoading = false,
}: {
  children: React.ReactChild;
  onConfirm: () => void | Promise<boolean>;
  title: string;
  message: React.ReactNode;
  cancelText?: string;
  okText?: string;
  okColor?: ButtonColor;
  okLoading?: boolean;
}): React.ReactElement => {
  const [modalOpen, setModalOpen] = useState(false);

  const childrenWithCustomizedOnClick = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        onClick: () => {
          if (child.props.onClick) {
            child.props.onClick();
          }
          setModalOpen(true);
        },
      });
    }
    return child;
  });

  return (
    <>
      {childrenWithCustomizedOnClick}
      <Modal
        centered
        title={title}
        visible={modalOpen}
        onCancel={(): void => {
          setModalOpen(false);
        }}
        footer={
          <>
            <Button
              onClick={(): void => {
                setModalOpen(false);
              }}
              type="default">
              {cancelText}
            </Button>
            <Button
              onClick={(): void => {
                const confirmed = onConfirm();
                if (typeof confirmed === "undefined") {
                  setModalOpen(false);
                } else {
                  confirmed.then((done: boolean) => {
                    if (done) {
                      setModalOpen(false);
                    }
                  });
                }
              }}
              loading={okLoading}
              type="primary"
              color={okColor}>
              {okText}
            </Button>
          </>
        }>
        <>{message}</>
      </Modal>
    </>
  );
};

export default ConfirmModal;
