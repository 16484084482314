import { ApolloCache, FetchResult, Reference } from "@apollo/client";

import {
  CreateBookingMutation,
  DeleteBookingMutation,
  FullBookingFragmentDoc,
  UpdateBookingMutation,
} from "generated/schemaTypes";

import { extendListInCache } from "helpers/service";

export const createBookingInCache = (
  cache: ApolloCache<CreateBookingMutation>,
  { data }: FetchResult<CreateBookingMutation>,
): void => {
  cache.modify({
    fields: {
      venue(venueRef, { readField }) {
        cache.modify({
          id: venueRef.__ref,
          fields: {
            bookings(bookings) {
              extendListInCache(
                bookings,
                data?.createBooking,
                FullBookingFragmentDoc,
                cache,
                readField,
              );
            },
          },
        });
        return venueRef;
      },
    },
  });
};

export const updateBookingInCache = (
  cache: ApolloCache<UpdateBookingMutation>,
  { data }: FetchResult<UpdateBookingMutation>,
): void => {
  cache.modify({
    fields: {
      booking() {
        cache.writeFragment({
          data: data?.updateBooking,
          fragment: FullBookingFragmentDoc,
        });
      },
    },
  });
};

export const deleteBookingInCache = (
  cache: ApolloCache<DeleteBookingMutation>,
  { data }: FetchResult<DeleteBookingMutation>,
): void => {
  console.log(data);

  cache.modify({
    fields: {
      venue(venueRef) {
        cache.modify({
          id: venueRef.__ref,
          fields: {
            bookings(bookings, { readField }) {
              console.log(bookings);

              const a = bookings.filter(
                (booking: Reference) => readField("id", booking) !== data?.deleteBooking,
              );
              console.log(a);
              return a;
            },
          },
        });
        return venueRef;
      },
    },
  });
};
