import React from "react";

import { Card } from "antd";
import styled from "styled-components";

interface CardProps {
  $colorFrom: string;
  $colorTo: string;
}

const StyledCard = styled(Card)<CardProps>`
  border-radius: 6px;
  background-image: ${({ $colorFrom, $colorTo }): string =>
    `linear-gradient( 222.58deg, ${$colorFrom} -28.51%, ${$colorTo}  99.71% )`};
  & div {
    font-size: 16px;
    color: white;
    & a {
      margin-left: 5px;
      color: white;
    }
  }
`;

const NavigationCard = ({
  children,
  title,
  colorFrom,
  colorTo = "#FFFFFF",
}: {
  children: React.ReactNode;
  title?: string;
  colorFrom: string;
  colorTo: string;
}): React.ReactElement => {
  return (
    <StyledCard $colorFrom={colorFrom} $colorTo={colorTo} title={title}>
      {children}
    </StyledCard>
  );
};

export default NavigationCard;
