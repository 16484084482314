// eslint-disable-next-line @typescript-eslint/no-var-requires
const layout = require("../config/layout.js");

const breakpoints = {
  "@screen-xs": layout.breakpoints.xs + "px",
  "@screen-sm": layout.breakpoints.sm + "px",
  "@screen-md": layout.breakpoints.md + "px",
  "@screen-lg": layout.breakpoints.lg + "px",
  "@screen-xl": layout.breakpoints.xl + "px",
  "@screen-xxl": layout.breakpoints.xxl + "px",

  "@screen-xs-min": layout.breakpoints.xs + "px",
  "@screen-sm-min": layout.breakpoints.sm + "px",
  "@screen-md-min": layout.breakpoints.md + "px",
  "@screen-lg-min": layout.breakpoints.lg + "px",
  "@screen-xl-min": layout.breakpoints.xl + "px",
  "@screen-xxl-min": layout.breakpoints.xxl + "px",

  "@screen-xs-max": layout.breakpoints.xs - 1 + "px",
  "@screen-sm-max": layout.breakpoints.sm - 1 + "px",
  "@screen-md-max": layout.breakpoints.md - 1 + "px",
  "@screen-lg-max": layout.breakpoints.lg - 1 + "px",
  "@screen-xl-max": layout.breakpoints.xl - 1 + "px",
  "@screen-xxl-max": layout.breakpoints.xxl - 1 + "px",
};

const padding = {
  "@padding-lg": layout.spacing.lg,
  "@padding-md": layout.spacing.md,
  "@padding-sm": layout.spacing.sm,
  "@padding-xs": layout.spacing.xs,
  "@padding-xss": layout.spacing.xxs,
};

module.exports = { breakpoints, padding };
