import React from "react";

import { breakpoints, spacing } from "config/layout";
import styled from "styled-components";

const ResponsieveContainer = styled.div`
  padding: ${spacing.xs}px;

  @media screen and (min-width: ${breakpoints.sm}px) {
    padding: ${spacing.sm}px;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    padding: ${spacing.md}px;
  }

  @media screen and (min-width: ${breakpoints.lg}px) {
    padding: ${spacing.lg}px;
  }
`;

const ResponsieveHorizontalContainer = styled.div`
  padding: 0 ${spacing.xs}px;

  @media screen and (min-width: ${breakpoints.sm}px) {
    padding: 0 ${spacing.sm}px;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    padding: 0 ${spacing.md}px;
  }

  @media screen and (min-width: ${breakpoints.lg}px) {
    padding: 0 ${spacing.lg}px;
  }
`;

const ResponsieveVerticalContainer = styled.div`
  padding: ${spacing.xs}px 0;

  @media screen and (min-width: ${breakpoints.sm}px) {
    padding: ${spacing.sm}px 0;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    padding: ${spacing.md}px 0;
  }

  @media screen and (min-width: ${breakpoints.lg}px) {
    padding: ${spacing.lg}px 0;
  }
`;

const Padding = ({
  children,
  size,
  direction = "both",
  style,
  className,
}: {
  children: React.ReactNode;
  size: "xxs" | "xs" | "sm" | "md" | "lg" | "responsive";
  direction?: "vertical" | "horizontal" | "both";
  style?: React.CSSProperties;
  className?: string;
}): JSX.Element => {
  if (size === "responsive") {
    if (direction === "vertical") {
      return (
        <ResponsieveVerticalContainer className={className} style={style}>
          {children}
        </ResponsieveVerticalContainer>
      );
    } else if (direction === "horizontal") {
      return (
        <ResponsieveHorizontalContainer className={className} style={style}>
          {children}
        </ResponsieveHorizontalContainer>
      );
    }
    return (
      <ResponsieveContainer className={className} style={style}>
        {children}
      </ResponsieveContainer>
    );
  } else {
    const space = spacing[size] + "px";

    return (
      <div
        className={className}
        style={{
          paddingLeft: direction === "horizontal" || direction === "both" ? space : 0,
          paddingRight: direction === "horizontal" || direction === "both" ? space : 0,
          paddingTop: direction === "vertical" || direction === "both" ? space : 0,
          paddingBottom: direction === "vertical" || direction === "both" ? space : 0,
          ...style,
        }}>
        {children}
      </div>
    );
  }
};

export default Padding;
