import Spin from "components/atoms/antD/Spin";

import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Loading = (): JSX.Element => {
  return <Spin data-testid="loading" indicator={antIcon} />;
};

export default Loading;
