import React, { CSSProperties } from "react";

import Padding from "components/atoms/Padding";

import { breakpoints, spacing } from "config/layout";
import styled from "styled-components";

const Container = styled.div`
  background: #fff;
  height: 100%;
`;

const ContainerWidthPadding = styled(Padding)`
  background: #fff;
  height: 100%;
`;

const BgContainer = ({
  children,
  padding = true,
  style = {},
}: {
  children: React.ReactNode;
  padding?: boolean;
  style?: CSSProperties;
}): JSX.Element => {
  return padding ? (
    <ContainerWidthPadding style={style} size="responsive">
      {children}
    </ContainerWidthPadding>
  ) : (
    <Container style={style}>{children}</Container>
  );
};

export default React.memo(BgContainer);

export const TabContainer = styled.div`
  padding-top: ${spacing.xs}px;

  @media screen and (min-width: ${breakpoints.sm}px) {
    padding-top: ${spacing.sm}px;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    padding-top: ${spacing.md}px;
  }

  @media screen and (min-width: ${breakpoints.lg}px) {
    padding-top: ${spacing.lg}px;
  }
`;
