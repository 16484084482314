import React from "react";

import { Resource, ResourceSkeletonFragment } from "generated/schemaTypes";

import ListTable from "components/atoms/ListTable";

import routes from "routes/routes";

const ListResources = ({ resources }: { resources?: Resource[] }): JSX.Element => {
  if (!resources) {
    return <></>;
  }

  let sortedResources = [...resources];
  resources = sortedResources.sort((a, b) => a.sortOrder - b.sortOrder);
  return (
    <>
      <ListTable<ResourceSkeletonFragment>
        easyColumns={{ name: "Name", sortOrder: "sortOrder", type: "Type" }}
        rowKey="id"
        dataSource={resources}
        showUrl={(_, resource): string => {
          return routes.resource.edit.path({
            tloId: resource.tlo,
            resourceId: resource.id,
          });
        }}
        editUrl={(_, resource): string => {
          return routes.resource.edit.path({
            tloId: resource.tlo,
            resourceId: resource.id,
          });
        }}
      />
    </>
  );
};

export default ListResources;
