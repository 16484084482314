import { ApolloClient, ApolloLink, NormalizedCacheObject } from "@apollo/client";

import cache from "apollo/cache";
import errorLink from "apollo/errorLink";
import typeDefs from "apollo/typeDefs";
import Amplify, { Auth } from "aws-amplify";
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import {
  REACT_APP_GRAPHQL_URL,
  AMAZON_COGNITO_AUTH_TYPE,
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_USER_POOL_ID,
  REACT_APP_AWS_OATH_DOMAIN,
  REACT_APP_AWS_OATH_SCOPE,
  REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
} from "envConfig";

// TODO: These values are now stored in .env, not sure if they should be generated with aws-cli or something instead. "aws-exports file"
const url = REACT_APP_GRAPHQL_URL;
const type = AMAZON_COGNITO_AUTH_TYPE;
const region = REACT_APP_AWS_REGION;
const userPoolId = REACT_APP_AWS_USER_POOL_ID;
const userPoolWebClientId = REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID;
const domain = REACT_APP_AWS_OATH_DOMAIN;
const scope = REACT_APP_AWS_OATH_SCOPE;

Amplify.configure({
  Auth: {
    userPoolId,
    userPoolWebClientId,
    region,
    oauth: {
      domain,
      scope: [scope],
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});

const jwtToken = async (): Promise<string> => {
  try {
    const user = await Auth.currentSession();
    return user.getIdToken().getJwtToken();
  } catch (error) {
    console.error(error);
    return "";
  }
};

const awsSettings = {
  url,
  region,
  auth: {
    type,
    jwtToken,
    // TODO: Unsure if credentials is needed for the refreshToken to work, initial tests without it.
    // credentials: async (): Promise<any> => await Auth.currentCredentials(),
  },
};

const authLink = createAuthLink(awsSettings);
const subscriptionLink = createSubscriptionHandshakeLink(awsSettings);

const link = ApolloLink.from([errorLink, authLink, subscriptionLink]);

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link,
  connectToDevTools: true,
  typeDefs,
});

export default client;
