import React from "react";

import AntDPopconfirm, { PopconfirmProps } from "antd/lib/popconfirm";
import styled from "styled-components";

const PopconfirmWrapper = (props: PopconfirmProps): JSX.Element => {
  return <AntDPopconfirm {...props} />;
};

const Popconfirm = styled(PopconfirmWrapper)`
  & {
  }
`;

export type { PopconfirmProps };
export default Popconfirm;
