import React from "react";

import AntDSpin, { SpinProps } from "antd/lib/spin";
import hoistNonReactStatics from "hoist-non-react-statics";

const SpinWrapper = (props: SpinProps): JSX.Element => {
  return <AntDSpin {...props} />;
};

export default hoistNonReactStatics(SpinWrapper, AntDSpin);
