import {
  BOOKING_TYPES,
  colorByBookingType,
  ResourceBullet,
  StyledLegend,
  StyledLegends,
} from "components/Schedule/styles";

const BookingLegends = (): React.ReactElement => {
  return (
    <StyledLegends>
      {BOOKING_TYPES.map((item) => {
        return (
          <StyledLegend key={item}>
            <ResourceBullet color={colorByBookingType(item)} />
            {item}
          </StyledLegend>
        );
      })}
    </StyledLegends>
  );
};

export default BookingLegends;
