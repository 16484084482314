import React from "react";

import BgContainer from "components/atoms/BgContainer";
import PageHeadline from "components/atoms/PageHeadline";
import Button from "components/atoms/antD/Button";
import Col from "components/atoms/antD/Col";
import Form from "components/atoms/antD/Form";
import FormItem from "components/atoms/antD/FormItem";
import Input from "components/atoms/antD/Input";
import Modal from "components/atoms/antD/Modal";
import Row from "components/atoms/antD/Row";

import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { signIn } from "helpers/auth";

interface Values {
  username: string;
  password: string;
}

const Login = (): JSX.Element => {
  const [form] = Form.useForm<Values>();

  const onFinish = async (values: Values): Promise<void> => {
    try {
      await signIn(values.username, values.password);
    } catch (error: any) {
      Modal.error({
        title: "Error",
        content: error.message,
      });
    }
  };

  return (
    <BgContainer>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{ username: "demo@matchi.se", password: "matchi123pass" }}>
        <Row>
          <Col span={12}>
            <PageHeadline title="Log in" />
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <FormItem
              name="username"
              tooltip={"For testing purpose, log in with 'demo@matchi.se'"}
              rules={[
                {
                  type: "email",
                  message: "The input is not a valid e-mail address",
                },
                {
                  required: true,
                  message: "Please input your e-mail address",
                },
              ]}
              hasFeedback>
              <Input
                type="email"
                placeholder={"Please input your e-mail address (use 'demo@matchi.se' for testing)"}
                required={true}
                prefix={<UserOutlined className="site-form-item-icon" />}
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <FormItem
              name="password"
              tooltip={"For testing purpose, log in with 'matchi123pass'"}
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              hasFeedback>
              <Input
                type="password"
                placeholder={"Please input your password (use 'matchi123pass' for testing)"}
                required={true}
                prefix={<LockOutlined className="site-form-item-icon" />}
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Button type="primary" htmlType="submit">
              Login
            </Button>
          </Col>
        </Row>
      </Form>
    </BgContainer>
  );
};

export default Login;
