import React from "react";
import { useParams } from "react-router-dom";

import { useResourcesFromVenueQuery } from "generated/schemaTypes";

import ListResources from "components/Resource/Get/ListResources";
import CreateResourceButton from "components/Resource/Modify/CreateResourcesButton";
import BgContainer from "components/atoms/BgContainer";
import PageHeadline from "components/atoms/PageHeadline";
import StatusHandler from "components/atoms/StatusHandler";

const IndexResourcesView = (): JSX.Element => {
  const { venueId, tloId } = useParams<{ venueId: string; tloId: string }>();

  const resourcesFromVenueQuery = useResourcesFromVenueQuery({
    variables: {
      id: venueId,
      tlo: tloId,
    },
  });

  return (
    <>
      <BgContainer>
        <StatusHandler
          dataQueries={[
            { query: resourcesFromVenueQuery, nonNullData: resourcesFromVenueQuery.data?.venue },
          ]}>
          {([venue]): React.ReactElement => (
            <>
              <PageHeadline title="Resources" />
              <CreateResourceButton parent={venue} />
              <ListResources resources={venue?.resources || []} />
            </>
          )}
        </StatusHandler>
      </BgContainer>
    </>
  );
};

export default IndexResourcesView;
