import React from "react";
import { useHistory } from "react-router-dom";

import { useCreateOrganizationMutation } from "generated/schemaTypes";

import ErrorMessage from "components/atoms/ErrorMessage";
import Modal from "components/atoms/antD/Modal";

import gql from "graphql-tag";
import routes from "routes/routes";

const CreateOrganization = (): JSX.Element => {
  const history = useHistory();

  const [, { error }] = useCreateOrganizationMutation({
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          listOrganizations(organizations) {
            organizations = organizations ? organizations : [];
            const newTodoRef = cache.writeFragment({
              data: data?.createOrganization,
              fragment: gql`
                fragment NewOrganization on Organization {
                  id
                  name
                }
              `,
            });
            return [...organizations, newTodoRef];
          },
        },
      });
    },
    onCompleted: (data) => {
      Modal.info({
        title: "This is a notification message",
        content: (
          <div>
            <p>{data?.createOrganization?.id}</p>
          </div>
        ),
        onOk() {
          if (data?.createOrganization?.id) {
            history.push(
              routes.organization.show.path({
                tloId: data.createOrganization.tlo,
                organizationId: data.createOrganization.id,
              }),
            );
          }
        },
      });
    },
  });

  // const onFinish = (values: any): void => {
  //   createOrganization(/*{ variables: values }*/);
  // };

  return <>{error && <ErrorMessage error={error} />}</>;
};

export default CreateOrganization;
