import React from "react";

import { ApolloError } from "@apollo/client";

import ErrorMessage from "components/atoms/ErrorMessage";
import Loading from "components/atoms/Loading";

type NonNullable<T> = Exclude<T, null | undefined>;

type nonNullableArray<A, B, C, D, E> = [
  NonNullable<A>,
  NonNullable<B>,
  NonNullable<C>,
  NonNullable<D>,
  NonNullable<E>,
];

interface Props<A, B, C, D, E> {
  breakOnLoading?: boolean;
  breakOnUndefinedLazyData?: boolean;
  dataQueries?: [
    {
      query: GenericApolloClientQueryInput;
      nonNullData: A;
    }?,
    {
      query: GenericApolloClientQueryInput;
      nonNullData: B;
    }?,
    {
      query: GenericApolloClientQueryInput;
      nonNullData: C;
    }?,
    {
      query: GenericApolloClientQueryInput;
      nonNullData: D;
    }?,
    {
      query: GenericApolloClientQueryInput;
      nonNullData: E;
    }?,
  ];
  children:
    | ((values: nonNullableArray<A, B, C, D, E>, loading: boolean) => React.ReactNode)
    | React.ReactElement
    | React.ReactElement[];
}

export interface GenericApolloClientQueryInput {
  data: any;
  loading: boolean;
  error?: ApolloError;
  called: boolean;
}

function isPresent<T>(
  value: T | undefined | null,
  index: number,
  avoidError: boolean = false,
): value is T {
  if (value !== undefined && value !== null) {
    return true;
  } else {
    if (avoidError) {
      return false;
    } else {
      throw new Error(`Return variable [${index}] is ${typeof value}`);
    }
  }
}

function StatusHandler<A, B, C, D, E>({
  breakOnLoading = true,
  breakOnUndefinedLazyData = false,
  dataQueries,
  children,
}: Props<A, B, C, D, E>): React.ReactElement {
  const anyError = dataQueries
    ?.map((obj) => {
      if (obj?.query.called) {
        return obj.query.error;
      }
      return false;
    })
    .filter((error) => error);

  const anyLoading = dataQueries
    ?.map((obj) => {
      if (obj?.query.called) {
        return obj.query.loading;
      }
      return false;
    })
    .filter((loading) => loading);

  if (anyError?.length) {
    return (
      <>
        {dataQueries?.map((obj) => (
          <ErrorMessage error={obj?.query.error} />
        ))}
      </>
    );
  }

  // If any query is still loading, show only indicator.
  // If breakOnLoading = false we try to display both indicator and data in final return
  if (breakOnLoading && anyLoading?.length) {
    return <Loading />;
  }

  const anyNullError = dataQueries?.find((obj, index) => {
    if (obj && (obj.query.called || breakOnUndefinedLazyData)) {
      return !isPresent(obj.nonNullData, index, !obj.query.called && breakOnUndefinedLazyData);
    }
    return false;
  });

  if (anyNullError) {
    return <></>;
  }

  return (
    <>
      {typeof children === "function"
        ? children(
            dataQueries?.map((obj) => obj?.nonNullData) as nonNullableArray<A, B, C, D, E>,
            Boolean(anyLoading && anyLoading?.length > 0),
          )
        : children}
    </>
  );
}

export default StatusHandler;
