import AntDInput, { InputProps, TextAreaProps } from "antd/lib/input";
import hoistNonReactStatics from "hoist-non-react-statics";
import styled from "styled-components";

const Input = styled(AntDInput)`
  .ant-input-group-wrapper,
  & {
    background: #fff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    height: 42px;

    input,
    .ant-input-wrapper {
      height: 100%;
    }
    * {
      border: none;
    }
    .ant-input-group-addon {
      border-left: 1px solid #d9d9d9;
    }
  }
`;

export const TextArea = hoistNonReactStatics(
  styled(AntDInput.TextArea)`
    input {
      background: #fff;
      border: 1px solid #d9d9d9;
      box-sizing: border-box;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
    }
  `,
  AntDInput.TextArea,
);

export type { InputProps, TextAreaProps };

export default hoistNonReactStatics(Input, AntDInput);
