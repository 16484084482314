import React from "react";
import { useParams } from "react-router-dom";

import { useBookingsWithEventsQuery, useResourcesFromVenueQuery } from "generated/schemaTypes";

import BookingsListTable from "components/Booking/BookingsListTable";
import { filterBookingsByStatus } from "components/Schedule/helpers";
import DateNavigator from "components/atoms/DateNavigator";
import StatusHandler from "components/atoms/StatusHandler";

import dayjs from "dayjs";
import { parseDatestring } from "helpers/date";

const CancellationsList = ({
  tlo,
  venueId,
  navigationPathForDate,
}: {
  tlo: string;
  venueId: string;
  navigationPathForDate: (date: string) => string;
}): JSX.Element => {
  const { date } = useParams<{ date?: string }>();

  const bookingsWithEventsFromVenueQuery = useBookingsWithEventsQuery({
    variables: { tlo: tlo, venue: venueId, date: dayjs(date).format("YYYY-MM-DD") },
  });
  const resourcesFromVenueQuery = useResourcesFromVenueQuery({
    variables: { tlo: tlo, id: venueId },
  });

  const selectedDate = parseDatestring(date, true);

  return (
    <StatusHandler
      dataQueries={[
        {
          query: bookingsWithEventsFromVenueQuery,
          nonNullData: bookingsWithEventsFromVenueQuery.data?.bookings?.bookings,
        },
        {
          query: resourcesFromVenueQuery,
          nonNullData: resourcesFromVenueQuery.data?.venue,
        },
      ]}>
      {([bookings, venueWithResources]): React.ReactElement => {
        const cancelledBookings = filterBookingsByStatus(bookings, "CANCELLED");

        let sortedBookings = [...cancelledBookings];
        bookings = sortedBookings.sort((a, b) => Date.parse(a.startTime) - Date.parse(b.startTime));

        return (
          <>
            <DateNavigator date={selectedDate} navigationPathForDate={navigationPathForDate} />
            <BookingsListTable
              bookings={bookings}
              resources={venueWithResources.resources ?? []}
              emptyListDescription={"No cancelled bookings on this day!"}
            />
          </>
        );
      }}
    </StatusHandler>
  );
};

export default CancellationsList;
