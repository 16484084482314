import React, { useEffect } from "react";

import { ConfigAttribute, ConfigFieldsFragment, Resource } from "generated/schemaTypes";

import SimpleFormItemFactory, {
  extendedFormItemProps,
  SimpleFormProps,
} from "components/SimpleFormItemFactory";
import Form from "components/atoms/antD/Form";
import FormItem from "components/atoms/antD/FormItem";
import Input from "components/atoms/antD/Input";
import Radio from "components/atoms/antD/Radio";

import { FormInstance, Select } from "antd";
import { getFirst } from "helpers/Array";
import { convertResourceToValues, ResourceFormValues } from "helpers/converters/resource";

const staticFormItems: SimpleFormProps = {
  name: {
    label: "Name",
    rules: [{ required: true }],
  },
  type: {
    label: "Type",
    children: (
      <Select>
        <Select.Option value="PADEL">Padel</Select.Option>
        <Select.Option value="TENNIS">Tennis</Select.Option>
      </Select>
    ),
    rules: [{ required: true }],
  },
  sortOrder: {
    label: "Sortorder",
    rules: [{ required: true }],
  },
};

const getConfigInputs = (config: ConfigFieldsFragment): SimpleFormProps => {
  return (
    config?.configAttributes?.reduce((acc, attribute) => {
      return { ...acc, ...getConfigInputsByAttribute(attribute, config.configType) };
    }, {}) || {}
  );
};

const getConfigInputsByAttribute = (
  attribute: ConfigAttribute,
  configType: string,
): SimpleFormProps => {
  let input = <Input />;

  if (attribute.type === "CHOICE") {
    input = (
      <Radio.Group>
        {attribute.options?.map((option) => {
          return (
            <Radio key={option} value={option}>
              {option}
            </Radio>
          );
        })}
      </Radio.Group>
    );
  }

  return {
    [configType + "." + attribute.attribute]: {
      label: attribute.attribute,
      name: ["attributes", configType, attribute.attribute],
      initialValue: getFirst(attribute.options),
      children: input,
      otherProps: {
        configType: configType,
      },
    },
  };
};

const buildFormSet = (configs: ConfigFieldsFragment[]): SimpleFormProps => {
  let map: SimpleFormProps = {};

  configs.forEach((config) => {
    map = Object.assign(map, getConfigInputs(config));
  });
  return map;
};

export interface FormRef {
  form: FormInstance<ResourceFormValues>;
}

const ResourceForm = ({
  initialValues,
  resourceConfigs = [],
  addForm,
  formName,
  resource,
  actionElements,
  onFinish,
}: {
  initialValues?: ResourceFormValues;
  resourceConfigs: ConfigFieldsFragment[];
  addForm?: (form: FormInstance<ResourceFormValues>) => void;
  formName?: string;
  resource?: Resource;
  actionElements?: React.ReactNode;
  onFinish?: (values: ResourceFormValues) => void;
}): JSX.Element => {
  const [form] = Form.useForm<ResourceFormValues>();
  console.log(resource);
  console.log(convertResourceToValues(resource) || initialValues);

  useEffect(() => {
    if (addForm) {
      addForm(form);
    }
  }, [addForm, form]);

  const dynamicFormItems = buildFormSet(resourceConfigs);

  const SimpleFormItem = SimpleFormItemFactory(staticFormItems);
  const SimpleFormItem2 = SimpleFormItemFactory(dynamicFormItems);

  return (
    <Form
      name={formName}
      form={form}
      layout="vertical"
      initialValues={convertResourceToValues(resource) || initialValues}
      onFinish={onFinish}>
      {Object.keys(staticFormItems).map((item) => {
        return <SimpleFormItem key={item} item={item} />;
      })}
      <FormItem noStyle shouldUpdate>
        {({ getFieldValue }): React.ReactNode => {
          return Object.keys(dynamicFormItems).map((item): JSX.Element => {
            if (
              (dynamicFormItems[item] as extendedFormItemProps).otherProps?.configType ===
              getFieldValue("type")
            ) {
              return <SimpleFormItem2 key={item} item={item} />;
            }
            return <></>;
          });
        }}
      </FormItem>
      {actionElements}
    </Form>
  );
};

export default ResourceForm;
