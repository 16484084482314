import React from "react";
import { useHistory } from "react-router-dom";

import {
  useUpdateResourceMutation,
  useDeleteResourceMutation,
  ResourceInput,
  Resource,
  ConfigFieldsFragment,
} from "generated/schemaTypes";

import ResourceForm from "components/Resource/Modify/ResourceForm";
import ConfirmModal from "components/atoms/ConfirmModal";
import ErrorMessage from "components/atoms/ErrorMessage";
import Button from "components/atoms/antD/Button";

import { convertValuesToResourceInput, ResourceFormValues } from "helpers/converters/resource";
import { deleteResourceInCache, updateResourceInCache } from "services/ResourceServices";

const EditResource = ({
  resource,
  resourceConfigs,
}: {
  resource: Resource;
  resourceConfigs: ConfigFieldsFragment[];
}): JSX.Element => {
  const history = useHistory();

  const [updateResource, { loading: updateLoading, error: updateError }] =
    useUpdateResourceMutation({
      update: updateResourceInCache,
      onCompleted: () => {
        history.goBack();
      },
    });

  if (!resource) {
    return <ErrorMessage error={{ message: "No resource" }} />;
  }

  const onSubmit = (values: ResourceFormValues): void => {
    const resourceInput: ResourceInput = convertValuesToResourceInput(
      values,
      resource.tlo,
      resource.id,
    );

    updateResource({ variables: { resource: resourceInput } });
  };

  return (
    <>
      {updateError ? (
        <ErrorMessage error={updateError} />
      ) : (
        resource &&
        resourceConfigs && (
          <ResourceForm
            onFinish={onSubmit}
            resourceConfigs={resourceConfigs}
            resource={resource}
            actionElements={
              <>
                <Button type="primary" htmlType="submit" loading={updateLoading}>
                  Save
                </Button>
                <DeleteResourceButton
                  id={resource.id}
                  tlo={resource.tlo}
                  type={resource.type}
                  sortOrder={resource.sortOrder}
                />
              </>
            }
          />
        )
      )}
    </>
  );
};

export default EditResource;

const DeleteResourceButton = ({
  id,
  tlo,
  type,
  sortOrder,
}: {
  id: string;
  tlo: string;
  type: string;
  sortOrder: number;
}): JSX.Element => {
  const history = useHistory();
  const [deleteResource, { loading }] = useDeleteResourceMutation({
    update: deleteResourceInCache,
    onError: (error) => {
      <ErrorMessage error={error} />;
    },
    onCompleted: () => {
      history.goBack();
    },
  });

  const deleteAction = (e?: React.MouseEvent): void => {
    deleteResource({
      variables: { tlo: tlo, id: id },
    });
  };

  return (
    <ConfirmModal
      title="Delete"
      message="Deleted resources are gone forever."
      okColor="danger"
      okText="Delete"
      onConfirm={deleteAction}>
      <Button color="danger" loading={loading}>
        Delete
      </Button>
    </ConfirmModal>
  );
};
