import React from "react";

import AntDImage, { ImageProps } from "antd/lib/image";
import hoistNonReactStatics from "hoist-non-react-statics";

const ImageWrapper = (props: ImageProps): JSX.Element => {
  return <AntDImage {...props} />;
};

export default hoistNonReactStatics(ImageWrapper, AntDImage);
