import React from "react";
import { Link } from "react-router-dom";

import TodaysUpcomingBookings from "components/Booking/Get/TodaysUpcomingBookings";
import OrganizationAndVenueProvider from "components/OrganizationAndVenueProvider";
import BgContainer from "components/atoms/BgContainer";
import NavigationCard from "components/atoms/NavigationCard";
import PageHeadline from "components/atoms/PageHeadline";
import Col from "components/atoms/antD/Col";
import Row from "components/atoms/antD/Row";

import {
  faCompass,
  faCalendarAlt,
  faCalendarEdit,
  faTennisBall,
  faUsers,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import routes from "routes/routes";

const Home = (): React.ReactElement => {
  return (
    <OrganizationAndVenueProvider>
      {({ organization, venue }): React.ReactElement => {
        return (
          <BgContainer>
            <Row>
              <Col span={12}>
                <PageHeadline title="Dashboard" />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <h2>Welcome to MATCHi, have a magnificent {dayjs().format("dddd")}!</h2>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <TodaysUpcomingBookings tlo={venue.tlo} venueId={venue.id} />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={8} lg={6}>
                <NavigationCard colorFrom={"#FFBE71"} colorTo={"#FF5F6D"}>
                  <FontAwesomeIcon icon={faCompass} />
                  <Link to={routes.venue.edit.path({ tloId: venue.tlo, venueId: venue.id })}>
                    My Venue
                  </Link>
                </NavigationCard>
              </Col>

              <Col sm={12} md={8} lg={6}>
                <NavigationCard colorFrom={"#00D2FF"} colorTo={"#3A7BD5"}>
                  <FontAwesomeIcon icon={faTennisBall} />
                  <Link to={routes.resource.index.path({ tloId: venue.tlo, venueId: venue.id })}>
                    Resources
                  </Link>
                </NavigationCard>
              </Col>
              <Col sm={12} md={8} lg={6}>
                <NavigationCard colorFrom={"#00D2FF"} colorTo={"#3A7BD5"}>
                  <FontAwesomeIcon icon={faUsers} />
                  <Link to={routes.customer.index.path({ tloId: venue.tlo, venueId: venue.id })}>
                    Customers
                  </Link>
                </NavigationCard>
              </Col>
              <Col sm={12} md={8} lg={6}>
                <NavigationCard colorFrom={"#6BC945"} colorTo={"#22AD5C"}>
                  <FontAwesomeIcon icon={faCalendarEdit} />
                  <Link
                    to={routes.scheduleRule.index.path({ tloId: venue.tlo, venueId: venue.id })}>
                    Schedule
                  </Link>
                </NavigationCard>
              </Col>
              <Col sm={12} md={8} lg={6}>
                <NavigationCard colorFrom={"#824456"} colorTo={"#32487A"}>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                  <Link to={routes.booking.overview.path({ tloId: venue.tlo, venueId: venue.id })}>
                    Bookings
                  </Link>
                </NavigationCard>
              </Col>
            </Row>
          </BgContainer>
        );
      }}
    </OrganizationAndVenueProvider>
  );
};

export default Home;
