import React from "react";

import AntDRow, { RowProps } from "antd/lib/row";
import hoistNonReactStatics from "hoist-non-react-statics";

const RowWrapper = (props: RowProps): JSX.Element => {
  return <AntDRow {...props} />;
};

export default hoistNonReactStatics(RowWrapper, AntDRow);
