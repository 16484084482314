import ShowOrganizationView from "components/Organization/Views/ShowOrganizationView";

import { BASE_ROUTE_PATH_ORGANIZATION } from "routes/paths";

const route = {
  path: (params?: { tloId?: string; organizationId?: string }): string => {
    return (
      BASE_ROUTE_PATH_ORGANIZATION +
      "/show/" +
      (params ? params.tloId : ":tloId") +
      "/" +
      (params ? params.organizationId : ":organizationId")
    );
  },
  component: ShowOrganizationView,
};

export default route;
