import React from "react";

import { Venue } from "generated/schemaTypes";

import InputFieldRow from "components/InputFieldRow";
import SimpleFormItemFactory, { SimpleFormProps } from "components/SimpleFormItemFactory";
import VenueInfoCard from "components/Venue/VenueInfoCard";
import Spacer from "components/atoms/Spacer";
import { DatePicker } from "components/atoms/antD";
import Button from "components/atoms/antD/Button";
import Col from "components/atoms/antD/Col";
import Form from "components/atoms/antD/Form";
import Input from "components/atoms/antD/Input";
import Row from "components/atoms/antD/Row";

import TextArea from "antd/lib/input/TextArea";
import dayjs from "dayjs";
import { convertVenueToValues, VenueFormValues } from "helpers/converters/venue";

const simpleFormItems: SimpleFormProps = {
  address: {
    label: "Address",
    rules: [{ required: false }],
  },
  zipCode: {
    label: "ZIP",
    rules: [{ required: false }],
  },
  city: {
    label: "City",
    rules: [{ type: "string", required: false }],
  },
  country: {
    label: "Country",
    rules: [{ type: "string", required: false }],
  },
  longitude: {
    label: "Longitude",
    rules: [{ type: "string", required: false }],
  },
  latitude: {
    label: "Latitude",
    rules: [{ type: "string", required: false }],
  },
  email: {
    label: "Email",
    rules: [{ type: "email", required: false }],
  },
  phone: {
    label: "Phone",
    rules: [{ type: "string", required: false }],
  },
  about: {
    label: "About",
    rules: [{ type: "string", required: false }],
    children: <Input.TextArea />,
  },
  name: {
    label: "Name",
    rules: [{ required: false }],
  },
  openingHoursDeviation: () => {
    return {
      name: ["openingHoursDeviation"],
      label: "Deviating opening hours",
      children: <TextArea rows={4} />,
    };
  },
  openingHours: (props) => {
    const { day } = props ?? {};
    return {
      name: ["openingHours", day],
      children: (
        <>
          <DatePicker.RangePicker format="HH:mm" order={false} allowEmpty={[true, true]} />
        </>
      ),
    };
  },
};

const SimpleFormItem = SimpleFormItemFactory(simpleFormItems);

interface props {
  onFinish?: (values: VenueFormValues) => void;
  venue?: Venue;
  extraAction?: JSX.Element;
  submitLoading?: boolean;
}

const VenueOpeningHours = ({ day }: { day: string }): JSX.Element => {
  return (
    <Row>
      <Col span={8}>{day}</Col>
      <Col span={16}>
        <SimpleFormItem item={"openingHours"} props={{ day: day }} />
      </Col>
    </Row>
  );
};

const CreateOrUpdateVenueForm = ({
  onFinish,
  venue,
  extraAction,
  submitLoading = false,
}: props): JSX.Element => {
  const [form] = Form.useForm<VenueFormValues>();

  const weekDays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

  return (
    <Form
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      initialValues={
        venue
          ? convertVenueToValues(venue)
          : {
              openingHours: {
                monday: [dayjs(), dayjs().add(5, "hour")],
                tuesday: [dayjs(), dayjs().add(5, "hour")],
                wednesday: [dayjs(), dayjs().add(5, "hour")],
                thursday: [dayjs(), dayjs().add(5, "hour")],
                friday: [dayjs(), dayjs().add(5, "hour")],
                saturday: [dayjs(), dayjs().add(5, "hour")],
                sunday: [dayjs(), dayjs().add(5, "hour")],
                desciption: "",
              },
            }
      }
      layout={"vertical"}>
      <VenueInfoCard form={form} />

      <InputFieldRow inputs={[<SimpleFormItem item="name" />, <></>]} />
      <InputFieldRow
        inputs={[<SimpleFormItem item="address" />, <SimpleFormItem item="zipCode" />]}
      />
      <InputFieldRow inputs={[<SimpleFormItem item="city" />, <SimpleFormItem item="country" />]} />
      <InputFieldRow
        inputs={[<SimpleFormItem item="longitude" />, <SimpleFormItem item="latitude" />]}
      />
      <Spacer size="lg" />
      <InputFieldRow inputs={[<SimpleFormItem item="email" />, <SimpleFormItem item="phone" />]} />

      <Spacer size="lg" />
      <Row>
        <Col span={12}>
          {weekDays.map((day) => (
            <VenueOpeningHours data-cy={day} day={day} key={day} />
          ))}
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <SimpleFormItem item={"openingHoursDeviation"} />
          <Row>
            <Button type="primary" htmlType="submit" loading={submitLoading}>
              Save
            </Button>
            {extraAction && <Spacer size="responsive" direction="horizontal" />}
            {extraAction}
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default CreateOrUpdateVenueForm;
