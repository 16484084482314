import loginRoute from "routes/authorization/login";
import bookingsOverviewRoute from "routes/booking/overview";
import indexCustomerRoute from "routes/customer/index";
import demoRoute from "routes/demo";
import homeRoute from "routes/home";
import createOrganizationRoute from "routes/organization/create";
import editOrganizationRoute from "routes/organization/edit";
import indexOrganizationRoute from "routes/organization/index";
import showOrganizationRoute from "routes/organization/show";
import createResourceRoute from "routes/resource/create";
import editResourceRoute from "routes/resource/edit";
import indexResourceRoute from "routes/resource/index";
import showResourceRoute from "routes/resource/show";
import editScheduleRuleRoute from "routes/scheduleRule/edit";
import indexScheduleRuleRoute from "routes/scheduleRule/index";
import createVenueRoute from "routes/venue/create";
import editVenueRoute from "routes/venue/edit";
import showVenueRoute from "routes/venue/show";

const routes = {
  organization: {
    index: indexOrganizationRoute,
    show: showOrganizationRoute,
    create: createOrganizationRoute,
    edit: editOrganizationRoute,
  },
  venue: {
    show: showVenueRoute,
    create: createVenueRoute,
    edit: editVenueRoute,
  },
  resource: {
    index: indexResourceRoute,
    show: showResourceRoute,
    create: createResourceRoute,
    edit: editResourceRoute,
  },
  customer: {
    index: indexCustomerRoute,
  },
  scheduleRule: {
    index: indexScheduleRuleRoute,
    edit: editScheduleRuleRoute,
  },
  booking: {
    overview: bookingsOverviewRoute,
  },
  authorization: {
    login: loginRoute,
  },
  demo: demoRoute,
  home: homeRoute,
};

export interface Route {
  path: (props?: { [key: string]: any }) => string;
  component: React.ComponentType<any>;
}

export default routes;
