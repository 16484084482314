import { useEffect, useState } from "react";

import { useCreateBookingMutation, useResourcesFromVenueQuery, Venue } from "generated/schemaTypes";

import BookingForm from "components/Booking/BookingForm";
import { withOrganizationAndVenue } from "components/OrganizationAndVenueProvider";
import StatusHandler from "components/atoms/StatusHandler";
import Button from "components/atoms/antD/Button";
import message from "components/atoms/antD/Message";
import Modal from "components/atoms/antD/Modal";

import { useForm } from "antd/lib/form/Form";
import { BookingFormValues, convertValuesToBookingInput } from "helpers/converters/booking";
import { isFormInitialized } from "helpers/form";
import { createBookingInCache } from "services/BookingServices";

const CreateBooking = withOrganizationAndVenue(
  ({
    venue,
    children,
  }: {
    venue: Venue;
    children: (props: { openModal: (values?: BookingFormValues) => void }) => React.ReactElement;
  }): React.ReactElement => {
    const [form] = useForm<BookingFormValues>();
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
      if (modalOpen === false && isFormInitialized(form)) {
        form.resetFields();
      }
    }, [modalOpen, form]);

    const resourcesFromVenueQuery = useResourcesFromVenueQuery({
      variables: {
        id: venue.id,
        tlo: venue.tlo,
      },
    });

    const [createBooking, { loading: createLoading }] = useCreateBookingMutation({
      update: createBookingInCache,
      onCompleted: () => {
        setModalOpen(false);
        message.success("Booking added");
      },
      onError: (e) => {
        message.error(e.message);
      },
    });

    const onSubmit = (): void => {
      const values = convertValuesToBookingInput(form.getFieldsValue(), venue.tlo);

      createBooking({ variables: { createBooking: { parent: venue.id, booking: values } } });

      return;
    };

    const openModal = (values?: BookingFormValues): void => {
      form.setFieldsValue(values || {});
      setModalOpen(true);
    };

    return (
      <>
        {children({ openModal })}
        <Modal
          centered
          title="Create Booking"
          visible={modalOpen}
          onCancel={(): void => {
            setModalOpen(false);
          }}
          footer={
            <>
              <Button
                loading={createLoading}
                onClick={(): void => {
                  onSubmit();
                }}
                type="primary">
                Save
              </Button>
            </>
          }>
          <StatusHandler
            dataQueries={[
              {
                query: resourcesFromVenueQuery,
                nonNullData: resourcesFromVenueQuery.data?.venue?.resources,
              },
            ]}>
            {([resources]): React.ReactElement => (
              <BookingForm form={form} resources={resources} cancelledBooking={false} />
            )}
          </StatusHandler>
        </Modal>
      </>
    );
  },
);

export default CreateBooking;
