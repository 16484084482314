import { breakpoints, spacing } from "config/layout";
import styled from "styled-components";

const ResponsieveHorizontalSpacer = styled.div`
  height: 100%;
  width: ${spacing.xs}px;
  display: inline-block;

  @media screen and (min-width: ${breakpoints.sm}px) {
    width: ${spacing.sm}px;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    width: ${spacing.md}px;
  }

  @media screen and (min-width: ${breakpoints.lg}px) {
    width: ${spacing.lg}px;
  }
`;

const ResponsieveVerticalSpacer = styled.div`
  width: 100%;
  height: ${spacing.xs}px;
  display: inline-block;

  @media screen and (min-width: ${breakpoints.sm}px) {
    height: ${spacing.sm}px;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    height: ${spacing.md}px;
  }

  @media screen and (min-width: ${breakpoints.lg}px) {
    height: ${spacing.lg}px;
  }
`;

const Spacer = ({
  size,
  direction = "vertical",
}: {
  size: "xxs" | "xs" | "sm" | "md" | "lg" | "responsive";
  direction?: "vertical" | "horizontal";
}): JSX.Element => {
  if (size === "responsive") {
    if (direction === "horizontal") {
      return <ResponsieveHorizontalSpacer />;
    } else {
      return <ResponsieveVerticalSpacer />;
    }
  }

  const space = spacing[size];

  let width: string, height: string, display: string;

  if (direction === "vertical") {
    height = space + "px";
    width = "auto";
    display = "block";
  } else {
    height = "auto";
    width = space + "px";
    display = "inline-block";
  }

  return <div style={{ display: display, width: width, height: height }}></div>;
};

export default Spacer;
