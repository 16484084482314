import { useDeleteCustomerMutation } from "generated/schemaTypes";

import ConfirmModal from "components/atoms/ConfirmModal";
import ErrorMessage from "components/atoms/ErrorMessage";
import Button from "components/atoms/antD/Button";
import message from "components/atoms/antD/Message";

import cache from "apollo/cache";
import { deleteCustomerInCache } from "services/CustomerServices";

const DeleteCustomersButton = ({
  selectedCheckboxes,
  resetSelectedCheckboxes,
  tloId,
}: {
  selectedCheckboxes: React.Key[];
  resetSelectedCheckboxes: () => void;
  tloId: string;
}): JSX.Element => {
  const [deleteCustomer, { loading }] = useDeleteCustomerMutation({
    onError: (error) => {
      <ErrorMessage error={error} />;
    },
  });

  const deleteAction = (): Promise<boolean> => {
    return Promise.all(
      selectedCheckboxes.map((customerId: React.Key) => {
        return deleteCustomer({
          variables: { tlo: tloId, id: customerId.toString() },
        });
      }),
    ).then(() => {
      deleteCustomerInCache(
        cache,
        selectedCheckboxes?.map((id) => id.toString()),
      );
      resetSelectedCheckboxes();
      message.success("Customer deleted successfully.");

      return true;
    });
  };

  return (
    <>
      <ConfirmModal
        title="Delete"
        onConfirm={deleteAction}
        okText="Delete"
        okColor="danger"
        cancelText="Cancel"
        message="Are you sure you want to delete?"
        okLoading={loading}>
        <Button type="default" disabled={selectedCheckboxes.length === 0} title="Delete customer">
          Delete
        </Button>
      </ConfirmModal>
    </>
  );
};

export default DeleteCustomersButton;
