import ShowVenueView from "components/Venue/Views/ShowVenueView";

import { BASE_ROUTE_PATH_VENUE } from "routes/paths";

const route = {
  path: (params?: { tloId?: string; venueId?: string }): string => {
    return (
      BASE_ROUTE_PATH_VENUE +
      "/show/" +
      (params ? params.tloId : ":tloId") +
      "/" +
      (params ? params.venueId : ":venueId")
    );
  },
  component: ShowVenueView,
};

export default route;
