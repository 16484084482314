import { useState } from "react";
import { useParams } from "react-router-dom";

import {
  useResourcesFromVenueQuery,
  Organization,
  Venue,
  useBookingsQuery,
} from "generated/schemaTypes";

import BookingsListTable from "components/Booking/BookingsListTable";
import CreateBooking from "components/Booking/Modify/CreateBooking";
import { withOrganizationAndVenue } from "components/OrganizationAndVenueProvider";
import Schedule from "components/Schedule/Schedule";
import BookingLegends from "components/Schedule/legends";
import DateNavigator from "components/atoms/DateNavigator";
import FlexContainer from "components/atoms/FlexContainer";
import Loading from "components/atoms/Loading";
import Spacer from "components/atoms/Spacer";
import StatusHandler from "components/atoms/StatusHandler";
import Button from "components/atoms/antD/Button";
import Col from "components/atoms/antD/Col";
import Row from "components/atoms/antD/Row";

import { faCalendarAlt, faList } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";

const BookingsOverview = withOrganizationAndVenue(
  ({
    venue,
    organization,
    navigationPathForDate,
  }: {
    venue: Venue;
    organization: Organization;
    navigationPathForDate: (date: string) => string;
  }): JSX.Element => {
    const { date } = useParams<{ date?: string }>();
    const selectedDate = dayjs(date).isValid() ? dayjs(date) : dayjs();
    const bookings = useBookingsQuery({
      variables: {
        tlo: venue.tlo,
        venue: venue.id,
        date: selectedDate.format("YYYY-MM-DD"),
      },
    });
    const resourcesFromVenueQuery = useResourcesFromVenueQuery({
      variables: { tlo: venue.tlo, id: venue.id },
    });

    const [listMode, setListMode] = useState(false);

    return (
      <StatusHandler
        breakOnLoading={false}
        dataQueries={[
          {
            query: bookings,
            nonNullData: bookings.data?.bookings || { bookings: [] },
          },
          {
            query: resourcesFromVenueQuery,
            nonNullData: resourcesFromVenueQuery.data?.venue?.resources || [],
          },
        ]}>
        {([scheduleData, resources], loading): React.ReactElement => {
          let sortedBookings = [...scheduleData?.bookings].sort(
            (a, b) => Date.parse(a.startTime) - Date.parse(b.startTime),
          );

          return (
            <>
              <FlexContainer>
                <CreateBooking>
                  {({ openModal }): React.ReactElement => {
                    return (
                      <>
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}>
                          <Col style={{ alignSelf: "flex-start" }}>
                            <Button
                              type="primary"
                              title="Add booking"
                              onClick={(e): void => {
                                openModal();
                              }}>
                              Add booking
                            </Button>
                          </Col>
                          <Col style={{ alignSelf: "flex-end", display: "flex" }}>
                            {loading && <Loading />}
                            <Button
                              title="Calendar mode"
                              icon={<FontAwesomeIcon icon={faCalendarAlt} />}
                              onClick={(e): void => {
                                setListMode(false);
                              }}
                            />
                            <Spacer size="md" direction="horizontal" />
                            <Button
                              title="List mode"
                              icon={<FontAwesomeIcon icon={faList} />}
                              onClick={(e): void => {
                                setListMode(true);
                              }}></Button>
                          </Col>
                        </Row>
                        <DateNavigator
                          date={selectedDate}
                          navigationPathForDate={navigationPathForDate}
                        />

                        {listMode ? (
                          <BookingsListTable
                            bookings={sortedBookings}
                            resources={resources}
                            emptyListDescription={
                              "There are currently no active booking to display on this day!"
                            }
                          />
                        ) : (
                          <>
                            <Schedule
                              showCurrentTimeAxis
                              triggerCreateBooking
                              data={{
                                scheduleObjectOrigins: sortedBookings,
                                resources: resources,
                                day: selectedDate,
                                boundaries: scheduleData.boundaries,
                              }}
                            />
                            <div>
                              <BookingLegends />
                            </div>
                          </>
                        )}
                      </>
                    );
                  }}
                </CreateBooking>
              </FlexContainer>
            </>
          );
        }}
      </StatusHandler>
    );
  },
);

export default BookingsOverview;
