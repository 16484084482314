import React from "react";

import {
  Organization,
  useVenuesFromOrganizationQuery,
  VenueSkeletonFragment,
} from "generated/schemaTypes";

import ListTable from "components/atoms/ListTable";
import StatusHandler from "components/atoms/StatusHandler";
import Button from "components/atoms/antD/Button";

import routes from "routes/routes";

const ListVenuesByOrganization = ({
  organization,
}: {
  organization: Organization;
}): JSX.Element => {
  const venuesFromOrganizationQuery = useVenuesFromOrganizationQuery({
    variables: { id: organization.id ?? "", tlo: organization.tlo },
  });

  return (
    <StatusHandler
      dataQueries={[
        {
          query: venuesFromOrganizationQuery,
          nonNullData: venuesFromOrganizationQuery?.data?.organization,
        },
      ]}>
      {([organization]): React.ReactElement => (
        <>
          <h3>Venues</h3>
          <Button
            href={routes.venue.create.path({
              organizationId: organization.id,
              tloId: organization.tlo,
            })}
            type="primary">
            Add venue
          </Button>
          <ListTable<VenueSkeletonFragment>
            easyColumns={{ name: "Name" }}
            dataSource={organization.venues}
            editUrl={(abs, venue): string => {
              return routes.venue.edit.path({ tloId: venue.tlo, venueId: venue.id });
            }}
            showUrl={(abs, venue): string => {
              return routes.venue.edit.path({ tloId: venue.tlo, venueId: venue.id });
            }}
          />
        </>
      )}
    </StatusHandler>
  );
};

export default ListVenuesByOrganization;
