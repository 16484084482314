import IndexOrganizationsView from "components/Organization/Views/IndexOrganizationsView";

import { BASE_ROUTE_PATH_ORGANIZATION } from "routes/paths";

const route = {
  path: (): string => BASE_ROUTE_PATH_ORGANIZATION + "/",
  component: IndexOrganizationsView,
};

export default route;
