import { isLoggedInVar } from "apollo/cache";
import { Auth } from "aws-amplify";
import { AWS_LOCAL_STORAGE_PREFIX } from "constants/index";

export const signIn = async (username: string, password: string): Promise<void> => {
  try {
    const user = await Auth.signIn(username, password);
    const prefix = `${user.keyPrefix}.${user.username}`;
    localStorage.setItem(AWS_LOCAL_STORAGE_PREFIX, prefix);
    isLoggedInVar(true);
  } catch (error) {
    console.error(error);
    isLoggedInVar(false);
  }
};

export const checkIfUserAlreadyAuthenticated = async (): Promise<void> => {
  try {
    const user = await Auth.currentSession();
    if (user) isLoggedInVar(true);
  } catch (error) {
    console.error(error);
    isLoggedInVar(false);
  }
};
