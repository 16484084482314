import React, { CSSProperties } from "react";

import styled from "styled-components";

const Container = styled.div<{ flexDirection: string }>`
  display: flex;
  flex-direction: ${({ flexDirection }): string => flexDirection};
  height: 100%;
`;

const FlexContainer = ({
  children,
  flexDirection = "column",
  style = {},
}: {
  children: React.ReactNode;
  flexDirection?: string;
  style?: CSSProperties;
}): JSX.Element => {
  return (
    <Container style={style} flexDirection={flexDirection}>
      {children}
    </Container>
  );
};

export default React.memo(FlexContainer);
