import React from "react";

import Empty, { EmptyProps } from "antd/lib/empty";
import hoistNonReactStatics from "hoist-non-react-statics";

const EmptyWrapper = (props: EmptyProps): JSX.Element => {
  return <Empty {...props} />;
};

export default hoistNonReactStatics(EmptyWrapper, Empty);
