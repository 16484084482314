import { InMemoryCache, makeVar } from "@apollo/client";

import { AWS_LOCAL_STORAGE_PREFIX } from "constants/index";

const prefix = localStorage.getItem(AWS_LOCAL_STORAGE_PREFIX);

export const isLoggedInVar = makeVar(Boolean(localStorage.getItem(`${prefix}.accessToken`)));
export const storedVenueId = makeVar<string | null>(null);
export const storedOrganizationId = makeVar<string | null>(null);
export const storedTloId = makeVar<string | null>(null);

const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn: {
          read(): boolean {
            return isLoggedInVar();
          },
        },
        storedVenueId: {
          read(): string | null {
            return storedVenueId();
          },
        },
        storedOrganizationId: {
          read(): string | null {
            return storedOrganizationId();
          },
        },
        storedTloId: {
          read(): string | null {
            return storedTloId();
          },
        },
      },
    },
  },
});

export default cache;
