import React from "react";
import { useParams } from "react-router-dom";

import { useConfigurationQuery, useGetVenueQuery } from "generated/schemaTypes";

import CreateResources from "components/Resource/Modify/CreateResources";
import BgContainer from "components/atoms/BgContainer";
import StatusHandler from "components/atoms/StatusHandler";

const CreateResourceView = (): JSX.Element => {
  const { venueId, tloId } = useParams<{ venueId: string; tloId: string }>();

  const getVenueQuery = useGetVenueQuery({
    variables: { id: venueId, tlo: tloId },
  });

  const configurationQuery = useConfigurationQuery({
    variables: { countryCode: "SE" },
  });

  return (
    <BgContainer>
      <StatusHandler
        dataQueries={[
          { query: getVenueQuery, nonNullData: getVenueQuery.data?.venue },
          { query: configurationQuery, nonNullData: configurationQuery.data?.configuration },
        ]}>
        {([venue, configuration]): React.ReactElement => (
          <>
            {venue && (
              <CreateResources
                parent={venue}
                resourceConfigs={configuration.resourceConfiguration ?? []}
              />
            )}
          </>
        )}
      </StatusHandler>
    </BgContainer>
  );
};

export default CreateResourceView;
