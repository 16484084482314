import React from "react";

import OrganizationAndVenueProvider from "components/OrganizationAndVenueProvider";
import ListVenuesByOrganization from "components/Venue/Get/ListVenuesByOrganization";
import BgContainer from "components/atoms/BgContainer";
import ConfirmModal from "components/atoms/ConfirmModal";
import Spacer from "components/atoms/Spacer";
import { Popconfirm } from "components/atoms/antD";
import Button from "components/atoms/antD/Button";
import message from "components/atoms/antD/Message";

const Demo = (): JSX.Element => {
  return (
    <BgContainer>
      <Popconfirm
        title={"confirm"}
        onConfirm={(): void => {
          message.info({
            content:
              "popconfirm confirmedaaaaaaaddhfkjsdfhsdkfhksdfhkjdsfhjkdshfsjkdfhjksdfhkjsdhfksjfh",
          });
        }}>
        <Button type={"primary"}>Popconfirm</Button>
      </Popconfirm>
      <Spacer direction="horizontal" size="md" />
      <ConfirmModal
        title="Delete Customer"
        message={"Message"}
        okText="Confirm"
        okColor="danger"
        onConfirm={(): void => {
          console.log("deleteModal confirmed");
        }}>
        <Button
          type="primary"
          onClick={(): void => {
            message.success("The thing is gone!");
          }}>
          DeleteCustomer
        </Button>
      </ConfirmModal>
      <OrganizationAndVenueProvider>
        {({ organization }): React.ReactElement => {
          return <ListVenuesByOrganization organization={organization} />;
        }}
      </OrganizationAndVenueProvider>
    </BgContainer>
  );
};

export default Demo;
