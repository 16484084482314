import { useQuery } from "@apollo/client";

import { isLoggedInVar } from "apollo/cache";
import { IS_LOGGED_IN } from "apollo/queries";
import { Auth } from "aws-amplify";

export const useIsLoggedIn = (): boolean => {
  const {
    data: { isLoggedIn },
  } = useQuery(IS_LOGGED_IN);

  return isLoggedIn;
};

export const useLogout = (): any => {
  return async (): Promise<void> => {
    try {
      await Auth.signOut();
      isLoggedInVar(false);
    } catch (error) {
      console.error("error signing out: ", error);
    }
  };
};
