import React from "react";

import {
  useUpdateVenueMutation,
  useDeleteVenueMutation,
  VenueInput,
  Venue,
} from "generated/schemaTypes";

import CreateOrEditVenueForm from "components/Venue/Modify/CreateOrEditVenueForm";
import ErrorMessage from "components/atoms/ErrorMessage";
import Button from "components/atoms/antD/Button";
import message from "components/atoms/antD/Message";
import Popconfirm from "components/atoms/antD/Popconfirm";

import { convertValuesToVenueInput, VenueFormValues } from "helpers/converters/venue";
import { deleteVenueInCache, updateVenueInCache } from "services/venueServices";

const EditVenue = ({ venue }: { venue: Venue }): JSX.Element => {
  const [updateVenue, { loading, error }] = useUpdateVenueMutation({
    update: updateVenueInCache,
    onCompleted: () => {
      message.success("Venue saved");
    },
  });

  if (!venue) {
    return <ErrorMessage error={{ message: "No organization" }} />;
  }

  const onSubmit = (values: VenueFormValues): void => {
    const venueInput: VenueInput = convertValuesToVenueInput(values, venue);

    updateVenue({ variables: { venue: venueInput } });
  };

  return (
    <>
      {error ? (
        <ErrorMessage error={error} />
      ) : (
        <CreateOrEditVenueForm
          onFinish={onSubmit}
          submitLoading={loading}
          venue={venue}
          extraAction={<DeleteVenueButton id={venue.id} tlo={venue.tlo} />}
        />
      )}
    </>
  );
};

export default EditVenue;

const DeleteVenueButton = ({ id, tlo }: { id: string; tlo: string }): JSX.Element => {
  const [deleteVenue, { loading }] = useDeleteVenueMutation({
    update: deleteVenueInCache,
    onError: (error) => {
      <ErrorMessage error={error} />;
    },
  });

  const deleteAction = (e?: React.MouseEvent): void => {
    deleteVenue({ variables: { tlo: tlo, id: id } });
  };

  return (
    <Popconfirm title="Are you sure you want to delete?" onConfirm={deleteAction}>
      <Button color="danger" loading={loading}>
        Delete
      </Button>
    </Popconfirm>
  );
};
