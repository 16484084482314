import React from "react";

import AntDButton, { ButtonProps } from "antd/lib/button/button";
import colors from "config/style";
import styled, { css } from "styled-components";

const greenColor = colors.green.c5;
const greenHoverColor = colors.green.c4;
const greenActiveColor = colors.green.c6;

const dangerColor = colors.salmon.c5;
const dangerHoverColor = colors.salmon.c4;
const dangerActiveColor = colors.salmon.c6;

const whiteColor = colors.white;

const ghostHoverBgColor = colors.whiteO.o1;
const ghostActiveBgColor = colors.whiteO.o2;

const darkTextColor = colors.grey.c9;
const lightTextColor = colors.white;

const lightBorderColor = colors.grey.c5;

const disabledBgColor = colors.grey.c2;
const disabledColor = colors.grey.c6;

export type ButtonColor = "green" | "white" | "danger";

type NewButtonProps = Omit<Omit<ButtonProps, "ghost">, "danger"> & {
  color?: ButtonColor;
  type?: "primary" | "default" | "link" | "text" | "ghost";
  iconPos?: "left" | "right";
  shadow?: boolean;
  loading?: boolean;
};

export const Button = React.forwardRef<HTMLButtonElement, Omit<NewButtonProps, "ref">>(
  ({ ...props }, forwardedRef) => {
    let { icon, ...newProps } = { ...props };
    const defaultProps: NewButtonProps = {
      size: "middle",
      type: "default",
      iconPos: "right",
      color: newProps?.type === "primary" ? "green" : "white",
      disabled: false,
    };
    icon = icon ? <span className="button-icon">{icon}</span> : null;
    const mergedProps = Object.assign(defaultProps, newProps);
    const { size, iconPos, type, loading, color, ...rest } = mergedProps;
    return (
      <StyledButton
        $size={size}
        $iconPos={iconPos}
        $type={type}
        loading={loading}
        $color={color}
        {...rest}
        icon={icon}>
        {mergedProps.children}
      </StyledButton>
    );
  },
);

export type { ButtonProps };
export default Button;

export const StyledButton = styled(AntDButton)<{
  $color?: string;
  $type?: string;
  $size?: string;
  loading?: boolean;
  $iconPos?: string;
}>`
  && {
    border-radius: 5px;
    text-shadow: none;
    box-shadow: none;
    transition-property: all;
    transition-duration: 100ms;
    border: 2px solid transparent;
    color: ${darkTextColor};
    display: inline-flex;
    align-items: center;
    justify-content: center;

    ${({ $color, $type, $size, loading, $iconPos }): any =>
      css`
        ${($size === "small" &&
          css`
            padding: 0 11px;
            height: 34px;
            font-size: 12px;
            line-height: 20px;

            &.ant-btn-icon-only {
              width: 34px;
              padding: 0;
            }

            ${$type !== "text" &&
            $type !== "link" &&
            css`
              & > * {
                font-weight: 600;
                margin: 0 2px;
              }
            `}
          `) ||
        ($size === "middle" &&
          css`
            padding: 0 10px;
            height: 40px;
            font-size: 14px;
            line-height: 30px;

            &.ant-btn-icon-only {
              width: 40px;
              padding: 0;
            }

            ${$type !== "text" &&
            $type !== "link" &&
            css`
              & > * {
                font-weight: 600;
                margin: 0 5px;
              }
            `}
          `) ||
        ($size === "large" &&
          css`
            padding: 0 15px;
            height: 50px;
            font-size: 16px;
            line-height: 40px;

            &.ant-btn-icon-only {
              width: 50px;
              padding: 0;
            }

            ${$type !== "text" &&
            $type !== "link" &&
            css`
              & > * {
                font-weight: 700;
                margin: 0 10px;
              }
            `}
          `)}

        ${($type === "primary" &&
          css`
            border-color: transparent;
            ${($color === "green" &&
              css`
                color: ${lightTextColor};
                background: ${greenColor};

                &:hover {
                  background: ${greenHoverColor};
                }

                &:active {
                  background: ${greenActiveColor};
                }
              `) ||
            ($color === "white" &&
              css`
                color: ${darkTextColor};
                background: ${whiteColor};

                &:hover {
                  border-color: ${lightBorderColor};
                }
              `) ||
            ($color === "danger" &&
              css`
                color: ${lightTextColor};
                background: ${dangerColor};

                &:hover {
                  background: ${dangerHoverColor};
                }

                &:active {
                  background: ${dangerActiveColor};
                }
              `)}
          `) ||
        ($type === "default" &&
          css`
            background: transparent;
            border-color: ${lightBorderColor};
            ${($color === "green" &&
              css`
                border-color: ${greenColor};
                color: ${greenColor};

                &:hover {
                  color: ${greenHoverColor};
                  border-color: ${greenHoverColor};
                }
                &:active {
                  color: ${greenActiveColor};
                  border-color: ${greenActiveColor};
                }
              `) ||
            ($color === "white" &&
              css`
                &:hover {
                  color: ${greenColor};
                  border-color: ${greenColor};
                }
                &:active {
                  color: ${greenActiveColor};
                  border-color: ${greenActiveColor};
                }
              `) ||
            ($color === "danger" &&
              css`
                border-color: ${dangerColor};
                color: ${dangerColor};

                &:hover {
                  color: ${dangerHoverColor};
                  border-color: ${dangerHoverColor};
                }

                &:active {
                  color: ${dangerActiveColor};
                  border-color: ${dangerActiveColor};
                }
              `)}
          `) ||
        ($type === "ghost" &&
          css`
            ${($color === "green" &&
              css`
                border-color: ${greenColor};
                color: ${greenColor};
              `) ||
            ($color === "white" &&
              css`
                border-color: ${whiteColor};
                color: ${whiteColor};
              `) ||
            ($color === "danger" &&
              css`
                border-color: ${dangerColor};
                color: ${dangerColor};
              `)}
            &:hover {
              background: ${ghostHoverBgColor};
            }

            &:active {
              background: ${ghostActiveBgColor};
            }

            &[disabled] {
              background: transparent;
              color: ${whiteColor};
              border-color: ${whiteColor};
              opacity: 0.3;
            }
          `) ||
        (($type === "link" || $type === "text") &&
          css`
            background: none;
            padding: 0;
            height: auto;
            border: none;
            box-shadow: 1px #000;
          `)}

      ${$type !== "ghost" &&
        css`
          &[disabled] {
            &,
            &:hover,
            &:focus,
            &:active {
              color: ${disabledColor};
              background: ${disabledBgColor};
              border-color: transparent;
            }
          }
        `}

        ${loading &&
        css`
          &:before {
            display: none;
          }
        `}

        .ant-btn-loading-icon,
        .button-icon {
          .anticon {
            padding: 0;
          }
        }

        ${$iconPos === "right" &&
        css`
          .ant-btn-loading-icon,
          .button-icon {
          }
        `}
      `}
  }
`;
