import styled from "styled-components";

// const ResponsiveContainer = styled.div`
//   & {
//     margin: 0 auto;
//     width: 100%;

//     @media screen and (min-width: ${breakpoints.xs}px) {
//       width: ${responsiveWidths.xs}px;
//     }

//     @media screen and (min-width: ${breakpoints.md}px) {
//       width: ${responsiveWidths.md}px;
//     }

//     @media screen and (min-width: ${breakpoints.lg}px) {
//       width: ${responsiveWidths.lg}px;
//     }

//     @media screen and (min-width: ${breakpoints.xl}px) {
//       width: ${responsiveWidths.xl}px;
//     }
//   }
// `;

const ResponsiveContainer = styled.div`
  & {
    margin: 0 auto;
    flex-grow: 1;
  }
`;

export default ResponsiveContainer;
