import { Customer } from "generated/schemaTypes";

import UpdateCustomerButton from "components/Customer/Modify/UpdateCustomerButton";
import { filterOutObjectsByStatus } from "components/Schedule/helpers";
import ListTable from "components/atoms/ListTable";
import Checkbox from "components/atoms/antD/Checkbox";
import Empty from "components/atoms/antD/Empty";

import { getFirst } from "helpers/Array";

const ListCustomers = ({
  customers,
  emptyListDescription,
  selectedCheckboxes,
  setSelectedCheckboxes,
}: {
  customers?: Customer[];
  emptyListDescription: string;
  selectedCheckboxes: React.Key[];
  setSelectedCheckboxes: (value: React.Key[]) => void;
}): JSX.Element => {
  let activeCustomers = customers ? filterOutObjectsByStatus(customers, "DELETED") : [];

  return (
    <>
      <ListTable<Customer>
        rowSelection={{
          selectedRowKeys: selectedCheckboxes,
          type: "checkbox",
          onChange: (selectedRowKeys: React.Key[], selectedRows: Customer[]): void => {
            setSelectedCheckboxes(selectedRowKeys);
          },
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              imageStyle={{
                height: 60,
              }}
              description={emptyListDescription}
            />
          ),
        }}
        columns={[
          {
            title: "Name",
            key: "name",
            fixed: "left",
            render: (checked, record): string => {
              return `${record.givenName} ${record.familyName}`;
            },
          },
          {
            title: "Customer number",
            dataIndex: "customerNumber",
          },
          {
            title: "Email",
            dataIndex: "email",
          },
          {
            title: "Mobile",
            render: (checked, record): string => {
              return `${getFirst(record?.telephoneNumbers)?.number ?? ""}`;
            },
          },
          {
            render: (checked, record): any => {
              return <UpdateCustomerButton customer={record} />;
            },
          },
        ]}
        rowKey="id"
        dataSource={activeCustomers}>
        <Checkbox />
      </ListTable>
    </>
  );
};

export default ListCustomers;
