import React from "react";

import AntDCheckbox, { CheckboxProps } from "antd/lib/checkbox/Checkbox";
import hoistNonReactStatics from "hoist-non-react-statics";

export const CheckboxWrapper = (props: CheckboxProps): JSX.Element => {
  return <AntDCheckbox {...props} />;
};

export type { CheckboxProps };
export default hoistNonReactStatics(CheckboxWrapper, AntDCheckbox);
