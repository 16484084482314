import React from "react";

import EditBooking from "components/Booking/Modify/BookingDetailsModal";
import { StyledDetails, StyledDetailsContainer } from "components/Schedule/styles";
import ListTable from "components/atoms/ListTable";
import Button from "components/atoms/antD/Button";
import Empty from "components/atoms/antD/Empty";

import { faEye } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BookingsListTable = ({
  bookings,
  resources,
  emptyListDescription,
}: {
  bookings: any[];
  resources: any[];
  emptyListDescription: string;
}): JSX.Element => {
  return (
    <>
      <StyledDetailsContainer>
        <StyledDetails>
          <ListTable
            locale={{
              emptyText: (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyListDescription} />
              ),
            }}
            size="small"
            scroll={{ x: "max-content" }}
            columns={[
              {
                title: "Time",
                key: "time",
                fixed: "left",
                render: (checked, record): React.ReactElement => {
                  const start = new Date(record.startTime);
                  const end = new Date(record.endTime);
                  return (
                    <>
                      {start.toLocaleTimeString(undefined, {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}{" "}
                      -{" "}
                      {end.toLocaleTimeString(undefined, {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </>
                  );
                },
              },
              {
                title: "Player(s)",
                dataIndex: "players",
              },
              {
                title: "Resource",
                render: (checked, record): React.ReactElement => {
                  const currentResource = resources.find(
                    (resource) => resource.id === record.resources[0],
                  );
                  return currentResource ? currentResource.name : record.resources[0];
                },
              },
              {
                title: "Type",
                dataIndex: "type",
              },
              {
                title: "Comment",
                dataIndex: "reason",
              },
              {
                title: "Status",
                dataIndex: "status",
              },
              {
                title: "Details",
                render: (checked, record): React.ReactElement => {
                  return (
                    <EditBooking booking={record}>
                      {({ openModal }): React.ReactElement => {
                        return (
                          <>
                            <Button
                              title="Edit booking"
                              type="link"
                              onClick={(e): void => {
                                openModal();
                              }}>
                              <FontAwesomeIcon icon={faEye} size={"lg"} />
                            </Button>
                          </>
                        );
                      }}
                    </EditBooking>
                  );
                },
              },
            ]}
            dataSource={bookings}
          />
        </StyledDetails>
      </StyledDetailsContainer>
    </>
  );
};

export default BookingsListTable;
