import { Link, useHistory } from "react-router-dom";

import {
  ScheduleRule,
  ScheduleRuleInput,
  useDeleteScheduleRuleMutation,
  useResourcesFromVenueQuery,
  useUpdateScheduleRuleMutation,
  Venue,
} from "generated/schemaTypes";

import OrganizationAndVenueProvider, {
  withOrganizationAndVenue,
} from "components/OrganizationAndVenueProvider";
import ScheduleRuleForm from "components/ScheduleRule/Modify/ScheduleRuleForm";
import { ScheduleRuleFormValues } from "components/ScheduleRule/Modify/ScheduleRuleFormItems";
import BgContainer from "components/atoms/BgContainer";
import ErrorMessage from "components/atoms/ErrorMessage";
import StatusHandler from "components/atoms/StatusHandler";
import Button from "components/atoms/antD/Button";
import Popconfirm from "components/atoms/antD/Popconfirm";

import { convertValuesToScheduleRuleInput } from "helpers/converters/scheduleRule";
import routes from "routes/routes";
import {
  deleteScheduleRuleInCache,
  updateScheduleRuleInCache,
} from "services/ScheduleRuleServices";

const EditScheduleRule = withOrganizationAndVenue(
  ({ scheduleRule, venue }: { scheduleRule: ScheduleRule; venue: Venue }): React.ReactElement => {
    const history = useHistory();
    const resourcesFromVenueQuery = useResourcesFromVenueQuery({
      variables: {
        id: venue.id, // Waiting for reference like scheduleRule.venue or similar
        tlo: scheduleRule.tlo,
      },
    });

    const [updateScheduleRule, { loading, error }] = useUpdateScheduleRuleMutation({
      update: updateScheduleRuleInCache,
      onCompleted: (): void => {
        history.push(
          routes.scheduleRule.index.path({
            tloId: scheduleRule.tlo,
            venueId: venue.id,
            tabKey: "rules",
          }),
        );
      },
    });

    const onSubmit = (values: ScheduleRuleFormValues): void => {
      const scheduleRuleInput: ScheduleRuleInput = convertValuesToScheduleRuleInput(
        values,
        scheduleRule.tlo,
        scheduleRule.id,
      );

      updateScheduleRule({ variables: { updateScheduleRule: scheduleRuleInput } });
    };

    return (
      <>
        {error ? (
          <>Error</>
        ) : (
          <BgContainer>
            <OrganizationAndVenueProvider>
              {({ venue }): React.ReactElement => (
                <StatusHandler
                  dataQueries={[
                    {
                      query: resourcesFromVenueQuery,
                      nonNullData: resourcesFromVenueQuery?.data?.venue?.resources,
                    },
                  ]}>
                  {([resources]): React.ReactElement => (
                    <>
                      <h2>Edit "{scheduleRule.name}"</h2>
                      <Link
                        to={routes.scheduleRule.index.path({
                          tloId: venue.tlo,
                          venueId: venue.id,
                          tabKey: "rules",
                        })}>
                        &laquo; Back to schedule list
                      </Link>
                      <ScheduleRuleForm
                        onSubmit={onSubmit}
                        resources={resources}
                        scheduleRule={scheduleRule}
                        submitLoading={loading}
                      />
                    </>
                  )}
                </StatusHandler>
              )}
            </OrganizationAndVenueProvider>
          </BgContainer>
        )}
      </>
    );
  },
);

export default EditScheduleRule;

export const DeleteScheduleRule = ({ id, tlo }: { id: string; tlo: string }): JSX.Element => {
  const history = useHistory();
  const [deleteScheduleRule, { loading }] = useDeleteScheduleRuleMutation({
    update: deleteScheduleRuleInCache,
    onCompleted: (data) => {
      history.goBack();
    },
    onError: (error) => {
      <ErrorMessage error={error} />;
    },
  });

  const deleteAction = (e?: React.MouseEvent): void => {
    deleteScheduleRule({
      variables: {
        tlo: tlo,
        id: id,
      },
    });
  };

  return (
    <Popconfirm title="Are you sure you want to delete?" onConfirm={deleteAction}>
      <Button color="danger" loading={loading}>
        Delete
      </Button>
    </Popconfirm>
  );
};
