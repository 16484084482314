import IndexBookingView from "components/Booking/Views/IndexBookingView";

import dayjs from "dayjs";
import { BASE_ROUTE_PATH_BOOKING } from "routes/paths";

const route = {
  path: (params?: {
    tloId?: string;
    venueId?: string;
    date?: string;
    tabKey?: "overview" | "cancellations";
  }): string => {
    return (
      BASE_ROUTE_PATH_BOOKING +
      "/index/" +
      (params ? params.tloId : ":tloId") +
      "/" +
      (params ? params.venueId : ":venueId") +
      "/" +
      (params ? params.tabKey || "overview" : ":tabKey") +
      "/" +
      (params ? params.date || dayjs().format("YYYYMMDD") : ":date?")
    );
  },
  component: IndexBookingView,
};

export default route;
