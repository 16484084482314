import React from "react";

import { useBookingWithEventsQuery } from "generated/schemaTypes";

import { StyledDetails, StyledDetailsContainer } from "components/Schedule/styles";
import BgContainer from "components/atoms/BgContainer";
import ListTable from "components/atoms/ListTable";
import StatusHandler from "components/atoms/StatusHandler";

import dayjs from "dayjs";

const ListBookingEvents = ({ tlo, bookingId }: { tlo: string; bookingId: string }): JSX.Element => {
  const bookingWithEventsQuery = useBookingWithEventsQuery({
    variables: { tlo: tlo, id: bookingId },
  });

  return (
    <BgContainer>
      <StatusHandler
        dataQueries={[
          {
            query: bookingWithEventsQuery,
            nonNullData: bookingWithEventsQuery.data?.booking,
          },
        ]}>
        {([booking]): React.ReactElement => {
          const sortedBookingEvents = [...(booking.events ?? [])].sort(
            (a, b) => Date.parse(a.createdAt ?? "") - Date.parse(b.createdAt ?? ""),
          );

          return (
            <>
              <h3>Event log</h3>
              <StyledDetailsContainer>
                <StyledDetails>
                  <ListTable
                    size="small"
                    scroll={{ x: "max-content" }}
                    rowKey={"createdAt"}
                    columns={[
                      {
                        title: "Date",
                        key: "createdAt",
                        fixed: "left",
                        render: (checked, record): React.ReactElement => {
                          return <>{dayjs(record.createdAt).format("YYYY-MM-DD HH:mm")}</>;
                        },
                      },
                      {
                        title: "Status",
                        dataIndex: "status",
                      },
                      {
                        title: "By",
                        dataIndex: "createdBy",
                      },
                      {
                        title: "Reason",
                        dataIndex: "reason",
                      },
                    ]}
                    dataSource={sortedBookingEvents}
                  />
                </StyledDetails>
              </StyledDetailsContainer>
            </>
          );
        }}
      </StatusHandler>
    </BgContainer>
  );
};

export default ListBookingEvents;
