import React from "react";

import { Booking, Resource } from "generated/schemaTypes";

import SimpleFormItemFactory, {
  extendedFormItemProps,
  SimpleFormProps,
} from "components/SimpleFormItemFactory";
import DatePicker from "components/atoms/antD/DatePicker";
import Form from "components/atoms/antD/Form";
import FormItem from "components/atoms/antD/FormItem";
import Input, { TextArea } from "components/atoms/antD/Input";
import Select from "components/atoms/antD/Select";
import Switch from "components/atoms/antD/Switch";
import TimePicker from "components/atoms/antD/TimePicker";

import { padding } from "antDOverrideVars/layout";
import { FormInstance } from "antd";
import { Dayjs } from "dayjs";
import { BookingFormValues, convertBookingToFormValues } from "helpers/converters/booking";
import styled from "styled-components";

export const bookingTypesList: { [key: string]: string } = {
  booking: "Booking",
  activity: "Activity",
  training: "Training",
  competition: "Competition",
  subscription: "Subscription",
  notAvailable: "Not Available",
};

const cancelledBookingFormItemProps: SimpleFormProps = {
  bookingType: {
    label: "Booking Type",
    children: (
      <Select disabled style={{ width: "100%" }}>
        {Object.keys(bookingTypesList).map((value) => (
          <Select.Option value={value} key={value}>
            {bookingTypesList[value]}
          </Select.Option>
        ))}
      </Select>
    ),
  },
  date: (props) => {
    const form = props?.form as FormInstance | undefined;
    return {
      label: "Date",
      initialValue: form?.getFieldValue("startTime"),
      value: form?.getFieldValue("startTime"),
      children: (
        <DatePicker
          disabled
          onChange={(date): void => {
            updateFormTimePickerDate(form, date);
          }}
        />
      ),
    };
  },
  createdAt: {
    noStyle: true,
    children: <Input type="hidden" disabled />,
  },
  startTime: {
    label: "Time Start",
    children: <TimePicker format="HH:mm" disabled />,
  },
  endTime: {
    label: "Time End",
    children: <TimePicker format="HH:mm" disabled />,
  },
  repeat: {
    label: "Repeat",
    children: <Switch disabled />,
  },
  court: (props) => {
    const { resources } = props ?? {};
    return {
      label: "Court",
      children: (
        <Select disabled>
          {resources.map((resource: Resource) => {
            return (
              <Select.Option key={resource.id} value={resource.id}>
                {resource.name}
              </Select.Option>
            );
          })}
        </Select>
      ),
    };
  },
  price: {
    label: "Price",
    children: <Input disabled />,
  },
  comment: {
    label: "Comment",
    children: <TextArea disabled />,
  },
};

const bookingFormItemProps: SimpleFormProps = {
  bookingType: {
    label: "Booking Type",
    children: (
      <Select style={{ width: "100%" }}>
        {Object.keys(bookingTypesList).map((value) => (
          <Select.Option value={value} key={value}>
            {bookingTypesList[value]}
          </Select.Option>
        ))}
      </Select>
    ),
  },
  date: (props) => {
    const form = props?.form as FormInstance | undefined;
    return {
      label: "Date",
      children: (
        <DatePicker
          onChange={(date): void => {
            updateFormTimePickerDate(form, date);
          }}
        />
      ),
    };
  },
  createdAt: {
    noStyle: true,
    children: <Input type="hidden" />,
  },
  startTime: {
    label: "Time Start",
    children: <TimePicker format="HH:mm" />,
  },
  endTime: {
    label: "Time End",
    children: <TimePicker format="HH:mm" />,
  },
  repeat: {
    label: "Repeat",
    children: <Switch />,
  },
  court: (props) => {
    const { resources } = props ?? {};
    return {
      label: "Court",
      children: (
        <Select style={{ width: "100%" }}>
          {resources.map((resource: Resource) => {
            return (
              <Select.Option key={resource.id} value={resource.id}>
                {resource.name}
              </Select.Option>
            );
          })}
        </Select>
      ),
    };
  },
  price: {
    label: "Price",
    children: <Input disabled />,
  },
  comment: {
    label: "Comment",
    children: <TextArea />,
  },
};

const Table = styled.table`
  width: 100%;
`;

const Td = styled.td`
  padding-bottom: ${padding["@padding-md"]}px;
`;

const Tr = styled.tr``;

const Render = (props: extendedFormItemProps): React.ReactElement => {
  const { label, ...rest } = props;

  if (props?.noStyle) {
    return <FormItem noStyle {...rest} />;
  }
  return (
    <Tr>
      <Td>
        <label htmlFor={props.name?.toString() || ""}>{label}</label>
      </Td>
      <Td>
        <FormItem noStyle {...rest} />
      </Td>
    </Tr>
  );
};

const updateFormTimePickerDate = (
  form: FormInstance<BookingFormValues> | undefined,
  date: Dayjs | null,
): void => {
  if (date && form) {
    const { startTime, endTime } = form.getFieldsValue();

    const newStartTime = startTime
      .set("year", date.year())
      .set("month", date.month())
      .set("date", date.date());

    const newEndTime = endTime
      .set("year", date.year())
      .set("month", date.month())
      .set("date", date.date());

    form.setFieldsValue({ startTime: newStartTime, endTime: newEndTime.clone() });
  }
};

const BookingForm = ({
  form,
  booking,
  resources,
  cancelledBooking,
}: {
  form: FormInstance<BookingFormValues>;
  booking?: Booking;
  resources: Resource[];
  cancelledBooking: boolean;
}): React.ReactElement => {
  const initialValues = convertBookingToFormValues(booking, resources[0].id);
  const sortedResources = [...resources];
  resources = sortedResources.sort((a, b) => a.sortOrder - b.sortOrder);

  const BookingFormItem = cancelledBooking
    ? SimpleFormItemFactory(cancelledBookingFormItemProps, Render)
    : SimpleFormItemFactory(bookingFormItemProps, Render);

  return (
    <>
      <Form form={form} initialValues={convertBookingToFormValues(booking, resources[0].id)}>
        <BookingFormItem item="createdAt" props={{ noStyle: true }} />
        <Table>
          <tbody>
            <BookingFormItem item="bookingType" />
            <BookingFormItem item="date" props={{ form, startTime: initialValues.startTime }} />
            <Tr>
              <Td>
                <label htmlFor={"startTime"}>Time start:</label>
              </Td>
              <Td>
                <BookingFormItem
                  item="startTime"
                  CustomRender={(props): React.ReactElement => <FormItem {...props} noStyle />}
                />
                {" - "}
                <BookingFormItem
                  item="endTime"
                  CustomRender={(props): React.ReactElement => <FormItem {...props} noStyle />}
                />
              </Td>
            </Tr>
            {/* <BookingFormItem item="repeat" /> */}
            <BookingFormItem item="court" props={{ resources: resources }} />
            <FormItem noStyle shouldUpdate>
              {({ getFieldValue }): React.ReactNode => (
                <>
                  {getFieldValue("bookingType") === "booking" && (
                    <>
                      <BookingFormItem item="price" />
                    </>
                  )}
                </>
              )}
            </FormItem>
            <BookingFormItem item="comment" />
          </tbody>
        </Table>
      </Form>
    </>
  );
};

export default BookingForm;
