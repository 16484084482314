import AntDRadio, { RadioProps } from "antd/lib/radio";
import hoistNonReactStatics from "hoist-non-react-statics";
import styled from "styled-components";

const Radio = styled(AntDRadio)`
  & > span.ant-radio {
    flex-basis: auto;
  }
  & > span {
    flex-basis: 100%;
  }
`;

export type { RadioProps };

export default hoistNonReactStatics(Radio, AntDRadio);
