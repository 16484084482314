import React, { ReactElement, useEffect, useState } from "react";

import Button from "components/atoms/antD/Button";

import { Steps } from "antd";

export interface WizardStepProps {
  children?: React.ReactNode;
  label?: string;
}

export interface WizardInstance {
  wizardPosition: number;
  numOfSteps: number;
  stepToNext: () => void;
  stepToPrevious: () => void;
  setWizardPosition: (value: number) => void;
  setNumOfSteps: (value: number) => void;
}

export interface WizardProps {
  showNavigation?: boolean;
  showNavigationButtons?: boolean;
  renderHiddenSteps?: boolean;
}

export const WizardStep = (props: WizardStepProps): JSX.Element => {
  return <>{props.children}</>;
};

function Wizard({
  wizard,
  children,
  showNavigation = true,
  showNavigationButtons = true,
  renderHiddenSteps = false,
  NavigationContainer = (props): ReactElement => <>{props.children}</>,
}: {
  wizard: WizardInstance;
  children: (props: WizardInstance) => React.ReactElement[];
  NavigationContainer?: (props: { children: ReactElement }) => ReactElement;
} & WizardProps): JSX.Element {
  const steps = children(wizard);

  useEffect(() => {
    wizard.setNumOfSteps(steps.length);
  }, [wizard, steps.length]);

  return (
    <>
      {renderHiddenSteps &&
        steps.map((value, index) => {
          return (
            <div style={{ display: index === wizard.wizardPosition ? "block" : "none" }}>
              {value}
            </div>
          );
        })}
      {renderHiddenSteps === false &&
        steps.find((value, index) => {
          return index === wizard.wizardPosition;
        })}
      {showNavigation && (
        <NavigationContainer>
          <Steps size="small" current={wizard.wizardPosition}>
            {steps.map((step) => {
              return <Steps.Step key={step.props.label} />;
            })}
          </Steps>
        </NavigationContainer>
      )}
      {showNavigationButtons && (
        <>
          <Button onClick={(): void => wizard.stepToPrevious()}>Prev</Button>
          <Button onClick={(): void => wizard.stepToNext()}>Next</Button>
        </>
      )}
    </>
  );
}

export default Wizard;

export function useWizard(): WizardInstance {
  const [position, setPosition] = useState(0);
  const [numOfSteps, setNumOfSteps] = useState(0);

  const setPositionFn = (value: number): void => {
    setPosition(value);
  };

  const stepToPrevious = (): void => {
    setPositionFn(position < 1 ? 0 : position - 1);
  };

  const stepToNext = (): void => {
    setPositionFn(position >= numOfSteps - 1 ? numOfSteps - 1 : position + 1);
  };

  return {
    numOfSteps,
    wizardPosition: position,
    stepToPrevious,
    stepToNext,
    setWizardPosition: setPosition,
    setNumOfSteps: setNumOfSteps,
  };
}
