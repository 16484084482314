export const AWS_LOCAL_STORAGE_PREFIX = "AWS_LOCAL_STORAGE_PREFIX";

// Date
export const WEEK_DAYS: [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
] = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
