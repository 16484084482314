import {
  faTennisBall,
  faCalendarEdit,
  faSignIn,
  faSignOut,
  faCompass,
  faCalendarAlt,
  faThLarge,
  faGlobe,
  faUsers,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import routes, { Route } from "routes/routes";

const routesToPathArray = (routes: { [key: string]: Route }): string[] => {
  return Object.values(routes).map((route) => {
    return route.path();
  });
};

interface MenuItemBasic {
  title: string;
  routes: string[];
  icon: React.ReactNode;
  subMenuItems?: MenuItem[];
}

interface MenuItemWithUrl extends MenuItemBasic {
  url: string;
}

interface MenuItemWithClick extends MenuItemBasic {
  onClick: () => void;
}

export type MenuItem = MenuItemWithUrl | MenuItemWithClick;

export const loggedInMenu = (venueId: string, tloId: string, logout: () => void): MenuItem[] => {
  return [
    {
      title: "Dashboard",
      url: "/",
      routes: [],
      icon: <FontAwesomeIcon icon={faThLarge} />,
    },
    {
      title: "Bookings",
      url: routes.booking.overview.path({ tloId, venueId }),
      routes: routesToPathArray(routes.booking),
      icon: <FontAwesomeIcon icon={faCalendarAlt} />,
    },
    {
      title: "Resources",
      url: routes.resource.index.path({ tloId, venueId }),
      routes: routesToPathArray(routes.resource),
      icon: <FontAwesomeIcon icon={faTennisBall} />,
    },
    {
      title: "Customers",
      url: routes.customer.index.path({ tloId, venueId }),
      routes: routesToPathArray(routes.customer),
      icon: <FontAwesomeIcon icon={faUsers} />,
    },
    {
      title: "Schedule",
      url: routes.scheduleRule.index.path({ tloId, venueId }),
      routes: routesToPathArray(routes.scheduleRule),
      icon: <FontAwesomeIcon icon={faCalendarEdit} />,
      subMenuItems: [
        {
          title: "Organisation",
          url: routes.organization.index.path(),
          routes: routesToPathArray(routes.organization),
          icon: <FontAwesomeIcon icon={faGlobe} />,
        },
      ],
    },
    // {
    //   title: "Organisation",
    //   url: routes.organization.index.path(),
    //   routes: routesToPathArray(routes.organization),
    //   icon: <FontAwesomeIcon icon={faGlobe} />,
    // },
    {
      title: "Venue",
      url: routes.venue.edit.path({ tloId, venueId }),
      routes: routesToPathArray(routes.venue),
      icon: <FontAwesomeIcon icon={faCompass} />,
    },
    {
      title: "Log out",
      onClick: logout,
      routes: routesToPathArray(routes.venue),
      icon: <FontAwesomeIcon icon={faSignOut} />,
    },
    {
      title: "Demo",
      url: routes.demo.path(),
      routes: [],
      icon: <FontAwesomeIcon icon={faCompass} />,
    },
  ];
};

export const loggedOutMenu = (): MenuItem[] => {
  return [
    {
      title: "Log in",
      url: routes.authorization.login.path(),
      routes: routesToPathArray(routes.authorization),
      icon: <FontAwesomeIcon icon={faSignIn} />,
    },
  ];
};
