import CreateVenueView from "components/Venue/Views/CreateVenueView";

import { BASE_ROUTE_PATH_VENUE } from "routes/paths";

const route = {
  path: (params?: { tloId?: string; organizationId?: string }): string => {
    return (
      BASE_ROUTE_PATH_VENUE +
      "/create/" +
      (params ? params.tloId : ":tloId") +
      "/" +
      (params ? params.organizationId : ":organizationId")
    );
  },
  component: CreateVenueView,
};

export default route;
