import React from "react";

import FormItem, { FormItemProps } from "components/atoms/antD/FormItem";
import Input from "components/atoms/antD/Input";

export interface extendedFormItemProps extends FormItemProps {
  otherProps?: { [key: string]: any };
}

export interface SimpleFormProps {
  [key: string]:
    | extendedFormItemProps
    | ((props?: { [key: string]: any }) => extendedFormItemProps);
}

export interface SimpleFormItemProps {
  item: string;
  props?: { [key: string]: any };
  CustomRender?: (props: extendedFormItemProps) => React.ReactElement;
}

const SimpleFormItemFactory = (
  formProps: SimpleFormProps,
  Render?: (props: extendedFormItemProps) => React.ReactElement,
) => {
  return ({ item, props, CustomRender }: SimpleFormItemProps): JSX.Element => {
    let newProps = formProps[item];

    if (!newProps) {
      throw new Error("Could not find form property: " + item);
    }

    if (typeof newProps === "function") {
      newProps = newProps(props);
    }

    if (!newProps.name) {
      newProps.name = item;
    }

    if (!newProps.children) {
      newProps.children = <Input />;
    }

    const { otherProps, ...propsObj } = newProps;

    if (CustomRender) {
      return <CustomRender {...propsObj} />;
    } else if (Render) {
      return <Render {...propsObj} />;
    }
    return <FormItem {...propsObj} />;
  };
};

export default SimpleFormItemFactory;
