import { Booking, Boundaries, Resource, ScheduleRule } from "generated/schemaTypes";

import { Dayjs } from "dayjs";

export type Maybe<T> = T;

interface ScheduleObjectBooking extends ScheduleObject {
  origin: Booking;
}

interface ScheduleObjectScheduleRule extends ScheduleObject {
  origin: ScheduleRule;
}

export interface ScheduleObject {
  xStart: number;
  xEnd: number;
  width: number;
  origin: ScheduleRule | Booking;
}

export type ScheduleObject2 = ScheduleObjectBooking | ScheduleObjectScheduleRule;

export interface ScheduleResource {
  index?: number;
  y: number;
  yDetails: number;
  origin: Resource;
  scheduleObjects: ScheduleObject[];
}

export interface ItemProps {
  id?: string;
  title?: string;
}

export interface SharedResourcePlannerProps {
  resources: Resource[];
  scheduleObjectOrigins: ScheduleObjectOrigin[];
  day: Dayjs;
  boundaries?: Maybe<Boundaries>;
}

export interface BoundariesData {
  startTime: string;
  endTime: string;
}

export interface ResourcePlannerHookProps {
  data: SharedResourcePlannerProps;
  ref: any;
}

export interface OpeningInterval {
  from: string;
  to: string;
}

export interface AxisType {
  [key: string]: {
    time: Dayjs;
    x: number;
  };
}

export type ScheduleObjectOrigin = ScheduleRule | Booking;

export type DetailsType = ScheduleResource | undefined;

export function isScheduleRule(obj: ScheduleObjectOrigin): obj is ScheduleRule {
  return obj?.__typename === "ScheduleRule";
}

export function isBooking(obj: ScheduleObjectOrigin): obj is Booking {
  return obj?.__typename === "Booking";
}

export function isCancelledBooking(obj: ScheduleObjectOrigin): obj is Booking {
  return obj?.__typename === "Booking" && obj?.status === "CANCELLED";
}

export function isScheduleRules(arr: ScheduleObject[]): arr is ScheduleObjectScheduleRule[] {
  return arr
    .map((o) => o.origin)
    .every((object) => {
      return isScheduleRule(object);
    });
}

export function isBookings(arr: ScheduleObject[]): arr is ScheduleObjectBooking[] {
  return arr
    .map((o) => o.origin)
    .every((object) => {
      return isBooking(object);
    });
}
