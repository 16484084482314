import React from "react";

import AntDDropdown, { DropdownButtonProps } from "antd/lib/dropdown";
import hoistNonReactStatics from "hoist-non-react-statics";

const DropdownWrapper = (props: DropdownButtonProps): JSX.Element => {
  return <AntDDropdown {...props} />;
};

export default hoistNonReactStatics(DropdownWrapper, AntDDropdown);
