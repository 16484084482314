export function get<T>(arr: T[] | undefined, index: number): T | undefined {
  if (arr) return arr[index];
  else return undefined;
}

export function getFirst<T>(arr: T[] | undefined): T | undefined {
  return get(arr, 0);
}

export function numberRangeToArray(start: number, end: number): number[] {
  return Array(end - start + 1)
    .fill(undefined)
    .map((_, idx) => start + idx);
}
