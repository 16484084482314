import React from "react";

import { useBookingsQuery, useResourcesFromVenueQuery } from "generated/schemaTypes";

import EditBooking from "components/Booking/Modify/BookingDetailsModal";
import CreateBooking from "components/Booking/Modify/CreateBooking";
import { filterOutObjectsByStatus, filterUpcomingBookings } from "components/Schedule/helpers";
import { StyledDetails, StyledDetailsContainer } from "components/Schedule/styles";
import ListTable from "components/atoms/ListTable";
import StatusHandler from "components/atoms/StatusHandler";
import Button from "components/atoms/antD/Button";

import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { formatDateForAPI } from "helpers/date";

const TodaysUpcomingBookings = ({
  tlo,
  venueId,
}: {
  tlo: string;
  venueId: string;
}): JSX.Element => {
  const bookingsQuery = useBookingsQuery({
    variables: { tlo: tlo, venue: venueId, date: formatDateForAPI(dayjs()) },
  });

  const resourcesFromVenueQuery = useResourcesFromVenueQuery({
    variables: { tlo: tlo, id: venueId },
  });

  return (
    <StatusHandler
      dataQueries={[
        {
          query: bookingsQuery,
          nonNullData: bookingsQuery.data?.bookings,
        },
        {
          query: resourcesFromVenueQuery,
          nonNullData: resourcesFromVenueQuery.data?.venue,
        },
      ]}>
      {([bookingsData, venueWithResources]): React.ReactElement => {
        let bookings = bookingsData?.bookings ?? [];
        let activeBookings = filterOutObjectsByStatus(bookings, "CANCELLED");
        bookings = [...bookings].sort((a, b) => Date.parse(a.startTime) - Date.parse(b.startTime));

        let upcomingBookings = filterUpcomingBookings(bookings);

        return (
          <>
            <h3>
              {activeBookings.length
                ? "You've got " +
                  activeBookings.length +
                  " booking" +
                  (activeBookings.length > 1 ? "s" : "") +
                  " today"
                : "No bookings today :("}
            </h3>
            <CreateBooking>
              {({ openModal }): React.ReactElement => {
                return (
                  <>
                    <Button
                      type="primary"
                      onClick={(e): void => {
                        openModal();
                      }}>
                      Add booking
                    </Button>
                  </>
                );
              }}
            </CreateBooking>
            <h3>Current/upcoming bookings</h3>
            <p>(Bookings starting within the last hour and upcoming.)</p>
            <StyledDetailsContainer>
              <StyledDetails>
                <ListTable
                  size="small"
                  scroll={{ x: "max-content" }}
                  columns={[
                    {
                      title: "Time",
                      key: "time",
                      fixed: "left",
                      render: (checked, record): React.ReactElement => {
                        const start = new Date(record.startTime);
                        const end = new Date(record.endTime);
                        return (
                          <>
                            {start.toLocaleTimeString(undefined, {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}{" "}
                            -{" "}
                            {end.toLocaleTimeString(undefined, {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </>
                        );
                      },
                    },
                    {
                      title: "Resource",
                      render: (checked, record): React.ReactElement => {
                        const currentResource: any = (venueWithResources?.resources ?? []).find(
                          (resource) => resource.id === record.resources[0],
                        );
                        return currentResource ? currentResource.name : record.resources[0];
                      },
                    },
                    {
                      title: "Type",
                      dataIndex: "type",
                    },
                    {
                      title: "Edit",
                      render: (checked, record): React.ReactElement => {
                        return (
                          <EditBooking booking={record}>
                            {({ openModal }): React.ReactElement => {
                              return (
                                <>
                                  <Button
                                    title="Edit booking"
                                    type="link"
                                    onClick={(e): void => {
                                      openModal();
                                    }}>
                                    <FontAwesomeIcon icon={faEdit} />
                                  </Button>
                                </>
                              );
                            }}
                          </EditBooking>
                        );
                      },
                    },
                  ]}
                  dataSource={upcomingBookings}
                />
              </StyledDetails>
            </StyledDetailsContainer>
          </>
        );
      }}
    </StatusHandler>
  );
};

export default TodaysUpcomingBookings;
