import Button from "components/atoms/antD/Button";

import styled from "styled-components";

export const CollapseButton = styled(Button)`
  && {
    border-radius: 0;
    border-bottom: 1px solid #eee;
    width: 100%;
    text-align: right;
    color: #aaa;
    padding: 10px;
    height: auto;
    justify-content: flex-end;

    &:hover,
    &.active,
    &:focus {
      color: #aaa;
      background: none;
      border-color: #eee;
    }

    &[ant-click-animating-without-extra-node]:after {
      animation: 0s !important;
    }

    & > svg {
      width: 30px;
    }
  }
`;
