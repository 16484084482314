import React from "react";
import { useParams } from "react-router-dom";

import { useGetOrganizationQuery } from "generated/schemaTypes";

import ShowOrganizationWithVenues from "components/Organization/Get/ShowOrganizationWithVenues";
import BgContainer from "components/atoms/BgContainer";
import StatusHandler from "components/atoms/StatusHandler";

const ShowOrganizationView = (): JSX.Element => {
  const { organizationId, tloId } = useParams<{ organizationId: string; tloId: string }>();

  const getOrganizationQuery = useGetOrganizationQuery({
    variables: { id: organizationId, tlo: tloId },
  });

  return (
    <BgContainer>
      <StatusHandler
        dataQueries={[
          { query: getOrganizationQuery, nonNullData: getOrganizationQuery?.data?.organization },
        ]}>
        {([organization]): React.ReactElement => (
          <ShowOrganizationWithVenues organization={organization} />
        )}
      </StatusHandler>
    </BgContainer>
  );
};

export default ShowOrganizationView;
