import { DocumentNode } from "@apollo/client";
import { Reference, ApolloCache } from "@apollo/client/cache";
import { ReadFieldFunction } from "@apollo/client/cache/core/types/common";

export const extendListInCache = <T, U>(
  objectArray: any[],
  object: (U & { id?: any }) | undefined,
  fragment: DocumentNode,
  cache: ApolloCache<T>,
  readField: ReadFieldFunction,
): U[] => {
  const newObjectRef = cache.writeFragment({
    data: object,
    fragment: fragment,
  });

  if (objectArray.some((ref: Reference) => readField("id", ref) === object?.id)) {
    return objectArray;
  }

  return [...objectArray, newObjectRef];
};
