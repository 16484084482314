import React from "react";

import { Customer } from "generated/schemaTypes";

import SimpleFormItemFactory, {
  extendedFormItemProps,
  SimpleFormProps,
} from "components/SimpleFormItemFactory";
import Form from "components/atoms/antD/Form";
import FormItem from "components/atoms/antD/FormItem";
import Input from "components/atoms/antD/Input";

import { Col, FormInstance, Row } from "antd";
import { convertCustomerToValues, CustomerFormValues } from "helpers/converters/customer";

const customerFormItemProps: SimpleFormProps = {
  givenName: {
    label: "Given name",
  },
  familyName: {
    label: "Family name",
  },
  email: {
    label: "Email",
    children: <Input type="email" />,
  },
  telephoneNumber: {
    label: "Phone number",
    children: <Input type="tel" />,
  },
};

const Render = (props: extendedFormItemProps): React.ReactElement => {
  const { label, ...rest } = props;

  if (props?.noStyle) {
    return <FormItem noStyle {...rest} />;
  }
  return (
    <Row style={{ marginBottom: "20px" }} gutter={16}>
      <Col style={{ alignSelf: "center", textAlign: "end" }} span={6}>
        <label htmlFor={props.name?.toString() || ""}>{label}</label>
      </Col>
      <Col flex="auto">
        <FormItem noStyle {...rest} />
      </Col>
    </Row>
  );
};

const CustomerForm = ({
  form,
  customer,
}: {
  form: FormInstance<CustomerFormValues>;
  customer?: Customer;
}): React.ReactElement => {
  const CustomerFormItem = SimpleFormItemFactory(customerFormItemProps, Render);

  return (
    <>
      <Form form={form} initialValues={convertCustomerToValues(customer)}>
        <CustomerFormItem item="givenName" />
        <CustomerFormItem item="familyName" />
        <CustomerFormItem item="email" />
        <CustomerFormItem item="telephoneNumber" />
      </Form>
    </>
  );
};

export default CustomerForm;
