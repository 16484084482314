import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { useCustomersFromVenueQuery } from "generated/schemaTypes";

import ListCustomers from "components/Customer/Get/ListCustomers";
import CreateCustomerButton from "components/Customer/Modify/CreateCustomerButton";
import DeleteCustomersButton from "components/Customer/Modify/DeleteCustomersButton";
import BgContainer from "components/atoms/BgContainer";
import PageHeadline from "components/atoms/PageHeadline";
import Spacer from "components/atoms/Spacer";
import StatusHandler from "components/atoms/StatusHandler";

const IndexCustomersView = (): JSX.Element => {
  const { venueId, tloId } = useParams<{ venueId: string; tloId: string }>();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<React.Key[]>([]);

  const resetSelectedCheckboxes = (): void => {
    setSelectedCheckboxes([]);
  };

  const customersFromVenueQuery = useCustomersFromVenueQuery({
    variables: {
      id: venueId,
      tlo: tloId,
    },
  });

  return (
    <>
      <BgContainer>
        <StatusHandler
          dataQueries={[
            { query: customersFromVenueQuery, nonNullData: customersFromVenueQuery.data?.venue },
          ]}>
          {([venue]): React.ReactElement => (
            <>
              <PageHeadline title="Customers" />
              <DeleteCustomersButton
                selectedCheckboxes={selectedCheckboxes}
                resetSelectedCheckboxes={resetSelectedCheckboxes}
                tloId={tloId}
              />
              <Spacer direction="horizontal" size={"md"} />
              <CreateCustomerButton parent={venue} />
              <Spacer direction="vertical" size={"md"} />
              <ListCustomers
                selectedCheckboxes={selectedCheckboxes}
                setSelectedCheckboxes={setSelectedCheckboxes}
                customers={venue.customers ?? []}
                emptyListDescription={"No customers added"}
              />
            </>
          )}
        </StatusHandler>
      </BgContainer>
    </>
  );
};

export default IndexCustomersView;
