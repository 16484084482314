import { Link } from "react-router-dom";

import { Venue } from "generated/schemaTypes";

import OrganizationAndVenueProvider from "components/OrganizationAndVenueProvider";
import Layout from "components/atoms/antD/Layout";

import MatchiWhiteLogo from "images/Matchi_white.svg";
import routes from "routes/routes";
import styled from "styled-components";

const { Header } = Layout;

const FlexHeader = styled(Header)`
  display: flex;
`;
const VenueHeadline = styled.h1`
  font-family: Barlow;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  padding-left: 24px;
  margin: 0px;
`;

const ShowVenueHeader = ({ venue }: { venue?: Venue }): JSX.Element => {
  if (!venue?.id) {
    return <></>;
  }

  return <VenueHeadline>{venue?.name}</VenueHeadline>;
};

const AppHeader = (): React.ReactElement => {
  return (
    <FlexHeader style={{ height: "60px" }}>
      <div style={{ width: 200, textAlign: "center" }}>
        <Link to={routes.home.path()}>
          <img src={MatchiWhiteLogo} alt="Matchi Logo" style={{ height: "25px" }} />
        </Link>
      </div>
      <OrganizationAndVenueProvider>
        {({ organization, venue }): React.ReactElement => {
          return <ShowVenueHeader venue={venue} />;
        }}
      </OrganizationAndVenueProvider>
    </FlexHeader>
  );
};

export default AppHeader;
