import React from "react";
import { Link } from "react-router-dom";

import { Button } from "components/atoms/antD";
import Table, { ColumnsType, ExtendedTableProps } from "components/atoms/antD/Table";

import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SortOrder } from "antd/lib/table/interface";
import { getFirst } from "helpers/Array";

interface EasyColumns {
  [key: string]: string | JSX.Element;
}
interface SortByColumns {
  [key: string]: SortOrder;
}

interface Props<RecordType> extends ExtendedTableProps<RecordType> {
  easyColumns?: EasyColumns;
  editUrl?: (item: any, record: RecordType) => string;
  editCallback?: (item: any, record: RecordType) => void;
  showUrl?: (item: any, record: RecordType) => string;
  defaultSortByColumns?: SortByColumns;
}

function createColumns<RecordType>(
  columns: EasyColumns,
  sortByColumns?: SortByColumns,
): ColumnsType<RecordType> {
  return Object.keys(columns).map((columnKey) => {
    const baseValues = {
      key: columnKey,
      title: columns[columnKey],
      dataIndex: columnKey,
    };

    if (columnKey === "checkbox") return baseValues;

    return {
      ...baseValues,
      defaultSortOrder: sortByColumns ? sortByColumns[columnKey] : null,
      sorter: {
        compare: (a: any, b: any): number => {
          if (isNaN(a[columnKey])) {
            return (a[columnKey] as string).localeCompare(b[columnKey] as string);
          } else {
            return parseInt(a[columnKey]) - parseInt(b[columnKey]);
          }
        },
      },
    };
  });
}

function ListTable<RecordType extends object = any>(props: Props<RecordType>): JSX.Element {
  const { easyColumns } = props;

  const columns = easyColumns
    ? createColumns<RecordType>(easyColumns, props.defaultSortByColumns)
    : [...(props.columns ?? [])];

  if (props.editUrl) {
    columns?.push({
      key: "edit",
      title: "",
      render: (value, record) => {
        return (
          props.editUrl && (
            <Link to={props.editUrl(value, record)}>
              <FontAwesomeIcon icon={faEdit} />
            </Link>
          )
        );
      },
    });
  }

  if (props.editCallback) {
    columns?.push({
      key: "edit",
      title: "",
      render: (value, record) => {
        return (
          props.editCallback && (
            <Button
              type="link"
              onClick={(): void => props.editCallback && props.editCallback(value, record)}>
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          )
        );
      },
    });
  }

  if (props.showUrl && columns) {
    const firstCol = { ...columns[0] };
    const renderFunc = getFirst(props?.columns)?.render;
    firstCol.render = (value, record, index): React.ReactNode => {
      const newValue = renderFunc ? renderFunc(value, record, index) : <>{value}</>;
      return props.showUrl && <Link to={props.showUrl(value, record)}>{newValue}</Link>;
    };
    columns[0] = firstCol;
  }

  return (
    <Table pagination={false} rowKey="id" {...props} size={props.size} columns={columns}></Table>
  );
}

export default ListTable;
