import React from "react";
import { useParams } from "react-router-dom";

import { useGetScheduleRuleQuery } from "generated/schemaTypes";

import EditScheduleRule from "components/ScheduleRule/Modify/EditScheduleRule";
import StatusHandler from "components/atoms/StatusHandler";

const CreateScheduleView = (modalOpen: boolean): React.ReactElement => {
  const { scheduleRuleId, tloId } = useParams<{ scheduleRuleId: string; tloId: string }>();

  const getScheduleQuery = useGetScheduleRuleQuery({
    variables: { id: scheduleRuleId, tlo: tloId },
  });

  return (
    <StatusHandler
      dataQueries={[{ query: getScheduleQuery, nonNullData: getScheduleQuery?.data?.schedule }]}>
      {([schedule]): React.ReactElement => <EditScheduleRule scheduleRule={schedule} />}
    </StatusHandler>
  );
};

export default CreateScheduleView;
