import React from "react";
import { useParams } from "react-router-dom";

import { useConfigurationQuery, useGetResourceQuery } from "generated/schemaTypes";

import EditResource from "components/Resource/Modify/EditResource";
import BgContainer from "components/atoms/BgContainer";
import PageHeadline from "components/atoms/PageHeadline";
import StatusHandler from "components/atoms/StatusHandler";

const EditResourceView = (): JSX.Element => {
  const { tloId, resourceId } = useParams<{ tloId: string; resourceId: string }>();

  const resourceQuery = useGetResourceQuery({
    variables: { id: resourceId, tlo: tloId },
  });

  const configurationQuery = useConfigurationQuery({
    variables: { countryCode: "SE" },
  });

  return (
    <BgContainer>
      <StatusHandler
        dataQueries={[
          { query: resourceQuery, nonNullData: resourceQuery.data?.resource },
          { query: configurationQuery, nonNullData: configurationQuery.data?.configuration },
        ]}>
        {([resource, configuration]): React.ReactElement => (
          <>
            <PageHeadline title={"Edit " + resource.name} />
            <EditResource
              resource={resource}
              resourceConfigs={configuration.resourceConfiguration ?? []}
            />
          </>
        )}
      </StatusHandler>
    </BgContainer>
  );
};

export default EditResourceView;
