import React from "react";

import { FullScheduleRuleFragment } from "generated/schemaTypes";

import ListTable from "components/atoms/ListTable";

import routes from "routes/routes";

const ListScheduleRules = ({
  scheduleRules,
}: {
  scheduleRules?: FullScheduleRuleFragment[];
}): JSX.Element => {
  return (
    <ListTable<FullScheduleRuleFragment>
      columns={[
        {
          dataIndex: "name",
          title: "Name",
          render: (value, record, index): React.ReactNode => {
            return record.interval + " " + record.hours;
          },
        },
      ]}
      dataSource={scheduleRules}
      defaultSortByColumns={{ sortOrder: "ascend" }}
      editCallback={(value, record): void => {
        alert(value);
      }}
      showUrl={(_, scheduleRule): string => {
        return routes.scheduleRule.edit.path({
          tloId: scheduleRule.tlo,
          scheduleRuleId: scheduleRule.id,
        });
      }}
    />
  );
};

export default ListScheduleRules;
