import React from "react";

import { Hours, ScheduleRule } from "generated/schemaTypes";

import EditBooking from "components/Booking/Modify/BookingDetailsModal";
import {
  StyledDetails,
  ResourceBullet,
  StyledDetailsContainer,
  colorBySortOrder,
  colorByBookingType,
} from "components/Schedule/styles";
import {
  DetailsType,
  isBookings,
  isScheduleRule,
  isScheduleRules,
  ScheduleObject,
} from "components/Schedule/types";
import ListTable from "components/atoms/ListTable";
import Button from "components/atoms/antD/Button";

import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs, { Dayjs } from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { get } from "helpers/Array";
import routes from "routes/routes";

const DetailsPerResource = ({
  details,
  day,
}: {
  details: DetailsType;
  day: Dayjs;
}): React.ReactElement => {
  if (!details?.scheduleObjects.length) {
    return (
      <StyledDetailsContainer>
        <StyledDetails>
          <p>No details</p>
        </StyledDetails>
      </StyledDetailsContainer>
    );
  }

  const scheduleObjects = details.scheduleObjects.sort(sortScheduleObjects);
  if (isScheduleRules(scheduleObjects)) {
    return (
      <StyledDetailsContainer>
        <StyledDetails>
          <ListTable
            size="small"
            scroll={{ x: "max-content" }}
            columns={[
              {
                title: "Name",
                dataIndex: "name",
                fixed: "left",
                render: (_, record): React.ReactElement => {
                  return (
                    <span style={{ whiteSpace: "nowrap" }}>
                      <ResourceBullet color={colorBySortOrder(record.sortOrder)} />
                      {record.name}
                    </span>
                  );
                },
              },
              {
                title: "Order",
                dataIndex: "sortOrder",
              },
              {
                title: "Start date",
                dataIndex: "startDate",
              },
              {
                title: "End date",
                dataIndex: "endDate",
              },
              {
                title: "Start Time",
                dataIndex: "startTime",
                render: (_, record): React.ReactElement => (
                  <>{getHoursForDay(record, day)?.startTime}</>
                ),
              },
              {
                title: "End Time",
                dataIndex: "endTime",
                render: (_, record): React.ReactElement => (
                  <>{getHoursForDay(record, day)?.endTime}</>
                ),
              },
              {
                title: "Min duration",
                dataIndex: "minDuration",
              },
              {
                title: "Interval",
                dataIndex: "interval",
              },
              {
                title: "Max Duration",
                dataIndex: "maxDuration",
              },
              {
                title: "Type",
                dataIndex: "type",
              },
              {
                title: "Description",
                dataIndex: "description",
              },
              {
                title: "Edit",
                render: (checked, record): React.ReactElement => {
                  return (
                    <Button
                      title="Edit rule"
                      type="link"
                      href={routes.scheduleRule.edit.path({
                        tloId: record.tlo,
                        scheduleRuleId: record.id,
                      })}>
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  );
                },
              },
            ]}
            dataSource={scheduleObjects.map((scheduleObject) => scheduleObject.origin)}
          />
        </StyledDetails>
      </StyledDetailsContainer>
    );
  }
  if (isBookings(scheduleObjects)) {
    return (
      <StyledDetailsContainer>
        <StyledDetails>
          <ListTable
            size="small"
            scroll={{ x: "max-content" }}
            columns={[
              {
                title: "Time",
                key: "time",
                fixed: "left",
                sorter: (a, b): number => Date.parse(a.startTime) - Date.parse(b.startTime),
                render: (checked, record): React.ReactElement => {
                  const start = new Date(record.startTime);
                  const end = new Date(record.endTime);
                  return (
                    <>
                      <ResourceBullet color={colorByBookingType(record.type)} />
                      {start.toLocaleTimeString(undefined, {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}{" "}
                      - {end.toLocaleTimeString(undefined, { hour: "2-digit", minute: "2-digit" })}
                    </>
                  );
                },
              },

              {
                title: "Player",
                render: (checked, record): React.ReactElement => {
                  return <>Paquito Navarro</>;
                },
              },
              {
                title: "Type",
                dataIndex: "type",
                filters: [
                  {
                    value: "activity",
                    text: "Activity",
                  },
                  {
                    value: "booking",
                    text: "Booking",
                  },
                  {
                    value: "subscription",
                    text: "Subscription",
                  },
                  {
                    value: "competition",
                    text: "Competition",
                  },
                  {
                    value: "training",
                    text: "Training",
                  },
                ],
                sorter: (a, b): number => a.type.length - b.type.length,
                onFilter: (value, record): boolean => record.type.indexOf(value as string) === 0,
              },
              {
                title: "Comment",
                dataIndex: "comment",
              },
              {
                title: "Booked",
                key: "booked",
                render: (checked, record): React.ReactElement => {
                  return (
                    <>
                      {dayjs(record.createdAt).toLocaleString()} by {record.createdBy}
                    </>
                  );
                },
              },
              {
                title: "Edit",
                render: (checked, record): React.ReactElement => {
                  return (
                    <EditBooking booking={record}>
                      {({ openModal }): React.ReactElement => {
                        return (
                          <>
                            <Button
                              title="Edit booking"
                              type="link"
                              onClick={(e): void => {
                                openModal();
                              }}>
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                          </>
                        );
                      }}
                    </EditBooking>
                  );
                },
              },
              {
                title: "Id (for testing purpose)",
                dataIndex: "id",
              },
            ]}
            dataSource={scheduleObjects.map((scheduleObject) => scheduleObject.origin)}
          />
        </StyledDetails>
      </StyledDetailsContainer>
    );
  }
  return <>Not supporting mixed ScheduleObject[]</>;
};

const sortScheduleObjects = (a: ScheduleObject, b: ScheduleObject): number => {
  if (isScheduleRule(a.origin) && isScheduleRule(b.origin)) {
    return a.origin.sortOrder - b.origin.sortOrder;
  } else {
    return a.xStart - b.xEnd;
  }
};
const getHoursForDay = (record: ScheduleRule, day: Dayjs): Hours | undefined => {
  dayjs.extend(weekday);
  return get(record.hours, day.weekday());
};
export default DetailsPerResource;
