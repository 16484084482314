import { gql } from "@apollo/client";

export const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

export const VENUE_AND_ORGANIZATION_AND_TLO_ID = gql`
  query GetVenueAndTloId {
    storedVenueId @client
    storedOrganizationId @client
    storedTloId @client
  }
`;
