import CreateScheduleView from "components/ScheduleRule/Views/EditScheduleView";

import { BASE_ROUTE_PATH_SCHEDULE_RULE } from "routes/paths";

const route = {
  path: (params?: { tloId?: string; scheduleRuleId?: string }): string => {
    return (
      BASE_ROUTE_PATH_SCHEDULE_RULE +
      "/edit/" +
      (params ? params.tloId : ":tloId") +
      "/" +
      (params ? params.scheduleRuleId : ":scheduleRuleId")
    );
  },
  component: CreateScheduleView,
};

export default route;
