const breakpoints = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 1024,
  xl: 1200,
  xxl: 1200,
};

const spacing = {
  lg: 24,
  md: 16,
  sm: 12,
  xs: 8,
  xxs: 4,
};

const responsiveWidths = {
  xs: breakpoints.xs - spacing.sm * 2,
  sm: breakpoints.sm - spacing.lg * 2,
  md: breakpoints.md - spacing.lg * 2,
  lg: breakpoints.lg - spacing.lg * 2,
  xl: breakpoints.xl - spacing.lg * 2,
  xxl: breakpoints.xxl - spacing.lg * 2,
};

module.exports = { breakpoints, responsiveWidths, spacing };
