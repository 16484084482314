import { useEffect, useState } from "react";

import {
  Booking,
  useResourcesFromVenueQuery,
  useUpdateBookingMutation,
  Venue,
  useDeleteBookingMutation,
} from "generated/schemaTypes";

import BookingForm from "components/Booking/BookingForm";
import ListBookingEvents from "components/Booking/Get/ListBookingEvents";
import { withOrganizationAndVenue } from "components/OrganizationAndVenueProvider";
import ErrorMessage from "components/atoms/ErrorMessage";
import StatusHandler from "components/atoms/StatusHandler";
import Button from "components/atoms/antD/Button";
import message from "components/atoms/antD/Message";
import Modal from "components/atoms/antD/Modal";
import Popconfirm from "components/atoms/antD/Popconfirm";

import { useForm } from "antd/lib/form/Form";
import { BookingFormValues, convertValuesToBookingInput } from "helpers/converters/booking";
import { isFormInitialized } from "helpers/form";
import { deleteBookingInCache, updateBookingInCache } from "services/BookingServices";

const UpdateBooking = withOrganizationAndVenue(
  ({
    venue,
    booking,
    children,
  }: {
    venue: Venue;
    booking: Booking;
    children: (props: { openModal: (values?: BookingFormValues) => void }) => React.ReactElement;
  }): React.ReactElement => {
    const [form] = useForm<BookingFormValues>();
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
      if (modalOpen === false && isFormInitialized(form)) {
        form.resetFields();
      }
    }, [modalOpen, form]);

    const resourcesFromVenueQuery = useResourcesFromVenueQuery({
      variables: {
        id: venue.id,
        tlo: venue.tlo,
      },
    });

    const [updateBooking, { loading: updateLoading }] = useUpdateBookingMutation({
      update: updateBookingInCache,
      onCompleted: () => {
        setModalOpen(false);
        message.success("Booking updated");
      },
      onError: (e) => {
        message.error(e.message);
      },
    });

    const onSubmit = (): void => {
      const values = convertValuesToBookingInput(form.getFieldsValue(), booking.tlo, booking.id);

      updateBooking({ variables: { updateBooking: values } });

      return;
    };

    const openModal = (values?: BookingFormValues): void => {
      form.setFieldsValue(values || {});
      setModalOpen(true);
    };

    const modalTitle = booking.status === "CANCELLED" ? "Booking details" : "Update Booking";

    return (
      <>
        {children({ openModal })}
        <Modal
          centered
          title={modalTitle}
          visible={modalOpen}
          onCancel={(e): void => {
            setModalOpen(false);
            e.stopPropagation();
          }}
          footer={
            booking.status !== "CANCELLED" ? (
              <>
                <ListBookingEvents tlo={venue.tlo} bookingId={booking.id || ""} />
                <DeleteBookingButton
                  id={booking.id}
                  tlo={booking.tlo}
                  onCompleted={(): void => {
                    setModalOpen(false);
                  }}
                />
                <Button
                  loading={updateLoading}
                  onClick={(): void => {
                    onSubmit();
                  }}
                  type="primary">
                  Save
                </Button>
              </>
            ) : (
              <>
                <ListBookingEvents tlo={venue.tlo} bookingId={booking.id || ""} />
                <Button
                  loading={updateLoading}
                  onClick={(): void => {
                    setModalOpen(false);
                  }}
                  type="default">
                  Close
                </Button>
              </>
            )
          }>
          <StatusHandler
            dataQueries={[
              {
                query: resourcesFromVenueQuery,
                nonNullData: resourcesFromVenueQuery.data?.venue?.resources,
              },
            ]}>
            {([resources]): React.ReactElement => (
              <BookingForm
                cancelledBooking={booking.status === "CANCELLED"}
                booking={booking}
                form={form}
                resources={resources}
              />
            )}
          </StatusHandler>
        </Modal>
      </>
    );
  },
);

export default UpdateBooking;

const DeleteBookingButton = ({
  id,
  tlo,
  onCompleted,
}: {
  id?: string;
  tlo?: string;
  onCompleted: () => void;
}): JSX.Element => {
  const [deleteResource, { loading }] = useDeleteBookingMutation({
    update: deleteBookingInCache,
    onError: (error) => {
      <ErrorMessage error={error} />;
    },
    onCompleted: onCompleted,
  });

  if (!id || !tlo) {
    return <></>;
  }

  const deleteAction = (e?: React.MouseEvent): void => {
    deleteResource({
      variables: {
        tlo: tlo,
        id: id,
      },
    });
  };

  return (
    <Popconfirm title="Are you sure you want to delete?" onConfirm={deleteAction}>
      <Button color="danger" loading={loading}>
        Cancel Booking
      </Button>
    </Popconfirm>
  );
};
