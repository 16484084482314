import React from "react";
import { useHistory } from "react-router-dom";

import { Organization, useCreateVenueMutation } from "generated/schemaTypes";

import CreateOrUpdateVenueForm from "components/Venue/Modify/CreateOrEditVenueForm";
import ErrorMessage from "components/atoms/ErrorMessage";

import { convertValuesToVenueInput, VenueFormValues } from "helpers/converters/venue";
import routes from "routes/routes";
import { updateListVenuesByOrganizationInCache } from "services/venueServices";

const CreateVenue = ({ organization }: { organization: Organization }): JSX.Element => {
  const history = useHistory();

  const [createVenue, { loading, error }] = useCreateVenueMutation({
    onCompleted: () => {
      history.push(
        routes.organization.show.path({ organizationId: organization.id, tloId: organization.tlo }),
      );
    },
    update: updateListVenuesByOrganizationInCache,
  });

  // if (!organization) {
  //   return <ErrorMessage error={{ message: "No organization" }} />;
  // }

  const onFinish = (values: VenueFormValues): void => {
    createVenue({
      variables: {
        createVenue: {
          parent: organization.id ?? "",
          venue: convertValuesToVenueInput(values, { tlo: organization.tlo }),
        },
      },
    });
  };

  return (
    <>
      {error ? (
        <ErrorMessage error={error} />
      ) : (
        <CreateOrUpdateVenueForm onFinish={onFinish} submitLoading={loading} />
      )}
    </>
  );
};

export default CreateVenue;
