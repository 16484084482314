import { useEffect } from "react";

import { useQuery } from "@apollo/client";

import {
  Organization,
  useGetOrganizationLazyQuery,
  useGetOrganizationSkeletonsLazyQuery,
  useGetVenueLazyQuery,
  useVenuesFromOrganizationLazyQuery,
  Venue,
} from "generated/schemaTypes";

import Loading from "components/atoms/Loading";

import { storedOrganizationId, storedTloId, storedVenueId } from "apollo/cache";
import { VENUE_AND_ORGANIZATION_AND_TLO_ID } from "apollo/queries";
import { getFirst } from "helpers/Array";

const OrganizationAndVenueProvider = ({
  children,
}: {
  children:
    | React.ReactNode
    | ((props: { organization: Organization; venue: Venue }) => React.ReactElement);
}): React.ReactElement => {
  const { venue, organization, loading, called } = useOrganizationAndVenueUnsafe();
  if (loading) {
    return <Loading />;
  }

  if (!venue || !organization) {
    if (called) {
      console.warn("Venue or Organization could not be loaded");
    }

    return <></>;
  }

  if (typeof children === "function") {
    return children({ organization, venue });
  }
  return <>{children}</>;
};

export default OrganizationAndVenueProvider;

export const useOrganizationAndVenueUnsafe = (): {
  venue: Venue | undefined;
  organization: Organization | undefined;
  loading: boolean;
  called: boolean;
} => {
  const [getVenue, getVenueLazyQuery] = useGetVenueLazyQuery();

  const [getOrganization, getOrganizationLazyQuery] = useGetOrganizationLazyQuery();

  const [getOrganizations, getOrganizationsLazyQuery] = useGetOrganizationSkeletonsLazyQuery({
    onCompleted: (values) => {
      getVenues({
        variables: {
          tlo: getFirst(values?.organizations)?.tlo ?? "",
          id: getFirst(values?.organizations)?.id ?? "",
        },
      });
    },
    onError: () => {
      console.log("ERROR");
    },
  });

  const [getVenues, getVenuesLazyQuery] = useVenuesFromOrganizationLazyQuery({
    onCompleted: (values) => {
      const organization = getFirst(getOrganizationsLazyQuery.data?.organizations);
      const venue = getFirst(values?.organization?.venues);

      storedVenueId(venue?.id);
      storedOrganizationId(organization?.id);
      storedTloId(venue?.tlo);
    },
  });

  const venueAndOrganizationAndTloIdLazyQuery = useQuery(VENUE_AND_ORGANIZATION_AND_TLO_ID);

  // Bad usage of useEffect, logic should rather be put in VENUE_AND_ORGANIZATION_AND_TLO_ID query
  // but onCompleted is broken in apollo and will not run on refetch.
  // https://github.com/apollographql/react-apollo/issues/3709
  useEffect(() => {
    const values = venueAndOrganizationAndTloIdLazyQuery.data;

    if (values && values.storedVenueId && values.storedOrganizationId && values.storedTloId) {
      getVenue({
        variables: {
          id: values.storedVenueId,
          tlo: values.storedTloId,
        },
      });
      getOrganization({
        variables: {
          id: values.storedOrganizationId,
          tlo: values.storedTloId,
        },
      });
    } else {
      getOrganizations();
    }
  }, [venueAndOrganizationAndTloIdLazyQuery, getOrganization, getOrganizations, getVenue]);

  const loading =
    getVenueLazyQuery.loading ||
    getOrganizationLazyQuery.loading ||
    getOrganizationsLazyQuery.loading ||
    getVenuesLazyQuery.loading;

  const called = getVenueLazyQuery.called && getOrganizationLazyQuery.called;

  const venue = getVenueLazyQuery.data?.venue;
  const organization = getOrganizationLazyQuery.data?.organization;

  return { venue, organization, loading, called };
};

export function withOrganizationAndVenue<P>(
  WrappedComponent: (props: P) => React.ReactElement,
): React.ComponentType<Omit<P, "venue" | "organization">> {
  return (props): React.ReactElement => (
    <OrganizationAndVenueProvider>
      {({ venue, organization }): React.ReactElement => (
        <WrappedComponent venue={venue} organization={organization} {...(props as any)} />
      )}
    </OrganizationAndVenueProvider>
  );
}
