import { ApolloCache, FetchResult, Reference } from "@apollo/client";

import {
  CreateScheduleRuleMutation,
  DeleteScheduleRuleMutation,
  FullScheduleRuleFragmentDoc,
  UpdateScheduleRuleMutation,
} from "generated/schemaTypes";

import { extendListInCache } from "helpers/service";

export const createScheduleRuleInCache = (
  cache: ApolloCache<CreateScheduleRuleMutation>,
  { data }: FetchResult<CreateScheduleRuleMutation>,
): void => {
  cache.modify({
    fields: {
      venue(venueRef, { readField }) {
        cache.modify({
          id: venueRef.__ref,
          fields: {
            scheduleRules(scheduleRules) {
              extendListInCache(
                scheduleRules,
                data?.createSchedule,
                FullScheduleRuleFragmentDoc,
                cache,
                readField,
              );
            },
          },
        });
        return venueRef;
      },
    },
  });
};

export const updateScheduleRuleInCache = (
  cache: ApolloCache<UpdateScheduleRuleMutation>,
  { data }: FetchResult<UpdateScheduleRuleMutation>,
): void => {
  cache.writeFragment({
    data: data?.updateSchedule,
    fragment: FullScheduleRuleFragmentDoc,
  });
};

export const deleteScheduleRuleInCache = (
  cache: ApolloCache<DeleteScheduleRuleMutation>,
  { data }: FetchResult<DeleteScheduleRuleMutation>,
): void => {
  cache.modify({
    fields: {
      venue(currentUserRef) {
        cache.modify({
          id: currentUserRef.__ref,
          fields: {
            schedules(schedules, { readField }) {
              return schedules.filter(
                (sechedule: Reference) => readField("id", sechedule) !== data?.deleteSchedule,
              );
            },
          },
        });
        return currentUserRef;
      },
    },
  });
};
