import Button from "components/atoms/antD/Button";
import DatePicker from "components/atoms/antD/DatePicker";

import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import locale from "antd/lib/date-picker/locale/sv_SE";
import dayjs, { Dayjs } from "dayjs";
import styled from "styled-components";

const DateSelector = ({
  date,
  handleChange,
}: {
  date: Dayjs;
  handleChange: (value: Dayjs | null) => void;
}): JSX.Element => {
  const nextDay = (): void => {
    handleChange(dayjs(date.add(1, "day")));
  };
  const previousDay = (): void => {
    handleChange(dayjs(date.subtract(1, "day")));
  };

  return (
    <StyledDateSelector>
      <StyledButton type="link" onClick={previousDay}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </StyledButton>
      <StyledDatePicker
        locale={locale}
        value={date}
        allowClear={false}
        onChange={handleChange}
        format="dddd YYYY-MM-DD"
      />
      <StyledButton type="link" onClick={nextDay}>
        <FontAwesomeIcon icon={faChevronRight} />
      </StyledButton>
    </StyledDateSelector>
  );
};

export default DateSelector;

export const StyledDateSelector = styled.nav`
  display: flex;
  margin: 0 auto;
  align-items: center;
`;

const StyledDatePicker = styled(DatePicker)`
  border: none;
  font-size: 25px;
  & .ant-picker-input input {
    font-size: 25px;
    &:hover {
      cursor: pointer;
    }
  }
`;
const StyledButton = styled(Button)`
  && {
    font-size: 25px;
    color: black;
  }
`;
