import ShowResourceView from "components/Resource/Views/ShowResourceView";

import { BASE_ROUTE_PATH_RESOURCE } from "routes/paths";

const route = {
  path: (params?: { tloId?: string; resourceId?: string }): string => {
    return (
      BASE_ROUTE_PATH_RESOURCE +
      "/show/" +
      (params ? params.tloId : ":tloId") +
      "/" +
      (params ? params.resourceId : ":resourceId")
    );
  },
  component: ShowResourceView,
};

export default route;
