import dayjs, { Dayjs } from "dayjs";

const REGULAR_DATE = "YYYY-MM-DD";

export const formatDateForAPI = (dateTime: Dayjs | null): string => {
  return (dateTime ? dateTime : dayjs()).format(REGULAR_DATE);
};

export const formatDateAsRegular = (dateTime: Dayjs | null): string => {
  return (dateTime ? dateTime : dayjs()).format(REGULAR_DATE);
};

export const parseDatestring = (
  datestring?: string,
  fallbackToToday = false,
  format = REGULAR_DATE,
): Dayjs => {
  const date = dayjs(datestring, format);

  if (date.isValid()) {
    return date;
  } else if (fallbackToToday) {
    return dayjs();
  } else {
    throw new Error(`Could not parse date ${datestring} with suggested format ${format}`);
  }
};
