import colors from "config/style";
import styled, { css } from "styled-components";

export const scheduleObjectAttributes = {
  scheduleRuleColor: ["#FFFFFF", "#C4D7F2", "MediumAquamarine", "papayawhip", "LavenderBlush"],
  bookingTypeColor: {
    booking: "#FFFFFF",
    activity: "#C4D7F2",
    training: "MediumAquamarine",
    competition: "papayawhip",
    subscription: "LavenderBlush",
    notAvailable: "#FA5F55",
    other: "blue",
  },
  dimensions: {
    height: {
      default: 70,
      open: 200,
    },
  },
  margin: {
    y: 6,
  },
};

export const BOOKING_TYPES = [
  "booking",
  "activity",
  "training",
  "competition",
  "subscription",
  "notAvailable",
] as const;

type BookingType = typeof BOOKING_TYPES[number];

function isBookingType(arg: any): arg is BookingType {
  return BOOKING_TYPES.includes(arg);
}

export const colorByBookingType = (bookingType: string | undefined): string => {
  if (isBookingType(bookingType)) {
    return scheduleObjectAttributes.bookingTypeColor[bookingType];
  }
  return scheduleObjectAttributes.bookingTypeColor["other"];
};

export const colorBySortOrder = (sortOrder: number): string => {
  return (
    scheduleObjectAttributes.scheduleRuleColor[sortOrder] ??
    scheduleObjectAttributes.scheduleRuleColor[5]
  );
};

const LAYOUT_PADDING = 32;

export const StyledLayout = styled.div`
  position: relative;
  padding-top: ${LAYOUT_PADDING}px;
  flex-grow: 1;
`;

export const StyledLegends = styled.ul`
  list-style: none;
  float: right;
  padding-top: 10px;
`;

export const StyledLegend = styled.li`
  text-transform: capitalize;
  display: inline;
  margin-left: 10px;
`;

export const StyledLegendBullet = styled.span<{ bookingType: string }>`
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: ${({ bookingType }): string => colorByBookingType(bookingType)};
  margin-right: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
`;

export const StyledResources = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledResourceIcon = styled.img`
  width: 20x;
  height: 20px;
  transform: rotate(30deg);
  margin-right: 5px;
`;

export const StyledPlannerBg = styled.div`
  position: absolute;
  top: 32px;
  right: 0;
  bottom: 0;
  left: 100px;
  background-color: rgba(0, 0, 0, 0.04);
`;

export const ResourceBullet = styled.span<{ color: string }>`
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ color }): string => color};
  margin-right: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
`;

export const ResourceObjects = styled.div`
  flex-grow: 1;
  position: relative;
  min-width: 0;
`;

export const ResourceScheduleObjects = styled.div`
  width: 100%;
  position: relative;
  z-index: 5;
  height: 70px;
`;

export const ResourceTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100px;
  overflow: hidden;
  background: white;
  height: ${scheduleObjectAttributes.dimensions.height.default}px;
  padding: 5px;
  /*border-top: 1px solid rgba(0, 0, 0, 0.2);*/
  flex-shrink: 0;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  span {
    display: block;
    overflow: hidden;
    line-height: 1.5;
    font-size: 14px;
  }
`;

export const ResourceContainer = styled.div`
  width: 100%;
  display: flex;
  min-height: 70px;
  cursor: pointer;
  &:hover {
    & > div {
      position: relative;
      background-color: #edfbff;
      &${ResourceTitle} {
        &::after {
          border-right: 3px solid #1c91dd;
          content: "";
          display: block;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 3px;
        }
        & span {
          color: #1c91dd;
        }
      }
    }
  }
`;

export const ResourceDetails = styled.div`
  width: 100%;
  background: rgba(0, 255, 0, 0.1);
  display: none;
  position: relative;
  z-index: 15;
`;

export const StyledDetailsContainer = styled.div`
  width: 100%;
  z-index: 15;
  transition: transform 300ms;
`;
export const StyledDetails = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 10px;
  overflow: hidden;
`;

interface LineProps {
  x: number;
  lineType: string;
  first?: boolean;
  last?: boolean;
}

export const StyledCurrentTimeIndicator = styled.div.attrs(({ x }: { x: number }) => ({
  style: {
    transform: `translateX(${x}px) translateZ(0)`,
  },
}))<{ x: number }>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  border: 1px solid red;
  z-index: 10;
  transition: transform 300ms;
  &::before {
    content: "";
    position: absolute;
    display: block;
    height: 12px;
    width: 12px;
    left: -6px;
    top: -12px;
    border-radius: 50%;
    background-color: red;
  }
`;

export const StyledAxisLine = styled.div.attrs(({ x }: LineProps) => ({
  style: {
    transform: `translateX(${x}px) translateZ(0)`,
  },
}))<LineProps>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  border: 0;
  border-left: 1px ${colors.grey.c4};
  ${({ first, last }): any =>
    (first || last) &&
    css`
      z-index: 5;
    `}

  ${({ lineType }): any =>
    lineType === "half" &&
    css`
      border-style: dashed;
    `}
  ${({ lineType }): any =>
    lineType === "full" &&
    css`
      border-style: solid;
    `}
`;

export const StyledAxisTime = styled.div.attrs(({ x }: { x: number }) => ({
  style: {
    transform: `translateX(${x}px) translateZ(0)`,
  },
}))<{ x: number }>`
  position: absolute;
  top: -${LAYOUT_PADDING}px;
  left: -7px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
`;

const sharedStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px;
  will-change: transform;
  transition: transform 300ms;
`;

interface SlotProps {
  x?: number;
  width?: number;
  zIndex: number;
  color: string;
}

export const StyledSlot = styled.div.attrs(({ x, width, color, zIndex }: SlotProps) => ({
  style: {
    width,
    transform: `translateX(${x}px) translateZ(0)`,
    zIndex: zIndex,
    backgroundColor: color,
    height: `${
      scheduleObjectAttributes.dimensions.height.default - scheduleObjectAttributes.margin.y * 2
    }px`,
  },
}))<SlotProps>`
  ${sharedStyles}
  margin-top: ${scheduleObjectAttributes.margin.y}px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${colors.grey.c2};
`;
