import React from "react";

import { useGetOrganizationSkeletonsQuery } from "generated/schemaTypes";

import ListOrganizations from "components/Organization/Get/ListOrganizations";
import BgContainer from "components/atoms/BgContainer";
import PageHeadline from "components/atoms/PageHeadline";
import StatusHandler from "components/atoms/StatusHandler";

const IndexOrganizationsView = (): JSX.Element => {
  const getOrganizationQuery = useGetOrganizationSkeletonsQuery();

  return (
    <BgContainer>
      <StatusHandler
        dataQueries={[
          { query: getOrganizationQuery, nonNullData: getOrganizationQuery?.data?.organizations },
        ]}>
        {([organizations]): React.ReactElement => (
          <>
            <PageHeadline title="Organisation" />
            <ListOrganizations organizations={organizations} />
          </>
        )}
      </StatusHandler>
    </BgContainer>
  );
};

export default IndexOrganizationsView;
