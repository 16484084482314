import React from "react";

import { ApolloError } from "@apollo/client/errors";
import { ServerError } from "@apollo/client/link/utils";

import Result from "components/atoms/antD/Result";

interface GenericErrorMessage {
  message: string;
}

export type AcceptedError = ApolloError | GenericErrorMessage | null | undefined;

interface props {
  error: AcceptedError;
}

function isApolloError(arg: any): arg is ApolloError {
  return arg?.name !== undefined && arg?.message !== undefined;
}

function isServerError(arg: any): arg is ServerError {
  return arg?.response !== undefined;
}

const ErrorMessage = ({ error }: props): JSX.Element => {
  let title: string = "",
    message: string = "",
    extra: string = "";

  if (isApolloError(error)) {
    //Do ApolloError check
    console.log(JSON.stringify(error));
    if (error.name && error.message) {
      message = error.message;
      title = error.name;
      if (isServerError(error.networkError)) {
        extra = error.networkError.result.errors
          ?.map((error: any): string => {
            return error.errorType + ": " + error.message;
          })
          .join("<br>");
      }
    }
  } else if (error) {
    //Probably generic
    message = error.message;
  }

  console.error(error);

  return (
    <div data-testid="error-message">
      <Result status="error" title={title} subTitle={message} extra={extra}></Result>
    </div>
  );
};

export default ErrorMessage;
