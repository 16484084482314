import { ScheduleRule, ScheduleRuleInput } from "generated/schemaTypes";

import { ScheduleRuleFormValues } from "components/ScheduleRule/Modify/ScheduleRuleFormItems";

import { WEEK_DAYS } from "constants/index";
import dayjs from "dayjs";
import { get } from "helpers/Array";

export const convertValuesToScheduleRuleInput = (
  values: ScheduleRuleFormValues,
  tloId: string,
  scheduleId?: string,
): ScheduleRuleInput => {
  const scheduleInput: ScheduleRuleInput = {
    tlo: tloId,
    id: scheduleId,
    name: values.name || "",
    sortOrder: values.sortOrder,
    startDate: values.startDate?.format("YYYY-MM-DD") ?? "",
    endDate: values.endDate?.format("YYYY-MM-DD"),
    type: values.typeOfRule,
    description: values.description,
    hours: WEEK_DAYS.map((day, index) => {
      return {
        startTime: values.hours && values.hours[day] ? values.hours[day][0].format("HH:mm") : "",
        endTime: values.hours && values.hours[day] ? values.hours[day][1].format("HH:mm") : "",
        weekdays: [index],
      };
    }),
    resources: values.resources,
    minDuration: values.minDuration,
    maxDuration: values.maxDuration,
    interval: values.interval,
  };

  return scheduleInput;
};

export const convertScheduleRuleToValues = (schedule?: ScheduleRule): ScheduleRuleFormValues => {
  return {
    typeOfRule: schedule?.type ?? "booking",
    sortOrder: schedule ? schedule.sortOrder : 100, //schedule should not be null
    name: schedule?.name ?? "",
    description: schedule?.description,
    resources: schedule?.resources ?? [],
    startDate: dayjs(schedule?.startDate),
    endDate: dayjs(schedule?.endDate),
    hours: {
      monday: [
        dayjs(get(schedule?.hours, 0)?.startTime, "HH:mm"),
        dayjs(get(schedule?.hours, 0)?.endTime, "HH:mm"),
      ],
      tuesday: [
        dayjs(get(schedule?.hours, 1)?.startTime, "HH:mm"),
        dayjs(get(schedule?.hours, 1)?.endTime, "HH:mm"),
      ],
      wednesday: [
        dayjs(get(schedule?.hours, 2)?.startTime, "HH:mm"),
        dayjs(get(schedule?.hours, 2)?.endTime, "HH:mm"),
      ],
      thursday: [
        dayjs(get(schedule?.hours, 3)?.startTime, "HH:mm"),
        dayjs(get(schedule?.hours, 3)?.endTime, "HH:mm"),
      ],
      friday: [
        dayjs(get(schedule?.hours, 4)?.startTime, "HH:mm"),
        dayjs(get(schedule?.hours, 4)?.endTime, "HH:mm"),
      ],
      saturday: [
        dayjs(get(schedule?.hours, 5)?.startTime, "HH:mm"),
        dayjs(get(schedule?.hours, 5)?.endTime, "HH:mm"),
      ],
      sunday: [
        dayjs(get(schedule?.hours, 6)?.startTime, "HH:mm"),
        dayjs(get(schedule?.hours, 6)?.endTime, "HH:mm"),
      ],
    },
    minDuration: schedule?.minDuration ?? 0,
    maxDuration: schedule?.maxDuration ?? 0,
    interval: schedule?.interval ?? 0,
  };
};
