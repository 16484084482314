import React from "react";

import ErrorMessage, { AcceptedError } from "components/atoms/ErrorMessage";

interface Props {
  error?: AcceptedError | AcceptedError[];
  children: React.ReactNode;
}

interface State {
  catchedError: AcceptedError;
}

class ErrorHandler extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { catchedError: null };
  }

  static getDerivedStateFromError(error: AcceptedError): State {
    // Update state so the next render will show the fallback UI.
    return { catchedError: error };
  }

  componentDidCatch(error: AcceptedError, errorInfo: any): void {
    // You can also log the error to an error reporting service
    console.warn(error, errorInfo);
  }

  render(): React.ReactElement {
    const { children } = this.props;
    const { catchedError } = this.state;

    if (catchedError) {
      return <ErrorMessage error={catchedError} />;
    } else {
      return <>{children}</>;
    }
  }
}

export default ErrorHandler;
