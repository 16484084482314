import React from "react";
import { useParams } from "react-router-dom";

import { useGetVenueQuery } from "generated/schemaTypes";

import ShowVenue from "components/Venue/Get/ShowVenue";
import BgContainer from "components/atoms/BgContainer";
import StatusHandler from "components/atoms/StatusHandler";

const ShowVenueView = (): JSX.Element => {
  const { venueId, tloId } = useParams<{ venueId: string; tloId: string }>();

  const getVenueQuery = useGetVenueQuery({
    variables: { id: venueId, tlo: tloId },
  });

  return (
    <BgContainer>
      <StatusHandler
        dataQueries={[{ query: getVenueQuery, nonNullData: getVenueQuery?.data?.venue }]}>
        {([venue]): React.ReactElement => <ShowVenue venue={venue} />}
      </StatusHandler>
    </BgContainer>
  );
};

export default ShowVenueView;
