import routes, { Route } from "routes/routes";

function isRoute(arg: any): boolean {
  return arg?.path !== undefined && arg?.component !== undefined;
}

const flattenRoutesFn = (routes: { [key: string]: any }): Route[] => {
  return Object.values(routes).flatMap((route) => {
    if (isRoute(route)) return route;
    return flattenRoutesFn(route);
  });
};

export const flattenRoutes = flattenRoutesFn(routes);
