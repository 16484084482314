import { useHistory, useParams } from "react-router-dom";

import CancellationsList from "components/Booking/Get/CancellationsList";
import BookingsOverview from "components/Booking/Views/BookingsOverview";
import BgContainer, { TabContainer } from "components/atoms/BgContainer";
import { dateIntoPathMethod } from "components/atoms/DateNavigator";
import FlexContainer from "components/atoms/FlexContainer";
import PageHeadline from "components/atoms/PageHeadline";
import Col from "components/atoms/antD/Col";
import Row from "components/atoms/antD/Row";
import Tabs from "components/atoms/antD/Tabs";

import routes from "routes/routes";

const { TabPane } = Tabs;

type TabKeyOptions = "overview" | "cancellations";

function isTabKeyOptions(arg: string): arg is TabKeyOptions {
  return arg === "overview" || arg === "cancellations";
}

const IndexBookingView = (): JSX.Element => {
  const history = useHistory();

  const { venueId, tloId, tabKey } =
    useParams<{ venueId: string; tloId: string; tabKey: string }>();

  const navigationPathForDate = dateIntoPathMethod(routes.booking.overview.path, {
    venueId,
    tloId,
    tabKey,
  });

  return (
    <BgContainer>
      <Row>
        <Col span={12}>
          <PageHeadline title="Bookings" />
        </Col>
      </Row>
      <Tabs
        defaultActiveKey={tabKey || "overview"}
        size="small"
        className="card-container"
        onChange={(tabKey): void => {
          if (isTabKeyOptions(tabKey)) {
            history.push(routes.booking.overview.path({ venueId, tloId, tabKey }));
          }
        }}>
        <TabPane tab="Overview" key="overview">
          <TabContainer>
            <FlexContainer>
              <BookingsOverview navigationPathForDate={navigationPathForDate} />
            </FlexContainer>
          </TabContainer>
        </TabPane>
        <TabPane tab="Cancellations" key="cancellations">
          <TabContainer>
            <CancellationsList
              tlo={tloId}
              venueId={venueId}
              navigationPathForDate={navigationPathForDate}
            />
          </TabContainer>
        </TabPane>
      </Tabs>
    </BgContainer>
  );
};

export default IndexBookingView;
