import React from "react";
import { useParams } from "react-router-dom";

import { useGetVenueQuery } from "generated/schemaTypes";

import EditVenue from "components/Venue/Modify/EditVenue";
import BgContainer from "components/atoms/BgContainer";
import PageHeadline from "components/atoms/PageHeadline";
import StatusHandler from "components/atoms/StatusHandler";

const EditVenueView = (): JSX.Element => {
  const { venueId, tloId } = useParams<{ venueId: string; tloId: string }>();

  const getVenueQuery = useGetVenueQuery({
    variables: { id: venueId, tlo: tloId },
  });

  return (
    <BgContainer>
      <StatusHandler
        dataQueries={[{ query: getVenueQuery, nonNullData: getVenueQuery?.data?.venue }]}>
        {([venue]): React.ReactElement => (
          <>
            <PageHeadline title="Venue" />
            <EditVenue venue={venue} />
          </>
        )}
      </StatusHandler>
    </BgContainer>
  );
};

export default EditVenueView;
