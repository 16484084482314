import { OpeningHours, Venue, VenueInput } from "generated/schemaTypes";

import { GenericSkeleton } from "components/genericTypes";

import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { getFirst } from "helpers/Array";

dayjs.extend(utc);
dayjs.extend(timezone);

export interface VenueFormValues {
  name: string;
  address?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  longitude?: string;
  latitude?: string;
  email?: string;
  phone?: string;
  about?: string;
  openingHours: {
    monday?: Dayjs[];
    tuesday?: Dayjs[];
    wednesday?: Dayjs[];
    thursday?: Dayjs[];
    friday?: Dayjs[];
    saturday?: Dayjs[];
    sunday?: Dayjs[];
  };
  openingHoursDeviation?: string;
}

export interface VenueOpeningTimeItem {
  startTime: string;
  endTime: string;
}

const convertClientHoursToUTC = (date?: Dayjs[]): VenueOpeningTimeItem => {
  //UTC Conversion removed, need a better take on it
  let startTime = "";
  let endTime = "";

  if (date) {
    startTime = date[0]?.format("HH:mm") ?? "";
    endTime = date[1]?.format("HH:mm") ?? "";
  }

  return {
    startTime,
    endTime,
  };
};

const getOpeningHoursDescription = (
  openingHours: OpeningHours[] | undefined,
): string | undefined => {
  const openingHour = getFirst(openingHours);
  return openingHour?.description;
};

const convertUTCHoursToClient = (
  venue: Venue | undefined,
  day: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday",
): any => {
  //UTC Conversion removed, need a better take on it
  try {
    const openingHours = getFirst(venue?.openingHours);
    const dayValues = openingHours && getFirst(openingHours[day]);

    const baseDate = "1970-01-01";
    const start = dayjs(`${baseDate} ${dayValues?.startTime || ""}`);
    const end = dayjs(`${baseDate} ${dayValues?.endTime || ""}`);

    return [start, end];
  } catch (error) {
    console.error(error);
    return "";
  }
};

export const convertValuesToVenueInput = (
  values: VenueFormValues,
  venue?: GenericSkeleton,
): VenueInput => {
  return {
    id: venue?.id ?? "",
    tlo: venue?.tlo ?? "",
    name: values.name,
    description: values.about,
    addresses: [
      {
        street: values.address ?? "",
        city: values.city ?? "",
        postalCode: values.zipCode ?? "",
        countryCode: values.country ?? "",
        addressType: "",
      },
    ],
    emails: [
      {
        email: values.email ?? "",
        emailType: "",
      },
    ],
    position: {
      latitude: values.latitude ?? "",
      longitude: values.longitude ?? "",
    },
    telephoneNumbers: [
      {
        number: values.phone ?? "",
        telephoneType: "SALES",
      },
    ],
    openingHours: [
      {
        monday: [convertClientHoursToUTC(values?.openingHours.monday)],
        tuesday: [convertClientHoursToUTC(values?.openingHours.tuesday)],
        wednesday: [convertClientHoursToUTC(values?.openingHours.wednesday)],
        thursday: [convertClientHoursToUTC(values?.openingHours.thursday)],
        friday: [convertClientHoursToUTC(values?.openingHours.friday)],
        saturday: [convertClientHoursToUTC(values?.openingHours.saturday)],
        sunday: [convertClientHoursToUTC(values?.openingHours.sunday)],
        description: values?.openingHoursDeviation,
      },
    ],
  };
};

export const convertVenueToValues = (venue: Venue | undefined): VenueFormValues => {
  return {
    name: venue?.name ?? "",
    address: getFirst(venue?.addresses)?.street,
    zipCode: getFirst(venue?.addresses)?.postalCode,
    city: getFirst(venue?.addresses)?.city,
    country: getFirst(venue?.addresses)?.countryCode,
    longitude: venue?.position?.longitude,
    latitude: venue?.position?.latitude,
    email: getFirst(venue?.emails)?.email,
    phone: getFirst(venue?.telephoneNumbers)?.number,
    about: venue?.description,
    openingHoursDeviation: getOpeningHoursDescription(venue?.openingHours),
    openingHours: {
      monday: convertUTCHoursToClient(venue, "monday"),
      tuesday: convertUTCHoursToClient(venue, "tuesday"),
      wednesday: convertUTCHoursToClient(venue, "wednesday"),
      thursday: convertUTCHoursToClient(venue, "thursday"),
      friday: convertUTCHoursToClient(venue, "friday"),
      saturday: convertUTCHoursToClient(venue, "saturday"),
      sunday: convertUTCHoursToClient(venue, "sunday"),
    },
  };
};
