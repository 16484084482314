import React, { Fragment, useRef, useState } from "react";

import { Booking } from "generated/schemaTypes";

import EditBooking from "components/Booking/Modify/BookingDetailsModal";
import CreateBooking from "components/Booking/Modify/CreateBooking";
import DetailsPerResource from "components/Schedule/DetailsPerResource";
import { PlannerData, usePlannerData } from "components/Schedule/helpers";
import {
  StyledAxisLine,
  StyledAxisTime,
  StyledCurrentTimeIndicator,
  StyledLayout,
  StyledPlannerBg,
  ResourceTitle,
  StyledResources,
  StyledSlot,
  StyledResourceIcon,
  ResourceContainer,
  ResourceObjects,
  ResourceDetails,
  ResourceScheduleObjects,
  colorBySortOrder,
  colorByBookingType,
} from "components/Schedule/styles";
import {
  AxisType,
  isBooking,
  isScheduleRule,
  ScheduleObject,
  ScheduleResource,
  SharedResourcePlannerProps,
} from "components/Schedule/types";
import StopPropagation from "components/atoms/StopPropagation";

import { Tooltip } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { convertBookingToFormValues, getRoundedDate } from "helpers/converters/booking";
import badmintonIcon from "images/sports/badminton.svg";
import padelIcon from "images/sports/padel.svg";
import pickleballIcon from "images/sports/pickleball.svg";
import squashIcon from "images/sports/squash.svg";
import tabletennisIcon from "images/sports/tabletennis.svg";
import tennisIcon from "images/sports/tennis.svg";

interface ResourceTypeIcon {
  [key: string]: string;
}

const ResourceTypeIcons = {
  padel: padelIcon,
  tennis: tennisIcon,
  badminton: badmintonIcon,
  squash: squashIcon,
  pickleball: pickleballIcon,
  tabletennis: tabletennisIcon,
} as ResourceTypeIcon;

const iconByResourceType = (resourceType: string): string => {
  return ResourceTypeIcons[resourceType.toLowerCase()] ?? ResourceTypeIcons.padel;
};

const Schedule = ({
  data,
  triggerCreateBooking = false,
  showCurrentTimeAxis = false,
}: {
  showCurrentTimeAxis?: boolean;
  triggerCreateBooking?: boolean;
  data: SharedResourcePlannerProps;
}): React.ReactElement => {
  const plannerRef = useRef<HTMLDivElement>(null);
  const planner = usePlannerData(data, plannerRef);
  const RenderResources = (): React.ReactElement => {
    return (
      <>
        {Object.keys(planner.scheduleResources).map((resourceKey: string) => (
          <ResourceRow
            day={data.day}
            planner={planner}
            triggerCreateBooking={triggerCreateBooking}
            key={resourceKey}
            resource={planner.scheduleResources[resourceKey]}
          />
        ))}
      </>
    );
  };

  return (
    <StyledLayout data-testid="resource-planner">
      <StyledResources>
        <RenderResources />
      </StyledResources>
      <StyledPlannerBg ref={plannerRef}>
        <Axis pxpm={planner.pxpm} axis={planner.axis} />
        {showCurrentTimeAxis && planner.currentTimePosition !== false && (
          <StyledCurrentTimeIndicator x={planner.currentTimePosition} />
        )}
      </StyledPlannerBg>
    </StyledLayout>
  );
};

const ResourceTypeImage = ({ resourceType }: { resourceType: string }): React.ReactElement => {
  return (
    <StyledResourceIcon
      src={iconByResourceType(resourceType)}
      title={resourceType}
      alt={resourceType}
    />
  );
};

const ResourceRow = ({
  resource,
  planner,
  triggerCreateBooking,
  day,
}: {
  resource: ScheduleResource;
  triggerCreateBooking?: boolean;
  planner: PlannerData;
  day: Dayjs;
}): React.ReactElement => {
  const [detailsOpen, setDetailsOpen] = useState(false);
  return (
    <ResourceContainer>
      <ResourceTitle onClick={(): void => setDetailsOpen(!detailsOpen)}>
        <span title={resource.origin.type + " : " + resource.origin.name}>
          <ResourceTypeImage resourceType={resource.origin.type} /> {resource.origin.name}
        </span>
      </ResourceTitle>
      <ResourceObjects>
        {triggerCreateBooking ? (
          <CreateBooking>
            {({ openModal }): React.ReactElement => (
              <>
                <ResourceScheduleObjects
                  title="Add booking"
                  onClick={(event: React.MouseEvent): void => {
                    const target = event.currentTarget.getBoundingClientRect();
                    const dayStartTime = dayjs(planner.fromTo.from, "HH:mm");
                    const startTime = getRoundedDate(
                      30,
                      planner.calculateTimeFromPixels(
                        event.pageX - target.left,
                        dayStartTime.hour() * 60 + dayStartTime.minute(),
                      ),
                    );

                    event.stopPropagation();

                    openModal &&
                      openModal(
                        convertBookingToFormValues(
                          {
                            startTime: startTime.toISOString(),
                            endTime: startTime.add(1, "hour").toISOString(),
                          },
                          resource.origin.id,
                        ),
                      );
                  }}>
                  {resource.scheduleObjects.map((scheduleObject) => {
                    return <Slot key={scheduleObject.origin.id} scheduleObject={scheduleObject} />;
                  })}
                </ResourceScheduleObjects>
              </>
            )}
          </CreateBooking>
        ) : (
          <ResourceScheduleObjects>
            {resource.scheduleObjects.map((scheduleObject) => {
              return <Slot key={scheduleObject.origin.id} scheduleObject={scheduleObject} />;
            })}
          </ResourceScheduleObjects>
        )}
        <ResourceDetails style={{ display: detailsOpen ? "block" : "none" }}>
          <DetailsPerResource details={resource} day={day} />
        </ResourceDetails>
      </ResourceObjects>
    </ResourceContainer>
  );
};

const Slot = ({ scheduleObject }: { scheduleObject: ScheduleObject }): React.ReactElement => {
  if (isScheduleRule(scheduleObject.origin)) {
    return (
      <StyledSlot
        color={colorBySortOrder(scheduleObject.origin.sortOrder)}
        width={scheduleObject.width}
        x={scheduleObject.xStart}
        zIndex={scheduleObject.origin.sortOrder}>
        <span>{scheduleObject.origin.name}</span>
      </StyledSlot>
    );
  } else if (isBooking(scheduleObject.origin)) {
    return (
      <StopPropagation>
        <EditBooking booking={scheduleObject.origin}>
          {({ openModal }): React.ReactElement => (
            <Tooltip
              title={getBookingTooltip(scheduleObject.origin as Booking)}
              placement="topLeft">
              <StyledSlot
                color={colorByBookingType(scheduleObject.origin.type)}
                width={scheduleObject.width}
                x={scheduleObject.xStart}
                zIndex={1}
                onClick={(e): void => {
                  openModal();
                }}>
                <span>Paquito Navarro</span>
              </StyledSlot>
            </Tooltip>
          )}
        </EditBooking>
      </StopPropagation>
    );
  }
  return <></>;
};

const Axis = ({ pxpm, axis }: { pxpm?: number; axis: AxisType }): React.ReactElement => {
  const axisKeys = Object.keys(axis);
  const renderTime = (): React.ReactNode =>
    axisKeys.map((key, index) => {
      const { x, time } = axis[key];
      const offset = (pxpm || 0) * 30;
      const first = index === 0;
      const last = index === axisKeys.length - 1;
      return (
        <Fragment key={key}>
          <StyledAxisTime x={x}>{time.format("HH")}</StyledAxisTime>
          <StyledAxisLine first={first} last={last} lineType="full" x={x} />
          {index !== axisKeys.length - 1 && (
            <StyledAxisLine lineType="half" x={Math.round(x + offset)} />
          )}
        </Fragment>
      );
    });

  return <>{renderTime()}</>;
};

const getBookingTooltip = (booking: Booking): string => {
  ///TODO: what to show when we have all data on a booking
  const start = new Date(booking.startTime);
  const end = new Date(booking.endTime);
  return `${start.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  })} - ${end.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  })}: ${"Paquito Navarro"}, ${booking.type}, ${booking.comment}`;
};

export default Schedule;
