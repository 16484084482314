import React from "react";
import { Link } from "react-router-dom";

import "generated/schemaTypes";
import { Organization } from "generated/schemaTypes";

import ListVenuesByOrganization from "components/Venue/Get/ListVenuesByOrganization";

import routes from "routes/routes";

const ShowOrganizationWithVenues = ({
  organization,
}: {
  organization?: Organization;
}): JSX.Element => {
  if (!organization?.id) {
    return <></>;
  }

  return (
    <>
      <h2>{organization?.name}</h2>
      {/* <Link
        to={routes.organization.edit.path({
          tloId: organization.tlo,
          organizationId: organization.id,
        })}>
        Edit organization
      </Link> */}
      <Link to={routes.organization.index.path()}>&laquo; Back to organisations list</Link>

      <ListVenuesByOrganization organization={organization} />
    </>
  );
};

export default ShowOrganizationWithVenues;
