import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useScheduleRulesFromVenueQuery } from "generated/schemaTypes";

import ListSchedules from "components/ScheduleRule/Get/ListScheduleRules";
import ScheduleRulesOverview from "components/ScheduleRule/Get/ScheduleRulesOverview";
import CreateScheduleRule from "components/ScheduleRule/Modify/CreateScheduleRule";
import BgContainer from "components/atoms/BgContainer";
import { dateIntoPathMethod } from "components/atoms/DateNavigator";
import FlexContainer from "components/atoms/FlexContainer";
import PageHeadline from "components/atoms/PageHeadline";
import StatusHandler from "components/atoms/StatusHandler";
import Button from "components/atoms/antD/Button";
import Col from "components/atoms/antD/Col";
import Row from "components/atoms/antD/Row";
import Tabs from "components/atoms/antD/Tabs";

import routes from "routes/routes";

const { TabPane } = Tabs;

type TabKeyOptions = "overview" | "rules";

function isTabKeyOptions(arg: string): arg is TabKeyOptions {
  return arg === "overview" || arg === "rules";
}

const IndexScheduleView = (): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();

  const { venueId, tloId, tabKey } =
    useParams<{ venueId: string; tloId: string; tabKey: string }>();

  const scheduleRulesFromVenueQuery = useScheduleRulesFromVenueQuery({
    variables: {
      id: venueId,
      tlo: tloId,
    },
  });

  return (
    <BgContainer>
      <Row>
        <Col span={12}>
          <PageHeadline title="Schedule" />
        </Col>
      </Row>
      <Tabs
        defaultActiveKey={tabKey || "overview"}
        size="small"
        className="card-container"
        onChange={(tabKey): void => {
          if (isTabKeyOptions(tabKey)) {
            history.push(routes.scheduleRule.index.path({ venueId, tloId, tabKey }));
          }
        }}>
        <TabPane tab="Overview" key="overview">
          <BgContainer>
            <FlexContainer>
              <ScheduleRulesOverview
                navigationPathForDate={dateIntoPathMethod(routes.scheduleRule.index.path, {
                  venueId,
                  tloId,
                  tabKey,
                })}
              />
            </FlexContainer>
          </BgContainer>
        </TabPane>
        <TabPane tab="Rules" key="rules">
          <BgContainer>
            <StatusHandler
              dataQueries={[
                {
                  query: scheduleRulesFromVenueQuery,
                  nonNullData: scheduleRulesFromVenueQuery.data?.venue,
                },
              ]}>
              {([venueWithSchedules]): React.ReactElement => (
                <>
                  <h2>Schedule rules</h2>
                  <Button onClick={(): void => setModalOpen(true)} type="primary" title="Add rule">
                    Add rule
                  </Button>
                  <ListSchedules scheduleRules={venueWithSchedules.schedules ?? []} />
                </>
              )}
            </StatusHandler>
            <CreateScheduleRule
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              venueId={venueId}
              tloId={tloId}
              numOfRules={(scheduleRulesFromVenueQuery.data?.venue?.schedules?.length || 0) + 1}
            />
          </BgContainer>
        </TabPane>
      </Tabs>
    </BgContainer>
  );
};

export default IndexScheduleView;
