import React from "react";
import { Link } from "react-router-dom";

import "generated/schemaTypes";
import { Venue } from "generated/schemaTypes";

import routes from "routes/routes";
import styled from "styled-components";

const ShowVenue = ({
  organizationId,
  venue,
}: {
  organizationId?: string;
  venue?: Venue;
}): JSX.Element => {
  if (!venue?.id) {
    return <></>;
  }

  return (
    <Container>
      <h1>{venue?.name}</h1>

      <h2>Actions</h2>

      <h3>Venues:</h3>
      <ul>
        <li>
          <Link to={routes.venue.edit.path({ tloId: venue.tlo, venueId: venue.id })}>
            Edit {venue?.name} &raquo;
          </Link>
        </li>
        <li>
          <Link to={routes.venue.create.path({ organizationId, tloId: venue.tlo })}>
            Create new venue
          </Link>
        </li>
      </ul>

      <h3>Schedule:</h3>
      <ul>
        <li>
          <Link to={routes.scheduleRule.index.path({ tloId: venue.tlo, venueId: venue.id })}>
            Show rules
          </Link>
        </li>
        <li>
          <Link
            to={routes.scheduleRule.index.path({
              tloId: venue.tlo,
              venueId: venue.id,
              tabKey: "overview",
            })}>
            WIP: Show rules planner
          </Link>
        </li>
      </ul>

      <h3>Bookings:</h3>
      <ul>
        <li>
          <Link to="/bookings">WIP: Show bookings schedule</Link>
        </li>
      </ul>

      <h3>Resources:</h3>
      <ul>
        <li>
          <Link to={routes.resource.index.path({ tloId: venue.tlo, venueId: venue.id })}>
            Show resources
          </Link>
        </li>
      </ul>
    </Container>
  );
};

const Container = styled.div`
  ul {
    padding: 0;
    list-style: none;
  }
`;

export default ShowVenue;
