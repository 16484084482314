import IndexCustomersView from "components/Customer/Views/IndexCustomersView";

import { BASE_ROUTE_PATH_CUSTOMER } from "routes/paths";

const route = {
  path: (params?: { tloId?: string; venueId?: string }): string => {
    return (
      BASE_ROUTE_PATH_CUSTOMER +
      "/index/" +
      (params ? params.tloId : ":tloId") +
      "/" +
      (params ? params.venueId : ":venueId")
    );
  },
  component: IndexCustomersView,
};

export default route;
