import React from "react";

import AntDCol, { ColProps } from "antd/lib/col";
import hoistNonReactStatics from "hoist-non-react-statics";

const ColWrapper = (props: ColProps): JSX.Element => {
  return <AntDCol {...props} />;
};

export default hoistNonReactStatics(ColWrapper, AntDCol);
