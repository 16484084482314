import React from "react";
import { useParams } from "react-router-dom";

import { useGetOrganizationQuery } from "generated/schemaTypes";

import EditOrganization from "components/Organization/Modify/EditOrganization";
import StatusHandler from "components/atoms/StatusHandler";

const EditOrganizationView = (): JSX.Element => {
  const { organizationId, tloId } = useParams<{ organizationId: string; tloId: string }>();

  const getOrganizationQuery = useGetOrganizationQuery({
    variables: { id: organizationId, tlo: tloId },
  });

  return (
    <StatusHandler
      dataQueries={[
        { query: getOrganizationQuery, nonNullData: getOrganizationQuery?.data?.organization },
      ]}>
      {([organization]): React.ReactElement => <EditOrganization organization={organization} />}
    </StatusHandler>
  );
};

export default EditOrganizationView;
