import React from "react";

//import { Link } from "react-router-dom";
import "generated/schemaTypes";
import { Resource } from "generated/schemaTypes";

const ShowResource = ({ resource }: { resource?: Resource }): JSX.Element => {
  if (!resource?.id) {
    return <></>;
  }

  return (
    <>
      <h1>{resource?.name}</h1>
      <pre style={{ fontSize: "10px" }}>${JSON.stringify(resource, null, 2)}</pre>
    </>
  );
};

export default ShowResource;
