import EditVenueView from "components/Venue/Views/EditVenueView";

import { BASE_ROUTE_PATH_VENUE } from "routes/paths";

const route = {
  path: (params?: { tloId?: string; venueId?: string; tab?: string }): string => {
    const tloId = params ? params.tloId : ":tloId";
    const venueId = params ? params.venueId : ":venueId";
    const tabQuery = params && params.tab ? `?tab=${params.tab}` : "";

    return `${BASE_ROUTE_PATH_VENUE}/edit/${tloId}/${venueId}${tabQuery}`;
  },
  component: EditVenueView,
};

export default route;
