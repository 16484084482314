import React from "react";

import Form, { FormItemProps } from "antd/lib/form";
import styled from "styled-components";

const FormItemWrapper = (props: FormItemProps): JSX.Element => {
  return <Form.Item {...props} />;
};

const FormItem = styled(FormItemWrapper)`
  & {
  }
`;

export type { FormItemProps };

export default FormItem;
