import { ResourceSkeletonFragment, ScheduleRule } from "generated/schemaTypes";

import { DeleteScheduleRule } from "components/ScheduleRule/Modify/EditScheduleRule";
import scheduleRuleForms, {
  ScheduleRuleFormValues,
} from "components/ScheduleRule/Modify/ScheduleRuleFormItems";
import Hr from "components/atoms/Hr";
import Spacer from "components/atoms/Spacer";
import Button from "components/atoms/antD/Button";
import Form from "components/atoms/antD/Form";
import FormItem from "components/atoms/antD/FormItem";

import { Col, Row } from "antd/lib/grid";
import { spacing } from "config/layout";
import { convertScheduleRuleToValues } from "helpers/converters/scheduleRule";

const ScheduleRuleForm = ({
  onSubmit,
  resources,
  scheduleRule,
  submitLoading,
}: {
  onSubmit: (values: ScheduleRuleFormValues) => void;
  resources: ResourceSkeletonFragment[];
  scheduleRule: ScheduleRule;
  submitLoading: boolean;
}): React.ReactElement => {
  const [form] = Form.useForm<ScheduleRuleFormValues>();

  const ScheduleRuleFormItem = scheduleRuleForms(resources);

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      layout="vertical"
      initialValues={convertScheduleRuleToValues(scheduleRule)}>
      <Spacer size="responsive" />
      <ScheduleRuleFormItem item="typeOfRule" />
      <ScheduleRuleFormItem item="sortOrder" props={{ disabled: false }} />

      <Hr size="md" />

      <Row gutter={spacing.lg * 2}>
        <Col span={12}>
          <h3>Information</h3>
          <p>Describe the rule to easily understand it.</p>
          <ScheduleRuleFormItem item="name" />
          <ScheduleRuleFormItem item="description" />
        </Col>
        <Col span={12}>
          <h3>Settings</h3>
          <p>Select which resources that this rule will apply to.</p>
          <ScheduleRuleFormItem item="resources" />
        </Col>
      </Row>

      <Hr size="md" />

      <Row gutter={spacing.lg * 2}>
        <Col span={12}>
          <h3>Date</h3>
          <p>Set a start and end date for when the exception should apply:</p>
          <Row gutter={spacing.lg}>
            <Col span={12}>
              <ScheduleRuleFormItem item="startDate" />
            </Col>
            <Col span={12}>
              <ScheduleRuleFormItem item="endDate" />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <h3>Time</h3>
          <p>Set a start and end time for when the court should be bookable</p>
          {["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].map(
            (day) => (
              <Row key={day}>
                <Col span={8}>{day}</Col>
                <Col span={16}>
                  <ScheduleRuleFormItem data-cy={day} item={"hours"} props={{ day: day }} />
                </Col>
              </Row>
            ),
          )}
        </Col>
      </Row>
      <FormItem
        noStyle
        shouldUpdate={(prevValues, curValues): boolean =>
          prevValues.typeOfRule !== curValues.typeOfRule
        }>
        {({ getFieldValue }): React.ReactNode => {
          return (
            <>
              {getFieldValue("typeOfRule") === "bookable" && (
                <>
                  <Hr size="md" />

                  <Row gutter={spacing.lg * 2}>
                    <Col span={12}>
                      <h3>Slot length settings</h3>
                      <Row gutter={spacing.lg}>
                        <Col span={12}>
                          <ScheduleRuleFormItem item="minDuration" />
                        </Col>
                        <Col span={12}>
                          <ScheduleRuleFormItem item="maxDuration" />
                        </Col>
                      </Row>
                      <h3>Increment</h3>
                      <p>
                        Booking extension increment. <br />
                        Enables the user to book a slot from the miminum booking length up to the
                        maximum booking length in incremental steps.{" "}
                      </p>
                      <ScheduleRuleFormItem item="interval" />
                    </Col>
                    <Col span={12}></Col>
                  </Row>
                </>
              )}
            </>
          );
        }}
      </FormItem>
      <Button loading={submitLoading} type="primary" htmlType="submit">
        Save
      </Button>
      <DeleteScheduleRule id={scheduleRule.id} tlo={scheduleRule.tlo} />
    </Form>
  );
};

export default ScheduleRuleForm;
