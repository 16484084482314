import EditOrganizationView from "components/Organization/Views/EditOrganizationView";

import { BASE_ROUTE_PATH_ORGANIZATION } from "routes/paths";

const route = {
  path: (params?: { tloId?: string; organizationId?: string }): string => {
    return (
      BASE_ROUTE_PATH_ORGANIZATION +
      "/edit/" +
      (params ? params.tloId : ":tloId") +
      "/" +
      (params ? params.organizationId : ":organizationId")
    );
  },
  component: EditOrganizationView,
};

export default route;
