import { useEffect, useState } from "react";

import { Booking, Customer, Hours, Resource } from "generated/schemaTypes";

import { scheduleObjectAttributes } from "components/Schedule/styles";
import {
  OpeningInterval,
  ScheduleResource,
  AxisType,
  ScheduleObjectOrigin,
  DetailsType,
  isScheduleRule,
  ScheduleObject,
  SharedResourcePlannerProps,
} from "components/Schedule/types";

import dayjs, { Dayjs } from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import { numberRangeToArray } from "helpers/Array";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(weekday);
dayjs.extend(utc);
dayjs.extend(timezone);

// Filters slots and returns slots that are active during the provided day.
export const filterScheduleObjectOriginsByDate = (
  scheduleObjectOrigin: ScheduleObjectOrigin[],
  day: Dayjs,
): ScheduleObjectOrigin[] | [] => {
  if (scheduleObjectOrigin?.length && day) {
    return scheduleObjectOrigin.filter((slot: ScheduleObjectOrigin) => {
      const currentDay = dayjs(day);

      if (isScheduleRule(slot)) {
        return currentDay.isSameOrAfter(dayjs(slot.startDate), "day");
      } else {
        return currentDay.isSame(dayjs(slot.startTime), "day");
      }
    });
  }
  return [];
};

export const filterBookingsByStatus = (bookings: Booking[], status: string): Booking[] => {
  if (bookings?.length && status) {
    return bookings.filter((booking: Booking) => {
      return booking.status === status;
    });
  }
  return [];
};

export const filterOutObjectsByStatus = <T extends Booking | Customer>(
  objectsWithStatus: T[],
  status: string,
): T[] => {
  if (objectsWithStatus?.length && status) {
    return objectsWithStatus.filter((object) => {
      return object.status !== status;
    });
  }
  return [];
};

//returns filtered list of bookings based on starttime, starting from 1 hours earlier
export const filterUpcomingBookings = (bookings: Booking[]): Booking[] => {
  if (bookings?.length) {
    return bookings.filter((booking: Booking) => {
      return (
        dayjs(booking.startTime).isAfter(dayjs().subtract(1, "hour")) &&
        booking.status !== "CANCELLED"
      );
    });
  }
  return [];
};

/**
 * setFromTo
 * @param slots - a list of schedule or booking type objects, check the ScheduleRule type.
 * @param day - current date in a dayjs format.
 **/
export const getScheduleDayOpeningInterval = (
  data: SharedResourcePlannerProps,
): OpeningInterval => {
  const currentDay = data.day.weekday();

  if (
    !data.scheduleObjectOrigins.length ||
    data.scheduleObjectOrigins.find((origin) => !isScheduleRule(origin))
  ) {
    try {
      if (data.boundaries?.startTime && data.boundaries?.endTime) {
        let startDate = dayjs(data.boundaries?.startTime);
        let endDate = dayjs(data.boundaries?.endTime);
        return {
          from: startDate.utc(false).format("HH:mm"),
          to: endDate.utc(false).format("HH:mm"),
        };
      }
    } catch (e) {
      return {
        from: "00:00",
        to: "24:00",
      };
    }
  }

  const hours = data.scheduleObjectOrigins.reduce(
    (accumulator: Hours[], ScheduleObject): Hours[] => {
      if (isScheduleRule(ScheduleObject)) {
        return [
          ...accumulator,
          ScheduleObject.hours?.find((item) => {
            return item?.weekdays?.includes(currentDay);
          }) ?? {},
        ];
      }

      return accumulator;
    },
    [],
  );

  const from = hours.sort(
    (a, b) => parseInt(a?.startTime ?? "0") - parseInt(b?.startTime ?? "0"),
  )[0]?.startTime;

  const to = hours.sort((a, b) => {
    const x = parseInt(a?.endTime ?? "");
    const y = parseInt(b?.endTime ?? "");
    if (isFinite(y - x)) {
      return y - x;
    } else {
      return isFinite(x) ? -1 : 1;
    }
  })[0]?.endTime;

  return { from: from || "", to: to || "" };
};

export const calculatePixelsPerMinute = (
  fromTo: OpeningInterval,
  width: number,
  day: Dayjs,
): number => {
  const start = dayjs(`${day.format("YYYY-MM-DD")} ${fromTo.from}`);
  const end = dayjs(`${day.format("YYYY-MM-DD")} ${fromTo.to}`);
  const minutesBetween = end.diff(start, "minutes");

  return width / minutesBetween;
};

const getScheduleResources = ({
  resources,
  scheduleObjects,
  details,
}: {
  resources: Resource[];
  scheduleObjects: ScheduleObject[];
  details: DetailsType;
}): { [key: string]: ScheduleResource } => {
  const { height } = scheduleObjectAttributes.dimensions;

  return resources.reduce(
    (accumulator: { [key: string]: ScheduleResource }, resource: Resource, index: number) => {
      const isOpen = details?.index !== undefined && details.index < index;
      const yStandard = index * height.default;
      const yOpen = height.open + yStandard;

      const obj = { ...accumulator };
      obj[resource.id] = {
        index,
        y: isOpen ? yOpen : yStandard,
        yDetails: yStandard + height.default,
        origin: resource,
        scheduleObjects: scheduleObjects.filter((scheduleObject) =>
          scheduleObject.origin.resources?.includes(resource.id),
        ),
      };

      return obj;
    },
    {},
  );
};

const generateAxes = (fromTo: OpeningInterval, pxpm: number, day: Dayjs): AxisType => {
  const from = dayjs(`${day.format("YYYY-MM-DD")} ${fromTo.from}`).hour();
  const to = dayjs(`${day.format("YYYY-MM-DD")} ${fromTo.to}`).hour();

  return numberRangeToArray(from, to).reduce((accumulator, hour, index) => {
    const x = Math.round(index * (pxpm * 60));
    const time = dayjs(`${day.format("YYYY-MM-DD")}`)
      .hour(hour)
      .minute(0)
      .second(0);
    return {
      ...accumulator,
      [hour]: {
        time: time,
        x,
      },
    };
  }, {});
};

const getFormattedScheduleObjects = ({
  origins,
  day,
  pxpm,
  axis,
}: {
  origins: ScheduleObjectOrigin[];
  pxpm: number;
  day: Dayjs;
  axis: AxisType;
}): ScheduleObject[] | [] => {
  const currentDay = day.weekday();

  if (origins?.length) {
    return origins.map((origin): ScheduleObject => {
      let start = dayjs();
      let end = dayjs();

      if (isScheduleRule(origin)) {
        const currentHours =
          origin?.hours &&
          origin.hours.find((hour: Hours) => {
            return hour.weekdays?.includes(currentDay);
          });

        if (currentHours) {
          start = dayjs(`${day.format("YYYY-MM-DD")} ${currentHours.startTime}`);
          end = dayjs(`${day.format("YYYY-MM-DD")} ${currentHours.endTime}`);
        }
      } else {
        start = dayjs(origin.startTime);
        end = dayjs(origin.endTime);
      }

      let xStart;
      let xEnd;
      let width;

      if (start.isValid() && end.isValid()) {
        const diff = end.diff(start, "minutes");
        xStart = axis[start.hour()]?.x + start.minute() * pxpm;
        xEnd = Math.round(diff * pxpm + xStart);
        width = xEnd - xStart;
      } else {
        xStart = 0;
        xEnd = 0;
        width = 0;
      }

      return {
        xStart,
        xEnd,
        width,
        origin: origin,
      };
    });
  }

  return [];
};

const generateCurrentTime = (pxpm: number, axis: AxisType, day: Dayjs): number | false => {
  const now = dayjs();
  const hourPixelPosition = axis[now.hour()];

  if (hourPixelPosition && day.isSame(now, "day"))
    return Math.round(hourPixelPosition.x + now.minute() * pxpm);

  return false;
};

export interface PlannerData {
  scheduleResources: { [key: string]: ScheduleResource };
  axis: AxisType;
  pxpm: number;
  fromTo: OpeningInterval;
  currentTimePosition: number | false;
  details: DetailsType;
  calculateTimeFromPixels: (x: number, startTime: number) => Dayjs;
}

export const usePlannerData = (data: SharedResourcePlannerProps, ref: any): PlannerData => {
  const [scheduleResources, setScheduleResources] = useState<{ [key: string]: ScheduleResource }>(
    {},
  );
  const [axis, setAxis] = useState<AxisType>({});
  const [helpers, setHelpers] = useState<{
    pxpm: number;
    currentTimePosition: number | false;
    fromTo: OpeningInterval;
  }>({
    pxpm: 0,
    currentTimePosition: false,
    fromTo: {
      from: "00:00",
      to: "24:00",
    },
  });

  const [details, setDetails] = useState<DetailsType>();

  const calculateTimeFromPixels = (x: number, startTime: number): Dayjs => {
    const minutesPerPixel = 1 / helpers.pxpm;
    return data.day.startOf("day").add(startTime + minutesPerPixel * x, "minute");
  };

  useEffect(() => {
    if (data && ref.current) {
      const { width } = ref.current.getBoundingClientRect();

      const fromTo = getScheduleDayOpeningInterval(data);

      const pxpm = calculatePixelsPerMinute(fromTo, width, data.day);
      const newAxis = generateAxes(fromTo, pxpm, data.day);

      const scheduleObjects = getFormattedScheduleObjects({
        origins: data.scheduleObjectOrigins,
        pxpm,
        axis: newAxis,
        day: data.day,
      });

      let sortedResources = [...data.resources];
      data.resources = sortedResources.sort((a, b) => a.sortOrder - b.sortOrder);
      const scheduleResources = getScheduleResources({
        resources: data.resources,
        scheduleObjects: scheduleObjects,
        details,
      });
      const currentTimePosition = generateCurrentTime(pxpm, newAxis, data.day);

      setAxis(newAxis);
      setScheduleResources(scheduleResources);
      setHelpers({ pxpm, currentTimePosition, fromTo });
    }
  }, [data, ref, details, data.scheduleObjectOrigins]);

  useEffect(() => {
    setDetails(undefined);
  }, [data.day]);

  return { scheduleResources, axis, ...helpers, details, calculateTimeFromPixels };
};
