import React from "react";

import AntDModal, { ModalFuncProps, ModalProps } from "antd/lib/modal";
import hoistNonReactStatics from "hoist-non-react-statics";
import styled from "styled-components";

const defaultProps: ModalProps = {
  style: {},
};

const ModalWrapper = (props: ModalProps & { children?: JSX.Element }): JSX.Element => {
  return <AntDModal {...defaultProps} {...props}></AntDModal>;
};

ModalWrapper.info = (props: ModalFuncProps): void => {
  AntDModal.info({ ...defaultProps, ...props });
};

ModalWrapper.warn = (props: ModalFuncProps): void => {
  AntDModal.warn({ ...defaultProps, ...props });
};

ModalWrapper.error = (props: ModalFuncProps): void => {
  AntDModal.error({ ...defaultProps, ...props });
};

const Modal = styled(ModalWrapper)({
  ...defaultProps.style,
});

export type { ModalProps };

export default hoistNonReactStatics(Modal, AntDModal);
