import React from "react";
import { Route, Redirect } from "react-router-dom";

import { useIsLoggedIn } from "hooks/auth";

interface PrivateRouteProps {
  path: string;
  children: React.ReactElement;
}

function PrivateRoute({ children, ...rest }: PrivateRouteProps): JSX.Element {
  const isLoggedIn = useIsLoggedIn();

  return (
    <Route
      {...rest}
      render={(): any => {
        return isLoggedIn ? children : <Redirect to="/login" />;
      }}
    />
  );
}

export default PrivateRoute;
