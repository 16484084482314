import IndexSchedulesView from "components/ScheduleRule/Views/IndexSchedulesView";

import dayjs from "dayjs";
import { BASE_ROUTE_PATH_SCHEDULE_RULE } from "routes/paths";

const route = {
  path: (params?: {
    tloId?: string;
    venueId?: string;
    tabKey?: "overview" | "rules";
    date?: string;
  }): string => {
    return (
      BASE_ROUTE_PATH_SCHEDULE_RULE +
      "/index/" +
      (params ? params.tloId : ":tloId") +
      "/" +
      (params ? params.venueId : ":venueId") +
      "/" +
      (params ? params.tabKey || "overview" : ":tabKey") +
      "/" +
      (params ? params.date || dayjs().format("YYYYMMDD") : ":date?")
    );
  },
  component: IndexSchedulesView,
};

export default route;
