import React from "react";

import { SizeType } from "antd/lib/config-provider/SizeContext";
import AntDTable, { TableProps, ColumnsType } from "antd/lib/table";
import hoistNonReactStatics from "hoist-non-react-statics";
import styled from "styled-components";

interface ExtendedTableProps<RecordType> extends Omit<TableProps<RecordType>, "size"> {
  size?: SizeType | "minimum";
}

const MinimumAntDTable = styled(AntDTable)`
  width: 100%;

  & .ant-table .ant-table-title,
  & .ant-table .ant-table-footer,
  & .ant-table .ant-table-thead > tr > th,
  & .ant-table .ant-table-tbody > tr > td,
  & .ant-table tfoot > tr > th,
  & .ant-table tfoot > tr > td {
    padding: 4px;
  }
` as typeof AntDTable;

function TableWrapper<RecordType extends object = any>(
  props: ExtendedTableProps<RecordType>,
): JSX.Element {
  if (props.size === "minimum") {
    return <MinimumAntDTable {...props} size="small" />;
  } else {
    return <AntDTable {...props} size={props.size} />;
  }
}

export type { ExtendedTableProps, ColumnsType };

export default hoistNonReactStatics(TableWrapper, AntDTable);
